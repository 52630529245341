import PAGE_ANALYTICS from './page_analytics';

export default {
  async typeaheadSearchOptionUpdate() {
    PAGE_ANALYTICS.track('typehead_option_update', {
      integrations: {
        Adobe: false,
        DataDog: true
      }
    });
  }
};
