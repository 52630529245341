import { Row, Col } from 'components/FlexBoxGrid';
import Progress from 'components/Progress/Progress';
import styled from 'styled-components';
import { Button, Icon, Popup } from '@kdshared/enrich-ui';

export const AttachmentInfo = styled(Row)`
  &&& { 
    background: ${({ theme }) => theme.COLORS.$white};
    padding-top: 5px;
    padding-bottom: 5px;  
    &:first-child {
        padding-top: 15px;
      }
  }
`;
export const AttachTitle = styled(Col)`
  &&& { 
    display:flex;
  }
`;
export const AttachName = styled.div`
  &&& { 
    position: relative;
    font-size: ${({ theme }) => theme.SIZE(11)};
    font-family: ${({ theme }) => theme.FONTS.$sansreg};
    color: $gray--darker;
    text-align: left;
    margin-bottom: ${({ theme }) => theme.SIZE(5)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ErrorIcon = styled(Icon)`
  &&& { 
    color: ${({ theme }) => theme.COLORS.$bcgred};
    ${({ hidden }) => (hidden ? 'visibility:hidden;' : '')}
  }
`;
export const DismissButton = styled(Icon)`
  &&& { 
  }
`;
export const ProgressBar = styled(Progress)`
  &&& { 
    .ui.progress {
        overflow: hidden;
        
        .bar {
            background: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
            min-width: 0 !important;
        }
    }
  }
`;
export const StyledButton = styled(Button)`
  &&& {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.COLORS.$graydarker};
    border: 0;
  }
`;
export const StyledPopup = styled(Popup)`
&&& {
  border-radius: 0;
}
`;
export const Percentage = styled.div`
  &&& { 
    font-size: ${({ theme }) => theme.SIZE(9)};
    font-family: ${({ theme }) => theme.FONTS.$sansbold};
    color: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
    position: relative;
    right: 5px;
  }
`;