import styled, { css } from 'styled-components';
import SVG from 'components/Svg/Svg';
import Button from 'components/Button/Button';
import { Icon } from '@kdshared/enrich-ui';
import ChatLoader from 'components/ChatLoader/ChatLoader';
import { ROLES } from 'redux/api/proposalBuilder/chat/chat';

export const Wrapper = styled.div`
  display: flex;
  transition: all 400ms ease-in-out;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 0px;
  overflow-y: hidden;
  opacity: 0;
  height: 100%;
  opacity: 1;
  padding: 0px 0px 10px 0px;
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.NEW.grays.gray300};
`;

export const BarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ $noPadding }) => !$noPadding && css`
    padding: 0 30px;
  `}
  margin: 20px 0px;
  ${({ onClick }) => onClick && css`
    cursor: pointer;
    ${BarText}:hover {
      background-color: ${({ theme }) => theme.NEW.grays.gray200};
      ${CaretIcon} {
        transform: rotate(180deg);
      }
    }
  `}

  ${({ $disabled }) => $disabled && css`
    cursor: auto;
    ${Hr} {
      background-color: ${({ theme }) => theme.NEW.grays.gray500};
    }
    ${BarText} {
      color: ${({ theme }) => theme.NEW.grays.gray500};
      :hover {
        background-color: transparent;
      }
    }
  `}
`;

export const Hr = styled.hr`
  width: 100%;
  margin: 0;
  border: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.NEW.chat.leaveChat};
`;

export const BarText = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.NEW.chat.leaveChat};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.SIZE(12)};
  margin: 0 6px;
`;

export const CaretIcon = styled(Icon)`
  &&& {
    transition: all 300ms ease-in-out;
    font-size: ${({ theme }) => theme.SIZE(12)};
    margin-right: 5px;
    height: auto;
  }
`;

export const ChatBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px;
  position: relative;
`;

export const MessagesWrapper = styled.div`
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 100%;
  overflow-y: auto;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
  font-size: ${({ theme }) => theme.SIZE(16)};
  ${({ $role }) => $role === ROLES.USER && css`
    background-color: white;
    color: ${({ theme }) => theme.NEW.grays.gray700};
  `}
  ${({ $role }) => $role === ROLES.ASSISTANT && css`
    background-color: #f7f7f8; // ${({ theme }) => theme.NEW.grays.gray200};
    border-top: 2px solid ${({ theme }) => theme.NEW.grays.gray200};
    border-bottom: 2px solid ${({ theme }) => theme.NEW.grays.gray200};
    color: black; // ${({ theme }) => theme.NEW.grays.gray00};
  `}
  ${({ $role }) => $role === ROLES.STATUS && css`
    color: ${({ theme }) => theme.NEW.grays.gray700};
    font-size: ${({ theme }) => theme.SIZE(12)};
    padding-left: 60px;
  `}
`;

export const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChatActionIconWrapper = styled(SVG)`
  cursor: pointer;
  height: 26px;
  width: 26px;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  color: ${({ theme }) => theme.NEW.grays.gray400};
  &:hover {
    color: ${({ theme }) => theme.NEW.black};
    background-color: ${({ theme }) => theme.NEW.grays.gray300};
  }
`;

export const FeedbackIconWrapper = styled(SVG)`
  height: 32px;
  width: 30px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 6px;
  border-radius: 20px;
  color: ${({ up, theme }) => up ? theme.NEW.darkModerateCyan : theme.COLORS.$bcgred};
  background-color: ${({ up, theme }) => up ? theme.NEW.verySoftLimeGreen : theme.NEW.reds.red200};
`;

export const FeedbackModalHeaderWrapper = styled.div`
  display: flex;
  align-items: end;
`;

export const InnerColumn = styled.div`
  display: flex;
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
  ${({ justifyContent }) => justifyContent && css`
    justify-content: ${justifyContent};
  `}
`;

export const CarouselWrapper = styled.div`
    .carousel {
      height: 605px;
      background-color: transparent;
      .scroller {
        height: inherit;
        display: flex;
      }
    }
`;

export const GridCardWrapper = styled.div`
  display: flex;
  margin: 0px 10px;
`;

export const ProfileWrapper = styled.div`
  margin-top: ${({ theme }) => theme.SIZE(6)};
  margin-right: ${({ theme }) => theme.SIZE(7)};
`;

export const ProfileImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right: 10px;
  ${({ $role }) => $role === ROLES.ASSISTANT && css`
    border-radius: 0px;
  `}
`;

export const MessageText = styled.div`
  margin-right: 10px;
  padding: 5px 10px;
  width: calc(100% - 90px);
  line-height: 28px;
  ${({ $role }) => $role === ROLES.STATUS && css`
    color: ${({ theme }) => theme.NEW.grays.gray700};
    font-size: ${({ theme }) => theme.SIZE(12)};
  `}
`;

export const SourcesWrapper = styled.div`
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.NEW.grays.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.NEW.grays.gray300};
  color: ${({ theme }) => theme.NEW.grays.gray700};
`;

export const ChatLoading = styled(ChatLoader)`
  margin: 16px;
  margin-left: 3.5vw;
`;

export const Bottom = styled.div`
  bottom: 0;
  z-index: 100; // docviz has some z-index's above 1
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 30px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg,#fff 70%,rgba(0,0,0,0) 100%);
  background-size: cover;
  background-blend-mode: screen, normal;
`;

export const InputWrapper = styled.div`
  position: relative;
  padding: 16px 0 16px 16px;
  border-radius: 8px;
  align-items: flex-end;
  margin-top: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.NEW.white};
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 15px rgba(0,0,0,.1);
`;

export const ChatInput = styled.textarea`
  resize: none;
  display: flex;
  border: 0;
  padding-right: 2.5rem;
  font-size: ${({ theme }) => theme.SIZE(16)};
  line-height: ${({ theme }) => theme.SIZE(24)};
  width: 100%;
  height: ${({ height }) => height};
  max-height: 200px;
  &:focus-visible {
    outline: none;
  }
  ::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
  }
`;

export const FeedbackInput = styled.textarea`
  resize: none;
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding-right: 2.5rem;
  font-size: ${({ theme }) => theme.SIZE(16)};
  line-height: ${({ theme }) => theme.SIZE(24)};
  width: 100%;
  height: ${({ height }) => height};
  max-height: 200px;
  padding: 10px;
  &:focus-visible {
    outline: none;
  }
  ::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
  }
`;

export const SendButton = styled(SVG)`
  position: absolute;
  bottom: 19px;
  right: 16px;
  color: ${({ theme }) => theme.NEW.grays.gray400};
  cursor: pointer;
  height: 20px;
  width: 25px;

  &:hover {
    color: ${({ theme }) => theme.NEW.grays.gray700};
  }

  ${({ $disabled }) => $disabled && css`
    cursor: not-allowed;
    &:hover {
      color: ${({ theme }) => theme.NEW.grays.gray200};
    }
  `}
`;

export const SubmitFeedbackButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: auto;
`;

export const ChatActionButton = styled(Button)`
  &&& {
    border-color: ${({ theme }) => theme.NEW.grays.gray200};
    color: ${({ theme }) => theme.NEW.grays.gray500};
    font-size: ${({ theme }) => theme.SIZE(12)};
    max-width: 150px;
    height: 40px;
    text-transform: none;
    font-family: HendersonBCGSans-Regular, Arial, Helvetica, sans-serif;
  }
`;

export const SubmitFeedbackButton = styled(Button)`
  &&& {
    border-color: ${({ theme }) => theme.NEW.grays.gray200};
    color: ${({ theme }) => theme.NEW.grays.gray500};
    font-size: ${({ theme }) => theme.SIZE(12)};
    max-width: 150px;
    height: 40px;
    text-transform: none;
    font-family: HendersonBCGSans-Regular, Arial, Helvetica, sans-serif;
  }
`;

export const StyledStopGeneratingSquare = styled(SVG)`
  margin: 0;
`;

export const SupButton = styled(Button)`
  && {
    transition: all 300ms ease-in-out;
    background: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
    border-radius: 5px;
    border: none;
    color: ${({ theme }) => theme.COLORS.$white};
    margin: 0;
    padding: 0.25rem;
    letter-spacing: normal;
    line-height: 1;
  }
`;

export const Sup = styled.sup`
  margin-left: 0.25em;
  display: inline-flex;
  a {
    color: ${({ theme }) => theme.COLORS.$white};
    font-size: ${({ theme }) => theme.SIZE(10)};
    font-weight: bold;
    margin-left: 0.25rem;
    padding: 0.25rem;
    background: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
    line-height: 1;
    border-radius: 5px;
  }
`;
