import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import CONFIG from 'config';
import { getUserEmail, isUserAuthenticated } from 'utils/auth/auth';

import './UsabillaSetup.scss';

const UsabillaSetup = () => {
  useEffect(() => {
    const loadUsabilla = async () => {
      let userEmail = 'unknown';

      if (await isUserAuthenticated()) {
        userEmail = await getUserEmail();
        if (!userEmail) {
          console.error('Usabilla userEmail is null');
        }
      }
      try {
        const ddSessionId = datadogRum.getInternalContext().session_id;
        // set a custom variable for the app version to be sent with all usabilla feedback submissions and prepopulate the users email address
        window.usabilla_live('data', { email: userEmail, custom: { app_version: CONFIG.SMART_SEARCH_VERSION, ddSessionId } });
      } catch (err) {
        console.log(err);
      }
    };

    loadUsabilla();
  }, []);

  return <div />;
};

export default UsabillaSetup;