import ANALYTICS from 'utils/analytics/analytics';
import { actions as notifyActions } from 'redux/components/notification/notification';
import CONFIG from 'config';

export const name = 'typeAheadFeedBack';
export const SHOW_FEEDBACK_MODAL = 'SHOW_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const SELECT_OPTION = 'SELECT_OPTION';
export const DESELECT_OPTION = 'DESELECT_OPTION';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const CONCEPT_SUGGESTIONS = 'CONCEPT_SUGGESTIONS';

export const initialState = {
  selectedOptions: [],
  showModal: false,
  buttonActive: false,
  feedbackType: '',
  conceptSuggestions: []
};

export const selectors = {
  getSelectedOptions: (state) => state[name].selectedOptions,
  getShowModal: (state) => state[name].showModal,
  getButtonActive: (state) => state[name].buttonActive,
  getFeedbackType: (state) => state[name].feedbackType,
  getConceptSuggestions: (state) => state[name].conceptSuggestions
};

const createString = (options) => options.map((a) => a.display).join('|');

const submitTypeAheadFeedBack = (selectedOptions, typeAheadResults) => {
  const allOptions = typeAheadResults.results;
  const notSelected = allOptions.filter((option) => !selectedOptions.some((suggestion) => suggestion.display === option.display));
  const selectedOptionsStr = createString(selectedOptions);
  const unselectedOptionsStr = createString(notSelected);
  ANALYTICS.relevancy.typeAheadFeedback(selectedOptionsStr, unselectedOptionsStr);
};

const submitRelevantConceptsFeedback = (selectedOptions, relatedConcepts, userSuggestions) => {
  const filtered = relatedConcepts.concepts.VALUES_UNLOCKED.filter((node, index) => node.data.display && index < 20);
  const suggestions = createString(userSuggestions[0]);
  const conceptsNew = filtered.map((c) => {
    const container = {};
    container.display = c.data.display;
    return container;
  }
  );
  const allConcepts = createString(conceptsNew);
  const selectedOptionsStr = createString(selectedOptions);
  ANALYTICS.relevancy.relatedConceptsFeedback(allConcepts, selectedOptionsStr, suggestions);
};

export const actions = {
  newConceptSuggestion: (suggestions) => (dispatch) => {
    dispatch({ type: CONCEPT_SUGGESTIONS, conceptSuggestions: suggestions });
  },
  openFeedBackModal: (type) => (dispatch) => {
    document.body.style.overflow = 'hidden';
    dispatch({ type: SHOW_FEEDBACK_MODAL, selectedOptions: [], showModal: true, buttonActive: false, feedbackType: type, conceptSuggestions: [] });
  },
  closeFeedBackModal: () => (dispatch) => {
    document.body.style.overflow = 'inherit';
    dispatch({ type: CLOSE_FEEDBACK_MODAL, selectedOptions: [], showModal: false, buttonActive: false, feedbackType: '', conceptSuggestions: [] });
  },
  selectOption: (option) => (dispatch, getState) => {
    const { typeAheadFeedBack } = getState();
    const currentSelectedOptions = [...typeAheadFeedBack.selectedOptions];
    currentSelectedOptions.push(option);
    dispatch({
      type: SELECT_OPTION,
      selectedOptions: currentSelectedOptions,
      showModal: true,
      buttonActive: true,
      feedbackType: typeAheadFeedBack.feedbackType,
      conceptSuggestions: typeAheadFeedBack.conceptSuggestions
    });
  },
  deSelectOption: (option) => (dispatch, getState) => {
    const { typeAheadFeedBack } = getState();
    const currentSelectedOptions = [...typeAheadFeedBack.selectedOptions];
    const optionToRemove = currentSelectedOptions.map((options) => options.display).indexOf(option.display);
    if (optionToRemove !== -1) {
      currentSelectedOptions.splice(optionToRemove, 1);
    }
    dispatch({
      type: DESELECT_OPTION,
      selectedOptions: currentSelectedOptions,
      showModal: true,
      buttonActive: currentSelectedOptions.length !== 0,
      feedbackType: typeAheadFeedBack.feedbackType,
      conceptSuggestions: typeAheadFeedBack.conceptSuggestions
    });
  },
  submitFeedBack: () => (dispatch, getState) => {
    const { typeAheadFeedBack, typeAhead, refiners } = getState();
    const currentSelectedOptions = [...typeAheadFeedBack.selectedOptions];
    const userSuggestions = [typeAheadFeedBack.conceptSuggestions];
    if (typeAheadFeedBack.feedbackType === 'typeahead') {
      submitTypeAheadFeedBack(currentSelectedOptions, typeAhead.KNOWLEDGE);
      dispatch(notifyActions.notifySuccess(
        CONFIG.DISPLAY_TEXT.TYPE_AHEAD_FEEDBACK.THANKYOU_TITLE,
        CONFIG.DISPLAY_TEXT.TYPE_AHEAD_FEEDBACK.THANKYOU_TEXT,
      ));
    } else if (typeAheadFeedBack.feedbackType === 'related_concepts') {
      submitRelevantConceptsFeedback(currentSelectedOptions, refiners, userSuggestions);
      dispatch(notifyActions.notifySuccess(
        CONFIG.DISPLAY_TEXT.TYPE_AHEAD_FEEDBACK.THANKYOU_TITLE,
        CONFIG.DISPLAY_TEXT.RELATED_CONCEPTS_FEEDBACK.THANKYOU_TEXT,
      ));
    }
    dispatch({
      type: SUBMIT_FEEDBACK,
      selectedOptions: currentSelectedOptions,
      showModal: false,
      buttonActive: false,
      feedbackType: typeAheadFeedBack.feedbackType,
      conceptSuggestions: []
    });
    document.body.style.overflow = 'inherit';
  },

};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_OPTION:
    case DESELECT_OPTION:
    case SHOW_FEEDBACK_MODAL:
    case CLOSE_FEEDBACK_MODAL:
    case SUBMIT_FEEDBACK:
      return {
        ...state,
        selectedOptions: action.selectedOptions,
        showModal: action.showModal,
        buttonActive: action.buttonActive,
        feedbackType: action.feedbackType,
        conceptSuggestions: action.conceptSuggestions,
      };
    case CONCEPT_SUGGESTIONS:
      return {
        ...state,
        conceptSuggestions: action.conceptSuggestions,
      };
    default:
      return state;
  }
}
