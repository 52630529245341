import axios from 'axios';
import CONFIG from 'config';

export const name = 'labdetails';

export const API_GET_LABDETAILS_PENDING = 'API_GET_LABDETAILS_PENDING';
export const API_GET_LABDETAILS_FAILURE = 'API_GET_LABDETAILS_FAILURE';
export const API_GET_LABDETAILS_SUCCESS = 'API_GET_LABDETAILS_SUCCESS';

const sources = [];
const initialState = {
  loading: false,
  loadingId: '',
  error: false,
  errorMessage: '',
  data: {}
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getLoadingId: (state) => state[name].loadingId,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getChapters: (state) => state[name].data
};

export const actions = {
  getLabdetails: (query, labCourseId) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_GET_LABDETAILS_PENDING, payload: labCourseId });

    try {
      const labData = await axios.get(
        CONFIG.API_URL.LAB_DETAILS(query, labCourseId),
        { cancelToken: newSource.token }
      );
      dispatch({ type: API_GET_LABDETAILS_SUCCESS, payload: { [labCourseId]: labData } });
    } catch (error) {
      if (error.cancelled) return;
      if (error.error.response.status === 404) {
        dispatch({ type: API_GET_LABDETAILS_SUCCESS, payload: { data: [] } });
      } else {
        dispatch({ type: API_GET_LABDETAILS_FAILURE, payload: error.toString() });
        throw error;
      }
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_LABDETAILS_PENDING:
      return {
        ...state,
        loading: true,
        loadingId: action.payload,
        error: false,
        errorMessage: ''
      };
    case API_GET_LABDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_GET_LABDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
}