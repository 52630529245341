import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'html-react-parser';

import {actions as userActions, name as userReduxName } from 'redux/api/user/user';
import { actions as notifyActions } from 'redux/components/notification/notification';
import { name as peopleImagesName } from 'redux/api/peopleImages/peopleImages';
import { actions as chatActions, name as chatName, ROLES } from 'redux/api/proposalBuilder/chat/chat';
import chatSendSvg from 'assets/images/chat-send.svg';
import chatKnIcon from 'assets/images/navi-logo-no-text.svg';
import copyButtonSvg from 'assets/images/copy-text.svg';
import checkmarkSvg from 'assets/images/checkmark-gray.svg';
import thumbsUpSvg from 'assets/images/thumbs-up.svg';
import thumbsDownSvg from 'assets/images/thumbs-down.svg';
import chatDefaultprofileImg from 'assets/images/default-user.png';
import Icon from 'components/Icon/Icon';
import { copyFormattedTextToClipboard } from 'utils/copyToClipboard/copyToClipboard';
import { LABELS } from 'utils/proposalBuilder/labels';

import * as Styled from './ChatBot.styles';
import * as analytics from './ChatBot.analytics';

import { Modal } from '@kdshared/enrich-ui';

export const ChatBot = ({
  selectedEngine
}) => {
  const dispatch = useDispatch();
  const { images: profileImages } = useSelector((state) => state[peopleImagesName]);
  const {
    query,
    messages,
    loading: chatLoading,
    error: chatError,
    messageSources,
    chatHistoryId
  } = useSelector((state) => state[chatName]);
  const { hrId } = useSelector((state) => state[userReduxName]);
  const inputRef = useRef(null);
  const autoScrollRef = useRef(null);
  const feedbackInputRef = useRef(null);
  const [inputMessage, setInputMessage] = useState('');
  const [userIcon, setUserIcon] = useState(profileImages[hrId]);

  const [performChat, setPerformChat] = useState(false);
  const [feedbackModalState, setFeedbackModalState] = useState({
    show: false,
    chatId: '',
    requestId: '',
    sentiment: 'positive' // positive | negative
  });

  const COPY_BUTTON_STATES = {
    INITIAL: 'initial',
    COPIED: 'copied',
  };
  const [copyButtonState, setCopyButtonState] = useState({});
  const {
    WENT_WRONG, 
    API_FAILED, 
    TRY_LATER, 
    POSITIVE, 
    NEGATIVE, 
    KN_CHAT_IMG, 
    PROFILE_IMG, 
    EXCLAMATION_TRIANGLE, 
    HOURGLASS_HALF, 
    COPY_MESSAGE, 
    THUMBS_UP, 
    CONTINUE_CHAT, 
    START_CHAT, 
    SEND_MESSAGE
  } = LABELS;

  const messageSourcesRef = useRef(messageSources);

  const handleSubmitFeedback = () => {
    analytics.submitChatFeedback(feedbackModalState, feedbackInputRef.current?.value);
    setFeedbackModalState({ show: false, sentiment: 'positive', chatId: '', requestId: '' });
    feedbackInputRef.current.value = '';
  };

  useEffect(() => {
    dispatch(userActions.fetchUserProfilePhoto());
  }, []);

  useEffect(() => {
    messageSourcesRef.current = messageSources;
  }, [messageSources]);

  const handleInputEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async (message) => {
    if ((!message && !inputMessage) || chatLoading) {
      return;
    }

    const content = inputMessage || message;
    dispatch(chatActions.addMessage({ role: ROLES.USER, content }));
    dispatch(chatActions.setQuery(content));

    setPerformChat(true);
    handleScrollChatToBottom(500);
    setInputMessage('');
    inputRef.current.textContent = '';
    inputRef.current?.focus();
    analytics.sendMessage(inputMessage);
  };

  const handleScrollChatToBottom = (scrollDelay) => {
    // only scroll to bottom if auto scroll is true
    // or null - null allows us to scroll to bottom
    // on first load when auto scroll is not set yet
    if (autoScrollRef.current || autoScrollRef.current === null) {
      setTimeout(() => {
        autoScrollRef.current.scrollTo(0, autoScrollRef.current.scrollHeight);
      }, scrollDelay || 0);
    }
  };

  const handleCopyButton = (chat) => {
    copyFormattedTextToClipboard(chat.content);
    setCopyButtonState(cbs => ({ ...cbs, [chat.id]: COPY_BUTTON_STATES.COPIED, proposalBuilderChatId: chat.id }));
  };

  useEffect(() => {
    setCopyButtonState(cbs => {
      if(cbs.proposalBuilderChatId) {
        const newCbs = { ...cbs, proposalBuilderChatId: 0 };
        delete newCbs[cbs.proposalBuilderChatId];
        return newCbs;
      }

      return cbs;
    });
  }, [copyButtonState]);

  const handleStopGenerating = () => {
    dispatch(chatActions.abortFetch());
  };

  useEffect(() => {
    if (performChat && query && query.trim().length > 0) {
      setPerformChat(false);
      dispatch(chatActions.streamChat(selectedEngine, handleScrollChatToBottom));
    }
  }, [performChat, query]);

  useEffect(() => {

    if (profileImages[hrId] === 'error') {
      setUserIcon(chatDefaultprofileImg);
    } else {
      setUserIcon(profileImages[hrId]);
    }
  }, [profileImages, hrId]);

  useEffect(() => {
    
    if (chatError) {
      dispatch(notifyActions.notifyError(WENT_WRONG, TRY_LATER, API_FAILED));
    }
  }, [chatError]);

  const FeedbackModal = () => (
    <Modal
      open={feedbackModalState.show}
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={() => setFeedbackModalState({ show: false, sentiment: 'positive' })}
    >
      <div className='header'>
        <Styled.FeedbackModalHeaderWrapper>
          {feedbackModalState.sentiment === 'positive' && <Styled.FeedbackIconWrapper up={true} src={thumbsUpSvg} />}
          {feedbackModalState.sentiment === 'negative' && <Styled.FeedbackIconWrapper up={false} src={thumbsDownSvg} />}
          Provide Additional Feedback
        </Styled.FeedbackModalHeaderWrapper>
      </div>

      <div className="content">
        <Styled.FeedbackInput
          ref={feedbackInputRef}
          placeholder={feedbackModalState.sentiment === 'positive' ? POSITIVE : NEGATIVE}
        />
      </div>

      <div className='actions'>
        <Styled.SubmitFeedbackButtonWrapper>
          <Styled.ChatActionButton onClick={handleSubmitFeedback}>Submit feedback</Styled.ChatActionButton>
        </Styled.SubmitFeedbackButtonWrapper>
      </div>
    </Modal>
  );

  return <>
    <Styled.Wrapper>
      <Styled.ChatBox>
        <Styled.MessagesWrapper ref={autoScrollRef}>
          {messages?.map((chat, i) => {
            
            if ((chat?.id === undefined && chat?.id === null) || chat.role === ROLES.STATUS) {
              return null;
            }

            const currentChatSources = messageSources[chat.id];

            const chatContent = chat.content.replace(/\n/ig, '<br />');
            const chatContentSplitByLinks = chatContent.split(/<\/sup>/g);

            return (
              <Styled.MessageWrapper data-testid="message" $role={chat.role} key={`${chat.id}-${i}`}>
                <Styled.CenterColumn>
                  <Styled.InnerColumn>
                    <Styled.ProfileWrapper>
                      {chat.role === ROLES.ASSISTANT && <Styled.ProfileImage $role={chat.role} src={chatKnIcon} alt={KN_CHAT_IMG} />}
                      {chat.role === ROLES.USER && <Styled.ProfileImage $role={chat.role} src={userIcon} alt={PROFILE_IMG} />}
                    </Styled.ProfileWrapper>

                    <Styled.MessageText $role={chat.role}>
                      {chat.role === ROLES.STATUS && <span><Icon name="check" color="green" /></span>}
                      {chat.role === ROLES.ERROR && <span><Icon name={EXCLAMATION_TRIANGLE} color="red" /></span>}
                      {chat.role === ROLES.RETRY && <span><Icon name={HOURGLASS_HALF} /></span>}
                      {!currentChatSources && ReactHtmlParser(chatContent)}
                      {currentChatSources && chatContentSplitByLinks.map((chatContentPartWithLink) => (
                        <>
                          {ReactHtmlParser(chatContentPartWithLink.split(/<sup/g)?.[0])}
                        </>
                      ))}
                    </Styled.MessageText>
                    <div>
                      <div>
                        {chat.role === ROLES.ASSISTANT && <Styled.ChatActionIconWrapper
                          src={copyButtonState[chat.id] === COPY_BUTTON_STATES.COPIED ? checkmarkSvg : copyButtonSvg}
                          onClick={() => handleCopyButton(chat)}
                          data-testid="copy-button"
                          alt={COPY_MESSAGE}
                          title={COPY_MESSAGE}
                        />}
                      </div>
                      <div>
                        {chat.role === ROLES.ASSISTANT && <Styled.ChatActionIconWrapper
                          src={thumbsUpSvg}
                          onClick={() => {
                            setFeedbackModalState({ show: true, sentiment: 'positive', chatId: chat?.id, requestId: chat?.requestId });
                          }}
                          data-testid="thumbs-up-button"
                          alt={THUMBS_UP}
                          title={THUMBS_UP}
                        />}
                      </div>
                      <div>
                        {chat.role === ROLES.ASSISTANT && <Styled.ChatActionIconWrapper
                          src={thumbsDownSvg}
                          onClick={() => {
                            setFeedbackModalState({ show: true, sentiment: 'negative', chatId: chat.id, requestId: chat?.requestId });
                          }}
                          data-testid="thumbs-up-button"
                          alt={THUMBS_UP}
                          title={THUMBS_UP}
                        />}
                      </div>
                    </div>
                  </Styled.InnerColumn>
                </Styled.CenterColumn>
              </Styled.MessageWrapper>
            );
          })}

          {(chatLoading) &&
            <Styled.CenterColumn>
              <Styled.InnerColumn>
                <Styled.ChatLoading />
              </Styled.InnerColumn>
            </Styled.CenterColumn>}
        </Styled.MessagesWrapper>

      </Styled.ChatBox>
      <Styled.Bottom>
        <Styled.CenterColumn>
          {(chatLoading) &&
            <Styled.InnerColumn justifyContent={'flex-end'}>
              <Styled.ChatActionButton onClick={handleStopGenerating} data-testid="stop-generating-button">
                Stop generating
              </Styled.ChatActionButton>
            </Styled.InnerColumn>
          }
          <Styled.InnerColumn>
            <Styled.InputWrapper onClick={() => inputRef.current?.focus()}>
              <Styled.ChatInput
                value={inputMessage}
                placeholder={chatHistoryId ?  CONTINUE_CHAT : START_CHAT}
                contentEditable-={true}
                suppressContentEditableWarning={true}
                tabIndex={0}
                onInput={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleInputEnter}
                ref={inputRef}
                height={inputMessage ? `${inputRef.current?.scrollHeight}px` : '28px'}
                data-testid="chat-input"
              />
              <Styled.SendButton
                src={chatSendSvg}
                onClick={() => sendMessage()}
                data-testid="send-button"
                alt={SEND_MESSAGE}
                $disabled={chatLoading}
              />
            </Styled.InputWrapper>
          </Styled.InnerColumn>
        </Styled.CenterColumn>
      </Styled.Bottom>

      <FeedbackModal />

    </Styled.Wrapper >
  </>;

};

ChatBot.propTypes = {
  selectedEngine: PropTypes.string.isRequired,
  initialQuery: PropTypes.string
};

ChatBot.defaultProps = {
  selectedEngine: 'gpt-4',
  initialQuery: '',
};

export default ChatBot;