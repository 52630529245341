import { getParameterValueFromUrl } from 'utils/string-mapper/string-mapper';
import { NO_VAR } from './analytics_constants';
import PAGE_ANALYTICS from './page_analytics';

const labelsThatRequireTransformation = ['Original_Published_Date.desc'];

export default {
  async changeSort(previousSortOrder, selectedSortOrder, projectId = null) {
    let previousSortOrderForAnalytics = previousSortOrder;
    let selectedSortOrderForAnalytics = selectedSortOrder; 

    // some labels require transformation
    const previousSortOrderContainsTransformationText = labelsThatRequireTransformation.includes(previousSortOrderForAnalytics);
    if (previousSortOrderContainsTransformationText) {
      const split = previousSortOrderForAnalytics.split('.');
      // Make dynamic if we need to support more than just `datepublished`
      previousSortOrderForAnalytics = `datepublished.${split[1]}`;
    }
    const selectedSortOrderContainsTransformationText = labelsThatRequireTransformation.includes(selectedSortOrderForAnalytics);
    if (selectedSortOrderContainsTransformationText) {
      const split = selectedSortOrderForAnalytics.split('.');
      // Make dynamic if we need to support more than just `datepublished`
      selectedSortOrderForAnalytics = `datepublished.${split[1]}`;
    }

    PAGE_ANALYTICS.setDefaultQueryProps();
    const adobe = {
      selectedSortOrder: selectedSortOrderForAnalytics,
      preOrderTerm: previousSortOrderForAnalytics,
      previousSortOrder: previousSortOrderForAnalytics,
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    adobe.caseID = undefined;
    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('sort_order_selected');
  },

  async changeRecommendedBy(resultsShowOptions) {
    PAGE_ANALYTICS.setDefaultQueryProps();
    const adobe = { resultsShowOptions };
    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('results_show');
  }
};