import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'components/FlexBoxGrid';
import Icon from 'components/Icon/Icon';
import Progress from 'components/Progress/Progress';

import CONFIG from 'config';
import './DownloadProgressBars.scss';

export class DownloadProgressBars extends PureComponent {
  render() {
    const {
      clippedData,
      handleCloseProgressBars
    } = this.props;
    return (
      <Grid className="progressBarMain" data-testid={CONFIG.TEST_IDS.DOWNLOAD_PROGRESS.PROGRESS_CONTAINER}>
        <Row className="modalHeadingBar">
          <Col xs={12}>
            <div className="modalHeader">{CONFIG.DISPLAY_TEXT.DOWNLOAD_PROGRESS.MODAL_HEADING}</div>
            <Icon name="minus" className="closeModal" onClick={() => handleCloseProgressBars()} />
            <div className="clear-fix" />
          </Col>
        </Row>
        <div className="progressBarsContainers">
          {Object.keys(clippedData).map(((key) =>
            (
              <Row className="attachmentInfo" key={key}>
                <Col xs={7} className="attachTitle">
                  <p className="attachName">{clippedData[key].fileName}</p>
                </Col>
                <Col xs={4}>
                  <Progress percent={clippedData[key].percentage} size="small" className="bar" />
                </Col>
                <Col xs={1}>
                  <p className="percentage">{clippedData[key].percentage}%</p>
                </Col>
              </Row>
            )
          ))}
        </div>
      </Grid>
    );
  }
}

DownloadProgressBars.propTypes = {
  clippedData: PropTypes.shape().isRequired,
  handleCloseProgressBars: PropTypes.func.isRequired
};

export default DownloadProgressBars;