import axios from 'axios';
import * as utils from '@kdshared/okta-utils';
import CONFIG from 'config';
import authService from 'redux/auth';
import { sendToLocalStorage, getFromLocalStorage } from 'utils/localStorage/localStorage';
import { getUserEmail, isUserAuthenticated } from 'utils/auth/auth';
import { removeLineBreaksUriComponent } from 'utils/string-mapper/string-mapper';
import { actions as peopleImageActions } from '../peopleImages/peopleImages';
import { testAccountsIds } from 'utils/user/user';

export const name = 'user';

export const USER_STATUS = {
  PENDING: 'API_USER_PENDING',
  DONE: 'API_USER_DONE'
};
export const NAVI_ACCESS = 'NAVI_ACCESS';
export const MULTI_SEARCH_TOOL_PILOT_USER = 'MULTI_SEARCH_TOOL_PILOT_USER';
export const USER_NAVI_TOOLS = 'USER_NAVI_TOOLS';


const initialState = { apiStatus: USER_STATUS.PENDING, hasAIAccessRetrievalStatus: USER_STATUS.PENDING };

const checkhasAIAccess = (officeName) => {
  if(officeName){
    const hasAIAccess = !CONFIG.GPT_RESTRICTED_LOCATIONS.some(loc => officeName.toLowerCase().includes(loc.toLowerCase()));
    return hasAIAccess;
  }
  return false;
};

export const actions = {
  getUserRegionFromApi: () => async (dispatch) => {
    let hrId = '';
    if (await isUserAuthenticated()) {
      const accessToken = await authService.getAccessToken();
      try {
        hrId = JSON.parse(window.atob(accessToken?.split('.')[1])).hrid;
        if (!hrId && CONFIG.ENVIRONMENT !== 'prod') {
          hrId = '383548'; // statically using Neer's ID, should only occur in pre-prod envs
        }
      } catch (ex) {
        console.error('getUserRegionFromAPI error parsing access token', ex.toString());
      }
      let region = 'n/a';
      let officeName = 'n/a';
      if (hrId) {
        try {
          const data = await axios
            .post(
              CONFIG.API_URL.PEOPLE,
              {
                query: `
                  query {
                    employees(ids: ["${hrId}"]) {
                      hostOfficeRegion
                      hostOfficeLocation {
                        name
                      }
                    }
                  }
                `
              },
              {
                headers: {
                  'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                  psId: utils.getPsId()
                }
              }
            );

          if (CONFIG.ENVIRONMENT !== 'prod' && testAccountsIds.some(act => act.id === hrId)) {
            const testAcct = testAccountsIds.find(act => act.id === hrId);
            console.error('mock test account used - user profile for hrId', hrId, '\ntestAcct:', testAcct, '\npeopleApi data:', data);
            data.data.employees.push(testAcct);
          }

          if (data?.data?.employees[0]?.hostOfficeRegion) {
            region = data.data.employees[0].hostOfficeRegion;
            const regionName = CONFIG.REGION_MAPPING.find((r) => r.ABBREVIATION === region)?.NAME;
            sendToLocalStorage(CONFIG.LOCAL_STORAGE.USER_REGION_NAME, regionName);
            if (regionName === undefined) { // '' is a valid value
              console.error('getUserRegionFromAPI no match for', removeLineBreaksUriComponent(region), 'in our config');
            }
          } else {
            console.error('getUserRegionFromAPI data missing from people API for hrid', hrId, '; region:', region, 'data:', data);
          }
          if (data?.data?.employees[0]?.hostOfficeLocation?.name) {
            officeName = data.data.employees[0].hostOfficeLocation.name;
            sendToLocalStorage(CONFIG.LOCAL_STORAGE.USER_LOCATION_NAME, officeName);
            dispatch({ type: NAVI_ACCESS, payload: { hasAIAccess: checkhasAIAccess(officeName)}}); 
          } else {
            console.error('getUserRegionFromAPI data missing from people API for hrid', hrId, '; officeName:', officeName, 'data:', data);
          }
        } catch (ex) {
          console.error('getUserRegionFromAPI error retrieving from people API for hrid', hrId, '; region:', removeLineBreaksUriComponent(region), '; officeName:', removeLineBreaksUriComponent(officeName), 'error:', ex.toString());
        }
      } else {
        console.error('getUserRegionFromAPI could not retrieve the hrid from the accessToken');
      }
      if (CONFIG.ENVIRONMENT !== 'prod' && testAccountsIds.some(act => act.id === hrId)) {
        hrId = '383548'; // statically using Neer's ID, should only occur in pre-prod envs
      }
      dispatch({ type: USER_STATUS.DONE, payload: { hrId } });
      return;
    }
  },
  getUserRegionFromStorage: () => {
    try {
      return getFromLocalStorage(CONFIG.LOCAL_STORAGE.USER_REGION_NAME);
    } catch (err) {
      return '';
    }
  },
  getDataFromStorage: () => {
    try {
      return {
        region: getFromLocalStorage(CONFIG.LOCAL_STORAGE.USER_REGION_NAME) || '',
        location: getFromLocalStorage(CONFIG.LOCAL_STORAGE.USER_LOCATION_NAME) || ''
      };
    } catch (err) {
      return null;
    }
  },
  getUserOfficeNameFromStorage: () => {
    try {
      return getFromLocalStorage(CONFIG.LOCAL_STORAGE.USER_LOCATION_NAME);
    } catch (err) {
      return '';
    }
  },
  getIsPilotUser: () => async (dispatch) => {
    try {
      if (await isUserAuthenticated()) {
        const email = await getUserEmail();
        const multiSearchPilotUsers = await fetch(CONFIG.API_URL.MULTI_SEARCH_TOOL_PILOT_USERS)
          .then((response) => response.json());
        const isMultiSearchPilotUser = multiSearchPilotUsers[0] === 'all' || multiSearchPilotUsers.some((user) => user.toLowerCase() === email.toLowerCase());

        dispatch({ type: MULTI_SEARCH_TOOL_PILOT_USER, payload: { isPilotUser: multiSearchPilotUsers[0] === 'all' || multiSearchPilotUsers.some((user) => user.toLowerCase() === email.toLowerCase()) } });

        const userAccessNaviTools = CONFIG.NAVI_TOOL_SUGGESTIONS_LIST
          .filter((tool) => {
            return (CONFIG.FEATURE_TOGGLES.SHOW_MULTI_SEARCH_TOOL ? (isMultiSearchPilotUser || tool.name !== CONFIG.NAVI_TOOL_NAMES.ALL) : tool.name !== CONFIG.NAVI_TOOL_NAMES.ALL);
          });
        dispatch({ type: USER_NAVI_TOOLS, payload: { userNaviToolsList: userAccessNaviTools } });
      }
    } catch (ex) {
      console.error('getIsPilotUser', ex.toString());
    }
  },
  fetchUserProfilePhoto: () => async (dispatch) => {
    try {
      let hrId = '';
      if (await isUserAuthenticated()) {
        const accessToken = await authService.getAccessToken();
        try {
          hrId = JSON.parse(window.atob(accessToken?.split('.')[1])).hrid;
          if (CONFIG.ENVIRONMENT !== 'prod' && testAccountsIds.some(act => act.id === hrId)) {
            hrId = '383548'; // statically using Neer's ID, should only occur in pre-prod envs
          }
        } catch (ex) {
          console.error('fetchUserProfilePhoto error parsing access token', ex.toString());
        }
        dispatch(peopleImageActions.fetchPeopleImages([hrId]));
      }
    } catch (ex) {
      console.error('fetchUserProfilePhoto', ex.toString());
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_STATUS.PENDING:
    case USER_STATUS.DONE:
      return {
        ...state,
        apiStatus: action.type,
        hrId: action.payload?.hrId
      };
    case NAVI_ACCESS:
      return {
        ...state,
        hasAIAccess: action.payload.hasAIAccess,
        hasAIAccessRetrievalStatus: USER_STATUS.DONE,
      };
    case MULTI_SEARCH_TOOL_PILOT_USER:
      return {
        ...state,
        multiSearchToolPilotUser: action.payload.isPilotUser,
      };
    case USER_NAVI_TOOLS:
      return {
        ...state,
        userNaviToolsList: action.payload.userNaviToolsList,
      };
    default:
      return state;
  }
};
