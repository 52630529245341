import React, { useEffect } from 'react';
import CONFIG from 'config';
import Hashes from 'jshashes';
import { datadogRum } from '@datadog/browser-rum';
import * as utils from '@kdshared/okta-utils';
import { isUserAuthenticated, getUserEmail, getOktaExpirations } from 'utils/auth/auth';

datadogRum.init({
  applicationId: CONFIG.DATA_DOG.APPLICATION_ID,
  clientToken: CONFIG.DATA_DOG.CLIENT_TOKEN,
  service: CONFIG.APP_NAME,
  env: CONFIG.ENVIRONMENT,
  version: CONFIG.SMART_SEARCH_VERSION,
  site: 'datadoghq.com',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 40,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

const DatadogRum = () => {
  useEffect(() => {
    const setGlobal = async () => {
      let retryCount = 0;
      let email = 'unknown';
      if (await isUserAuthenticated()) {
        email = await getUserEmail();
      }
      const [oktaAccessExpiration, oktaIdExpiration] = getOktaExpirations();
      if (email) {
        const staffSystemId = new Hashes.SHA256().hex(email.toLowerCase());
        datadogRum.setGlobalContext('user', {
          staffSystemId,
          principal: email,
          oktaAccessExpiration: oktaAccessExpiration || 'empty',
          oktaIdExpiration: oktaIdExpiration || 'empty',
          psid: utils.getPsId()
        });
        datadogRum.setUser({
          id: staffSystemId,
          email: email
        });
      } else if (retryCount < 5) {
        retryCount += 1;
        setTimeout(setGlobal, 1000);
      }
    };
    setGlobal();
  }, []);

  return <div />;
};

export default DatadogRum;