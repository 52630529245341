import axios from 'axios';
import CONFIG from 'config';
import { keyBy, merge, orderBy } from 'lodash';
import * as utils from '@kdshared/okta-utils';
import { isNotNull } from 'utils/string-mapper/string-mapper';
import { DEFAULT_CHECKBOX_TRUE, getNameFromAttributeCode } from 'utils/filters/filters';

export const INCLUDE_FLAG = '/YES/';
export const CUSTOM_DATES = 'Custom Dates';
export const CUSTOM_DATE_VALUE_REGEX = /[0-9]to[0-9]/;

export const mapItem = (filterItem, parent, filterConfig) => {
  const attributeValue = getNameFromAttributeCode(filterItem.attributeCode) || filterItem.attributeValue;

  return {
    filter: filterConfig,
    count: +filterItem?.count,
    name: attributeValue,
    MULTISELECT: filterConfig?.MULTISELECT || false,
    hasNextLevelOptions: !!filterItem?.children?.length,
    field: attributeValue,
    path: `/${filterItem.attributeCode?.replace(/>/g, '/') || attributeValue}/`,
    display: `${attributeValue} (${filterItem.count})`,
    parent,
    children: filterItem?.children ? 
      filterItem.children.map(i => mapItem(i, attributeValue, filterConfig)) :
      []
  };
};

export const buildQuery = (query, pageNumber, pageSize) => {
  const checkboxFilters = Object.entries(CONFIG.EXPERT_FILTERS.FILTER_BAR).filter(f => f[1].CHECKBOX_ONLY_FILTER).map(f => f[1]);
  const filters = Object.entries(query)
    .filter((f) => f[0] !== 'query' && f[0] !== 'enableAutoCorrect' && f[0] !== 'sortingOrder' && !checkboxFilters.some(c => c.QUERY_PARAM === f[0]))
    .map(f => ({
      filterName: f[0], //query param key name
      filterText: f[1].map(fv => fv.display?.replace(/\//g, '>')), // used for expertise filters
      data: f[1].map(fv => ({ //used for other filters
        attributeValue: fv.display,
        attributeCode: fv.display,
        state: true
      })),
    }));
  const searchQuery = {
    filters,
    pageNumber: pageNumber || 0,
    pageSize: pageSize || 20,
    query: query?.query || '',
    sortDirection: 'desc',
    sortValue: query?.sortingOrder,
    includeAlumni: query.includeAlumni && query.includeAlumni[0].name === DEFAULT_CHECKBOX_TRUE,
    onlyCCOProfiles: query.onlyCCOProfiles && query.onlyCCOProfiles[0].name === DEFAULT_CHECKBOX_TRUE,
    onlyCertifiedProfiles: query.onlyCertifiedProfiles && query.onlyCertifiedProfiles[0].name === DEFAULT_CHECKBOX_TRUE
  };

  return searchQuery;
};

export const getAndMergePeopleData = async (results, sources) => {
  const ids = results.map(r => `"${r.hrEmployeeId}"`);
  sources.push(axios.CancelToken.source());
  const peopleData = await axios
    .post(
      CONFIG.API_URL.PEOPLE,
      {
        query: `
            query {
              employees(ids: [${ids.join()}]) {
               id
               slackId
               hostOfficeLocation {
                timezone
               }
              }
             }`
      },
      {
        headers: {
          'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
          psId: utils.getPsId()
        }
      }
    );

  if (!peopleData?.data?.employees?.length)
    return results;

  const emps = peopleData.data.employees.map(e => ({
    ...e,
    slackId: isNotNull(e.slackId)
  }));

  return orderBy(
    Object.values(
      merge(keyBy(results, 'hrEmployeeId'), keyBy(emps, 'id'))
    ), 'relevancyDetails.position');
};