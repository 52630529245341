import axios from 'axios';
import CONFIG from 'config';
import authService from 'redux/auth';
import { getRandomString } from 'utils/string-mapper/string-mapper';

export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  STATUS: 'status',
  RETRY: 'retry',
  ERROR: 'error'
};

export const name = 'proposalBuilderChat';

export const PROPOSAL_BUILDER_CHAT_SET_HISTORY_ID = 'PROPOSAL_BUILDER_CHAT_SET_HISTORY_ID';
export const PROPOSAL_BUILDER_CHAT_PENDING = 'PROPOSAL_BUILDER_CHAT_PENDING';
export const PROPOSAL_BUILDER_CHAT_REPLACE_MESSAGE = 'PROPOSAL_BUILDER_CHAT_REPLACE_MESSAGE';
export const PROPOSAL_BUILDER_CHAT_FAILURE = 'PROPOSAL_BUILDER_CHAT_FAILURE';
export const PROPOSAL_BUILDER_RESET_CHAT_STATE = 'PROPOSAL_BUILDER_RESET_CHAT_STATE';
export const PROPOSAL_BUILDER_SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS = 'PROPOSAL_BUILDER_SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS';
export const PROPOSAL_BUILDER_CHAT_STREAM_CHUNK = 'PROPOSAL_BUILDER_CHAT_STREAM_CHUNK';
export const PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE = 'PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE';
export const PROPOSAL_BUILDER_CHAT_SET_QUERY = 'PROPOSAL_BUILDER_CHAT_SET_QUERY';
export const PROPOSAL_BUILDER_CHAT_ADD_MESSAGE = 'PROPOSAL_BUILDER_CHAT_ADD_MESSAGE';
export const PROPOSAL_BUILDER_CHAT_DELETE_LATEST_ASSISTANT_MESSAGE = 'PROPOSAL_BUILDER_CHAT_DELETE_LATEST_ASSISTANT_MESSAGE';
export const PROPOSAL_BUILDER_SET_FETCH_CONTROLLER = 'PROPOSAL_BUILDER_SET_FETCH_CONTROLLER';
export const PROPOSAL_BUILDER_NEW_ERROR_STATUS = 'PROPOSAL_BUILDER_NEW_ERROR_STATUS';
export const PROPOSAL_BUILDER_REMOVE_FETCH_CONTROLLER = 'PROPOSAL_BUILDER_REMOVE_FETCH_CONTROLLER';
export const PROPOSAL_BUILDER_SET_SELECTED_ENGINE = 'PROPOSAL_BUILDER_SET_SELECTED_ENGINE';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  query: '',
  messages: [],
  hasSentInitialMessage: false,
  messageSources: {},
  fetchController: null,
  selectedEngine: 'gpt-4',
  chatHistoryId: null
};

const errorContinueMessage = {
  'role': 'user',
  'content': 'Your previous message was cut off due to an error. Please respond seamlessly from where you left off.'
};

export const selectors = {
  isLoading: (state) => state[name].loading,
};

const setHasSentInitialMessage = (hasSentInitialMessage) => {
  return { type: PROPOSAL_BUILDER_SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS, payload: hasSentInitialMessage };
};

const streamChat = (selectedEngine, onChunk, retry = false) => async (dispatch, getState) => {
  dispatch({ type: PROPOSAL_BUILDER_CHAT_PENDING });
  const _query = getState()[name].query;
  const streamMessages = getState()[name].messages;

  if ((!_query || _query?.length === 0) && (!streamMessages || streamMessages?.length === 0)) {
    console.error('KNCHAT callChat: No query/messages to send');
    return null;
  }

  const requestId = getRandomString(20);
  try {
    const _messages = streamMessages.filter(m => !!m.content && (m.role === ROLES.USER || m.role === ROLES.ASSISTANT)).map(message => {
      return {
        role: message.role,
        content: message.content
      };
    });

    const accessToken = await authService.getAccessToken();

    const headers = new Headers();
    headers.append('accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('x-api-key', CONFIG.X_API_KEY);

    console.log('KNCHAT requestId:', requestId);

    const body_json = {
      'gen_options': {
        'max_tokens': 800,
        'stream': true
      },
      'request_id': requestId,
      'consumer_id': 'KN',
      'engine': selectedEngine
    };
    if (getState()[name].chatHistoryId) {
      body_json.chat_history_id = getState()[name].chatHistoryId;
    }
    if (_query && _query.length > 0 && CONFIG.API_URL.GENAI_CHAT.includes('/v2/')) {
      body_json.query = retry ? errorContinueMessage.content.concat(',', _query) : _query;
    }
    else {
      body_json.messages = retry ? _messages.concat(errorContinueMessage) : _messages;
    }
    const body = JSON.stringify(body_json);

    const fetchController = new AbortController();
    const requestOptions = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
      signal: fetchController.signal
    };
    dispatch({ type: PROPOSAL_BUILDER_SET_FETCH_CONTROLLER, payload: fetchController });
    const startTime = new Date();
    // using fetch as axios doesn't support this type of stream
    const response = await fetch('https://api-kd-dev.integration.smp.bcg.com/genai-chat/v2/chat/', requestOptions);
    const reader = response.body.pipeThrough(new window.TextDecoderStream()).getReader();

    let boldMarkdown = '';
    let boldMarkdownOpen = false;
    let waitForBoldMarkdown = false;

    let contentCnt = 0;
    let chunkCnt = 0;

    let endedCleanly = false;
    let createdNewMessage = false;
    let shouldProcessMessage = true;

    let chatId = '';
    let processChunks = true;
    while (processChunks) {
      const { value, done } = await reader.read();
      if (done) {
        console.warn(`KNCHAT done with ${contentCnt} chunks ${new Date() - startTime}ms; requestId: ${requestId};`);
        processChunks = false;
        break;
      }
      if (value) {
        // expected format of value is "data: value\n\ndata: value\n\ndata: value"
        const chunks = value.split('\n\n').filter(c => c);

        let content = null;
        chunks.forEach(chunk => {
          chunkCnt++;
          // expected format of chunk is "data: value"
          const data = chunk.replace('data:', '').trim();
          // console.log('KNCHAT chunk', data);
          let json = {};
          try {
            json = data ? JSON.parse(data) : {};
            // we receive many chunks, but only chunks with choices[0].delta.content are chat messages
            content = json?.choices?.length > 0 ? json.choices[0].delta?.content : null;
            if (!chatId && json?.id) {
              chatId = json.id;
            }
          } catch (ex) {
            console.error(`KNCHAT failed to parse json. Attempting regex. error:${ex}; data:${data}; value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
            // attempt to get content using regex
            let matches = data.match(/"content":\s*"([^"]*)"/i);
            if (matches?.length >= 1) {
              content = matches[1];
            } else {
              if (retry) {
                dispatch({ type: PROPOSAL_BUILDER_NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
                throw new Error(`KNCHAT Second attempt failed to find content in chunk. error:${ex}; data:${data}; matches:${matches}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
              } else {
                console.error(`KNCHAT failed to find content in chunk. Retrying. error:${ex}; data:${data}; matches:${matches}; value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);

                // stop this API call and let's try again
                content = null;
                processChunks = false;
                endedCleanly = true; // yes really, if false it'll trigger an error which isnt accurate
                shouldProcessMessage = false;
                dispatch({ type: PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE, payload: { content: 'I\'ve hit a snag - continuing in a moment.', role: ROLES.RETRY, processed: true, requestId } });
                console.log('KNCHAT run again');
                fetchController.abort('trying again');
                return dispatch(streamChat(selectedEngine, onChunk, true));
              }
            }
          }

          if (content) {
            if (contentCnt === 0) {
              console.warn(`KNCHAT time to first chunk ${new Date() - startTime}ms; requestId: ${requestId}; chatId: ${chatId};`);
            }
            let newContent = content;
            newContent = newContent.replace(/\n/g, '<br/>');
            if (newContent.match(/\*{1,2}/ig)) {
              // has a *, is it mardown for bold **
              boldMarkdown += content;
              waitForBoldMarkdown = true;
            } else if (waitForBoldMarkdown) {
              boldMarkdown += content;
              if (boldMarkdown.match(/\*\*/ig)) {
                if (!boldMarkdownOpen) {
                  newContent = boldMarkdown.replace(/\*\*/ig, '<b>');
                  boldMarkdownOpen = true;
                }
                else {
                  newContent = boldMarkdown.replace(/\*\*/ig, '</b>');
                  boldMarkdownOpen = false;
                }
                waitForBoldMarkdown = false;
                boldMarkdown = '';
              }
            }

            if (!waitForBoldMarkdown) {
              if (!createdNewMessage) {
                dispatch({ type: PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE, payload: { role: ROLES.ASSISTANT, content: newContent, processed: false, requestId } });
                createdNewMessage = true;
              } else {
                dispatch({ type: PROPOSAL_BUILDER_CHAT_STREAM_CHUNK, payload: { content: newContent } });
                onChunk && onChunk();
              }
              // console.log('KNCHAT added', content);
            }
            contentCnt++;
          }
          if (json?.user_message) {
            console.log(`KNCHAT message: ${json.user_message}; requestId: ${requestId}; chatId: ${chatId};`);
            dispatch({ type: PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE, payload: { content: json.user_message, role: ROLES.STATUS, processed: true, requestId } });
            onChunk && onChunk();
            createdNewMessage = false;
          }
          if (json?.system_message) {
            switch (json.system_message) {
              case 'usage':
                console.warn(`KNCHAT usage: ${JSON.stringify(json.usage)}; requestId: ${requestId}; chatId: ${chatId};`);
                break;
              case 'END CHAT':
                processChunks = false;
                endedCleanly = true;
                dispatch({ type: PROPOSAL_BUILDER_CHAT_SET_HISTORY_ID, payload: json.chat_history_id });
                break;
              default:
                break;
            }
            console.warn(`KNCHAT system message: ${json.system_message}; requestId: ${requestId}; chatId: ${chatId};`);
            if (json?.error) {
              shouldProcessMessage = false;
              dispatch({ type: PROPOSAL_BUILDER_NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
              console.error(`KNCHAT system error: ${json.error}; requestId: ${requestId}; chatId: ${chatId};`);
            }
          }
        });
      }
    }

    if (!endedCleanly) {
      console.warn(`KNCHAT ended prematurely. chatId: ${chatId}; chunkCnt: ${chunkCnt}; contentCnt: ${contentCnt};`);
    }

    if (shouldProcessMessage) {
      const unprocessedMessages = getState()[name].messages.filter(m => !m.processed);
      unprocessedMessages.forEach(message => {
        const processedMessage = processMessage(message);
        dispatch({ type: PROPOSAL_BUILDER_CHAT_REPLACE_MESSAGE, payload: processedMessage });
      });
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('KNCHAT callChat: API call aborted', error, 'requestId:', requestId);
    } else {
      console.error('KNCHAT callChat: API call failed', error, 'requestId:', requestId);
      dispatch({ type: PROPOSAL_BUILDER_CHAT_FAILURE, payload: error });
      return null;
    }
  }
};

const resetChatState = () => (dispatch) => {
  dispatch({ type: PROPOSAL_BUILDER_RESET_CHAT_STATE });
};

const processMessage = (message) => {
  return {
    ...message
  };
};

const setChatHistoryId = (chatHistoryId) => (dispatch) => {
  dispatch({ type: PROPOSAL_BUILDER_CHAT_SET_HISTORY_ID, payload: chatHistoryId });
};

const setQuery = (query) => (dispatch) => {
  dispatch({ type: PROPOSAL_BUILDER_CHAT_SET_QUERY, payload: query });
};

const addMessage = (message) => (dispatch) => {
  dispatch({ type: PROPOSAL_BUILDER_CHAT_ADD_MESSAGE, payload: message });
};

const abortFetch = () => (dispatch, getState) => {
  const fetchController = getState()[name].fetchController;
  if (fetchController !== null) {
    fetchController.abort('User clicked stop generating');
  }

  dispatch({ type: PROPOSAL_BUILDER_REMOVE_FETCH_CONTROLLER });
};

const setSelectedEngine = (engine) => (dispatch) => {
  dispatch({ type: PROPOSAL_BUILDER_SET_SELECTED_ENGINE, payload: engine });
};

export const actions = {
  streamChat,
  setHasSentInitialMessage,
  resetChatState,
  setQuery,
  addMessage,
  abortFetch,
  setSelectedEngine,
  setChatHistoryId
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPOSAL_BUILDER_CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case PROPOSAL_BUILDER_CHAT_REPLACE_MESSAGE:
      const newMessages = state.messages.map(m => {
        if (m.id === action.payload.id) {
          return action.payload;
        }
        return m;
      });
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: newMessages
      };
    case PROPOSAL_BUILDER_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
      };
    case PROPOSAL_BUILDER_SET_FETCH_CONTROLLER:
      return {
        ...state,
        fetchController: action.payload
      };
    case PROPOSAL_BUILDER_REMOVE_FETCH_CONTROLLER:
      return {
        ...state,
        loading: false,
        fetchController: null
      };
    case PROPOSAL_BUILDER_RESET_CHAT_STATE:
      if (state.fetchController !== null) {
        state.fetchController.abort('User cancelled/reset chat');
      }
      return initialState;
    case PROPOSAL_BUILDER_CHAT_ADD_MESSAGE:
      const replaceLastMessage = () => {
        const messagesCopy = [...state.messages];
        messagesCopy[messagesCopy.length - 1] = action.payload;
        return messagesCopy;
      };

      const isUser = action.payload.role === ROLES.USER;
      const isLastMessageAnError = state.messages[state.messages.length - 1]?.role === ROLES.ERROR;
      const id = state.messages.length > 1 ? state.messages[state.messages.length - 1].id + 1 : 0;
      return {
        ...state,
        messages: isUser && isLastMessageAnError
          ? replaceLastMessage()
          : [...state.messages, { ...action.payload, id }],
      };
    case PROPOSAL_BUILDER_CHAT_DELETE_LATEST_ASSISTANT_MESSAGE:
      const deleteLatestAssistantMessage = () => {
        const _messages = [...state.messages];
        for (let i = _messages.length - 1; i >= 0; i--) {
          if (_messages[i]?.role === ROLES.ASSISTANT) {
            _messages.splice(i, 1);
            break;
          }
        }
        return _messages;
      };
      return {
        ...state,
        messages: deleteLatestAssistantMessage()
      };
    case PROPOSAL_BUILDER_SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS:
      return {
        ...state,
        hasSentInitialMessage: action.payload,
      };
    case PROPOSAL_BUILDER_CHAT_STREAM_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, { ...action.payload, id: state.messages[state.messages.length - 1].id + 1 }],
      };
    case PROPOSAL_BUILDER_NEW_ERROR_STATUS:
      return {
        ...state,
        loading: false,
        messages: [
          ...state.messages,
          action.payload
        ]
      };
    case PROPOSAL_BUILDER_CHAT_STREAM_CHUNK:
      const appendToLastAssistantMessage = () => {
        const reversedMessages = [...state.messages].reverse();
        const lastAssistantMessage = reversedMessages.find(m => m.role === ROLES.ASSISTANT);
        if (lastAssistantMessage) {
          const indexToUpdate = reversedMessages.findIndex(m => m === lastAssistantMessage);
          reversedMessages[indexToUpdate].content += action.payload.content;
        }
        return reversedMessages.reverse();
      };

      return {
        ...state,
        messages: appendToLastAssistantMessage(),
      };
    case PROPOSAL_BUILDER_SET_SELECTED_ENGINE:
      return {
        ...state,
        selectedEngine: action.payload
      };
    case PROPOSAL_BUILDER_CHAT_SET_HISTORY_ID:
      return {
        ...state,
        chatHistoryId: action.payload
      };
    case PROPOSAL_BUILDER_CHAT_SET_QUERY:
      return {
        ...state,
        query: action.payload
      };
    default:
      return state;
  }
};