import { withRouter } from 'react-router';
import { ProposalFormStyles } from './ProposalForm.style';

//components
import { ChatBot } from 'containers/ProposalBuilder/ChatBot/ChatBot';

export const ProposalForm = () => {

  return (
    <ProposalFormStyles>
      <div className="inner-chatbot">
        <ChatBot
          selectedEngine='gpt-4'
          initialQuery=''
        />
      </div>
    </ProposalFormStyles>
  );
};

export default withRouter(ProposalForm);