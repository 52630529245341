import store from 'redux/store';
import moment from 'moment';
import CONFIG from 'config';
import { selectors as storageSelectors, actions as storageActions } from 'redux/api/storage/storage';

export const RECENT_SEARCHES = 'recent_searches';
// SEE https://bcg-ksg.atlassian.net/wiki/spaces/MIN/pages/668729654/Usage+of+the+Storage+API+in+the+Front+End
// for more detail on this API

export function getRecentSearchesfromAPI() {
  storageActions.getStorage(RECENT_SEARCHES)(store.dispatch);
}

export function allRecentSearchTerms() {
  return [...storageSelectors.getStorage(store.getState(), RECENT_SEARCHES)];
}

export function addSearchTerm(searchTerm) {
  if (searchTerm !== undefined) {
    const cleanSearchTerm = searchTerm.toLowerCase().trim();
    if (cleanSearchTerm.length > 0) {
      const searchTerms = allRecentSearchTerms();
      const termExists = searchTerms.find((t) => (t.display === cleanSearchTerm));
      if (termExists) {
        termExists.date = moment().toString();
      } else {
        searchTerms.push({
          display: cleanSearchTerm,
          date: moment().toString()
        });
      }

      const sortedTerms = searchTerms.sort((a, b) => b.date.localeCompare(a.date));
      storageActions.updateStorage(RECENT_SEARCHES, sortedTerms)(store.dispatch);
    }
  }
}

export function clearAllTerms() {
  storageActions.deleteStorage(RECENT_SEARCHES)(store.dispatch);
}

export function getLimitedRecentSearches(searches) {
  return searches.map((s) => ({ ...s, category: 'recent' })).slice(0, CONFIG.DEFAULT_RECENT_SEARCH_COUNT);
}