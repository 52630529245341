import CONFIG from 'config';
import PAGE_ANALYTICS from './page_analytics';

export default {
  async openInLAB(doc, chapter, lesson, isExpanded, flavor, displayMode) {
    let rule = '';
    if (flavor === CONFIG.LAB_FLAVORS.COURSE) {
      rule = 'lab course open in lab';
      PAGE_ANALYTICS.setLABCourseProps(doc, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.CHAPTER) {
      rule = 'lab chapter open in lab';
      PAGE_ANALYTICS.setLABChapterProps(doc, chapter, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.LESSON) {
      rule = 'lab lesson open in lab';
      PAGE_ANALYTICS.setLABLessonProps(doc, chapter, lesson, isExpanded, displayMode);
    }

    PAGE_ANALYTICS.track(rule);
  },

  async addToPlaylist(doc, chapter, lesson, isExpanded, flavor, displayMode) {
    let rule = '';
    if (flavor === CONFIG.LAB_FLAVORS.COURSE) {
      rule = 'lab course add to playlist';
      PAGE_ANALYTICS.setLABCourseProps(doc, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.CHAPTER) {
      rule = 'lab chapter add to playlist';
      PAGE_ANALYTICS.setLABChapterProps(doc, chapter, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.LESSON) {
      rule = 'lab lesson add to playlist';
      PAGE_ANALYTICS.setLABLessonProps(doc, chapter, lesson, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.TALK) {
      rule = 'lab talk add to playlist';
      PAGE_ANALYTICS.setLABTalkProps(doc, isExpanded, displayMode);
    }

    PAGE_ANALYTICS.track(rule);
  },

  async shareViaEmail(doc, chapter, lesson, isExpanded, flavor, displayMode) {
    let rule = '';
    if (flavor === CONFIG.LAB_FLAVORS.COURSE) {
      rule = 'lab course email';
      PAGE_ANALYTICS.setLABCourseProps(doc, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.CHAPTER) {
      rule = 'lab chapter email';
      PAGE_ANALYTICS.setLABChapterProps(doc, chapter, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.LESSON) {
      rule = 'lab lesson email';
      PAGE_ANALYTICS.setLABLessonProps(doc, chapter, lesson, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.TALK) {
      rule = 'lab talk email';
      PAGE_ANALYTICS.setLABTalkProps(doc, isExpanded, displayMode);
    }

    PAGE_ANALYTICS.track(rule);
  },

  async launchInLAB(doc, chapter, lesson, isExpanded, flavor, displayMode) {
    let rule = '';
    if (flavor === CONFIG.LAB_FLAVORS.LESSON) {
      rule = 'lab lesson launch';
      PAGE_ANALYTICS.setLABLessonProps(doc, chapter, lesson, isExpanded, displayMode);
    } else if (flavor === CONFIG.LAB_FLAVORS.TALK) {
      rule = 'lab talk launch';
      PAGE_ANALYTICS.setLABTalkProps(doc, isExpanded, displayMode);
    }

    PAGE_ANALYTICS.track(rule);
  },

  async viewInLAB(doc, displayMode) {
    PAGE_ANALYTICS.setLABCourseProps(doc, true, displayMode);

    PAGE_ANALYTICS.track('lab item open in lab link');
  }
};