import axios from 'axios';
import CONFIG from 'config';
import { RECENT_SEARCHES } from 'utils/recentSearches/recentSearches';

export const name = 'storage';

export const API_GET_STORAGE_PENDING = 'API_GET_STORAGE_PENDING';
export const API_GET_STORAGE_FAILURE = 'API_GET_STORAGE_FAILURE';
export const API_GET_STORAGE_SUCCESS = 'API_GET_STORAGE_SUCCESS';
export const API_PUT_STORAGE_PENDING = 'API_PUT_STORAGE_PENDING';
export const API_PUT_STORAGE_FAILURE = 'API_PUT_STORAGE_FAILURE';
export const API_PUT_STORAGE_SUCCESS = 'API_PUT_STORAGE_SUCCESS';
export const API_DELETE_STORAGE_PENDING = 'API_DELETE_STORAGE_PENDING';
export const API_DELETE_STORAGE_FAILURE = 'API_DELETE_STORAGE_FAILURE';
export const API_DELETE_STORAGE_SUCCESS = 'API_DELETE_STORAGE_SUCCESS';

const sources = [];
const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  data: {}
};

export const CHAT_ACCEPTANCE_STORAGE_KEY = 'chat_acceptance';

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getStorage: (state, storageKey) => state[name].data[storageKey] || []
};

// SEE https://bcg-ksg.atlassian.net/wiki/spaces/MIN/pages/668729654/Usage+of+the+Storage+API+in+the+Front+End
// for more detail on this API
export const actions = {
  getStorage: (storageKey) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_GET_STORAGE_PENDING });

    try {
      const response = await axios.get(
        CONFIG.API_URL.STORAGE(storageKey),
        { cancelToken: newSource.token, headers: { 'x-api-key': CONFIG.X_API_KEY } }
      );

      const storageValueDefaults = {
        [RECENT_SEARCHES]: [],
        [CHAT_ACCEPTANCE_STORAGE_KEY]: ''
      };

      const defaultValue = storageValueDefaults[storageKey];

      const data = !response.payload ? defaultValue : JSON.parse(response.payload);

      dispatch({ type: API_GET_STORAGE_SUCCESS, payload: { storageKey, data } });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_GET_STORAGE_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  updateStorage: (storageKey, payload) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_PUT_STORAGE_PENDING });
    try {
      await axios.put(
        CONFIG.API_URL.STORAGE(storageKey),
        { payload: JSON.stringify(payload) },
        { cancelToken: newSource.token, headers: { 'x-api-key': CONFIG.X_API_KEY } }
      );

      dispatch({ type: API_PUT_STORAGE_SUCCESS, payload: { storageKey, data: payload } });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_PUT_STORAGE_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  deleteStorage: (storageKey) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_DELETE_STORAGE_PENDING });
    try {
      await axios.delete(
        CONFIG.API_URL.STORAGE(storageKey),
        { cancelToken: newSource.token, headers: { 'x-api-key': CONFIG.X_API_KEY } }
      );

      dispatch({ type: API_DELETE_STORAGE_SUCCESS, payload: { storageKey } });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_DELETE_STORAGE_FAILURE, payload: error.toString() });
      throw error;
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_STORAGE_PENDING:
    case API_PUT_STORAGE_PENDING:
    case API_DELETE_STORAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_GET_STORAGE_FAILURE:
    case API_PUT_STORAGE_FAILURE:
    case API_DELETE_STORAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_GET_STORAGE_SUCCESS:
    case API_PUT_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload.storageKey]: action.payload.data
        }
      };
    case API_DELETE_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [action.payload.storageKey]: null
        }
      };
    default:
      return state;
  }
}
