import axios from 'axios';
import CONFIG from 'config';

export const API_META_DATA_SERVICE_PENDING = 'API_META_DATA_SERVICE_PENDING';
export const API_META_DATA_SERVICE_SUCCESS = 'API_META_DATA_SERVICE_SUCCESS';
export const API_META_DATA_SERVICE_FAILURE = 'API_META_DATA_SERVICE_FAILURE';

export const name = 'metadata';

const sources = [];
const initialState = {
  metadata: {},
  loading: false,
  error: false,
  errorMessage: '',
  empty: true
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getEmpty: (state) => state[name].empty,
};

export const actions = {
  getMetadata: (guid) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_META_DATA_SERVICE_PENDING });

    try {
      const metadata = await axios.get(CONFIG.CMS.METADATA(guid),
        {
          cancelToken: newSource.token,
          headers: { 'x-api-key': CONFIG.CMS.METADATA_XAPIKEY }
        }
      );

      dispatch({ type: API_META_DATA_SERVICE_SUCCESS, payload: { metadata } });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_META_DATA_SERVICE_FAILURE, payload: error.error.message.toString() });
      throw error;
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_META_DATA_SERVICE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
      };
    case API_META_DATA_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case API_META_DATA_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        metadata: {
          ...state.metadata,
          [action.payload.metadata.id]: action.payload.metadata.data
        },
        empty: action.payload.metadata.length <= 0
      };
    default:
      return state;
  }
}
