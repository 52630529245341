import CONFIG from 'config';

export function getKnowledgeTypeAheadSuggestions(typeAheadResults) {
  const typeAheadSuggestions = {
    filenames: [],
    concepts: [],
    acronyms: [],
    authors: [],
  };
  let finalSuggestions = [];
  const filenames = typeAheadResults.filter((data) => data.category === CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE);
  const concepts = typeAheadResults.filter((data) => data.category === CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.VALUE);
  const acronyms = typeAheadResults.filter((data) => data.category === CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.VALUE);
  const authors = typeAheadResults.filter((data) => data.category === CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.VALUE);
  const acronymsCount = acronyms.length;
  const conceptsCount = concepts.length;
  const authorsCount = authors.length;
  const filenamesCount = filenames.length;

  typeAheadSuggestions.filenames = filenames.length >= CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH ? filenames.splice(0, CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH) : filenames.splice(0, filenames.length);
  typeAheadSuggestions.concepts = concepts.length >= CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH ? concepts.splice(0, CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH) : concepts.splice(0, concepts.length);
  typeAheadSuggestions.acronyms = acronyms.length >= CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH ? acronyms.splice(0, CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH) : acronyms.splice(0, acronyms.length);
  typeAheadSuggestions.authors = authors.length >= CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH ? authors.splice(0, CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH) : authors.splice(0, authors.length);

  finalSuggestions = typeAheadSuggestions.filenames.concat(typeAheadSuggestions.concepts.concat(typeAheadSuggestions.acronyms).concat(typeAheadSuggestions.authors));

  const showMoreFiles = (acronymsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH || conceptsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH || authorsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH) && filenamesCount > CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH;
  const showMoreAcronyms = (conceptsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH || authorsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH || filenamesCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH) && acronymsCount > CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH;
  const showMoreConcepts = (acronymsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH || authorsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH || filenamesCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH) && conceptsCount > CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH;
  const showMoreAuthors = (acronymsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.MAX_LENGTH || conceptsCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.MAX_LENGTH || filenamesCount < CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.MAX_LENGTH) && authorsCount > CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.MAX_LENGTH;

  if (finalSuggestions.length !== CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH) {
    if (showMoreFiles) {
      typeAheadSuggestions.filenames = typeAheadSuggestions.filenames.concat(filenames.splice(0, (CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH - finalSuggestions.length)));
      finalSuggestions = typeAheadSuggestions.filenames.concat(typeAheadSuggestions.concepts.concat(typeAheadSuggestions.acronyms).concat(typeAheadSuggestions.authors));
    }
  }
  if (finalSuggestions.length !== CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH) {
    if (showMoreConcepts) {
      typeAheadSuggestions.concepts = typeAheadSuggestions.concepts.concat(concepts.splice(0, (CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH - finalSuggestions.length)));
      finalSuggestions = typeAheadSuggestions.filenames.concat(typeAheadSuggestions.concepts.concat(typeAheadSuggestions.acronyms).concat(typeAheadSuggestions.authors));
    }
  }
  if (finalSuggestions.length !== CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH) {
    if (showMoreAcronyms) {
      typeAheadSuggestions.acronyms = typeAheadSuggestions.acronyms.concat(acronyms.splice(0, (CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH - finalSuggestions.length)));
      finalSuggestions = typeAheadSuggestions.filenames.concat(typeAheadSuggestions.concepts.concat(typeAheadSuggestions.acronyms).concat(typeAheadSuggestions.authors));
    }
  }
  if (finalSuggestions.length !== CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH) {
    if (showMoreAuthors) {
      typeAheadSuggestions.authors = typeAheadSuggestions.authors.concat(authors.splice(0, (CONFIG.TYPEAHEAD.KNOWLEDGE.MAX_LENGTH - finalSuggestions.length)));
      finalSuggestions = typeAheadSuggestions.filenames.concat(typeAheadSuggestions.concepts.concat(typeAheadSuggestions.acronyms).concat(typeAheadSuggestions.authors));
    }
  }

  return finalSuggestions.map((s, i) => ({ ...s, index: i })); // reset index values since we removed some;
}