import PAGE_ANALYTICS from './page_analytics';
import { NO_VAR } from './analytics_constants';

export default {
  async linkToMaterialResults() {
    PAGE_ANALYTICS.setDefaultQueryProps();
    PAGE_ANALYTICS.track('Material_Results_Navigation');
  },

  async phonebookInteractions(contactObj, displayMode, phonebookAction, index, parentContact = null) {
    if (!window.digitalData) await PAGE_ANALYTICS.initialize();
    const contact = contactObj.event ? contactObj.event.data.contact : contactObj;
    const contactId = contact.id ? contact.id : NO_VAR;
    const empID = parentContact !== null ? parentContact.id : contactId;
    const hostOffLocation = contact.hostOfficeLocation ? contact.hostOfficeLocation : NO_VAR;
    // eslint-disable-next-line no-nested-ternary
    const assistantID = contact.isAssistant ? contact.id : ((contact.assistant && contact.assistant.id) ? contact.assistant.id : NO_VAR);
    PAGE_ANALYTICS.setDefaultQueryProps();
    const adobe = {
      displayMode,
      globalRelevance: 1, // TBD from people API
      searchResultRanking: index + 1,
      empID,
      alumniInfo: !contact.isActive ? 'true' : 'false',
      employeeOffice: (hostOffLocation && hostOffLocation.name) ? contact.hostOfficeLocation.name : NO_VAR,
      cco: {
        info: NO_VAR, // TBD from people API
        company: NO_VAR // TBD from people API
      },
      assistantID,
      assistant: { info: contact.isAssistant === true ? 'true' : 'false' },
      phonebook: { trigger: phonebookAction }
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Phonebook_card_interactions');
  }
};