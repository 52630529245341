import PAGE_ANALYTICS from './page_analytics';

export default {
  async viewKNLink(navigatorScope) {
    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = { navigatorScope };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Navigator_Redirection');
  }
};