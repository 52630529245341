import styled from 'styled-components';
import Button from 'components/Button/Button';

export const SessionsStyles = styled.div`
  color: ${({ theme }) => theme.COLORS.$white};
  line-height: 21px; 
  position: sticky;
  top: 72px;

  .mt10 {
    margin-top: 10px;
  }
  .sessionWrapper {
    position: absolute;
    width: calc(100% + 19px);
    overflow-y: auto;
    height: calc(100vh - 123px);
  }
  .sessionlist {
    h2 {
      font-family: ${({ theme }) => theme.FONTS.$sansbold};
      color:  ${({ theme }) => theme.COLORS.$graylight};
      font-size: ${({ theme }) => theme.SIZE(14)};
      font-weight: 700;
      padding: 10px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  .sessionItem {
    position: relative;

    :hover {
      .actions {
        display: inline-flex;
      }
    }
    a{
      font-family: ${({ theme }) => theme.FONTS.$sanslight};
      display: block;
      color: ${({ theme }) => theme.COLORS.$graylight};
      padding: 10px;
      font-weight: 400;
      font-size: ${({ theme }) => theme.SIZE(14)};
    }
    a:hover {
      background:  ${({ theme }) => theme.COLORS.$bggray};
      color: ${({ theme }) => theme.COLORS.$gvgray};
      font-family: ${({ theme }) => theme.FONTS.$sansreg};
    }
  }
  .actions {
    display: none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: ${({ theme }) => `${theme.COLORS.$gray}80`};
    align-items: center;
    padding: 10px;
    z-index: 1;
  }
  .ml5 {
    margin-left: 5px;
  }
  .inlineInput {
    background: transparent;
    color: #fff;
    border: 0;
    outline: none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.$graydarklight};
  }
  .actionIcon {
    width: 16px;
    height: 16px;
  }
  .hideScrollbar::-webkit-scrollbar {
    display: none;
  }
  .hideScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const StartChatButton = styled(({ ...rest }) => <Button {...rest} />)`
  &&& {
    width: 100%;
    height: 40px;
    border-color: ${({ theme }) => theme.COLORS.$graydarklight};
    color: ${({ theme }) => theme.COLORS.$graydarklight};
    background-color: ${({ theme }) => theme.COLORS.$btngray};
  }
`;