import { merge } from 'lodash';
import PAGE_ANALYTICS from './page_analytics';
import { SPELL_CHECK_REJECT,
  SPELL_CHECK_ACCEPT } from './analytics_constants';

export default {
  async spellCheckReject(term, autoCorrectionSuggestion) {
    const adobe = {
      autoCorrectionSuggestion,
      spellCheckOption: SPELL_CHECK_REJECT,
      search: { term }
    };

    window.digitalData = merge({}, window.digitalData, adobe);
    PAGE_ANALYTICS.track('spell_check_usage');
  },
  async spellCheckAccept(term, autoCorrectionSuggestion) {
    const adobe = {
      autoCorrectionSuggestion,
      spellCheckOption: SPELL_CHECK_ACCEPT,
      search: { term: autoCorrectionSuggestion }
    };

    window.digitalData = merge({}, window.digitalData, adobe);
    PAGE_ANALYTICS.track('spell_check_usage');
  }
};
