import CONFIG from 'config';
import history from 'redux/history';
import { getFromLocalStorageAsJSON, removeFromLocalStorage, sendJSONToLocalStorage } from 'utils/localStorage/localStorage';

export function enableMaintenanceMode() {
  removeFromLocalStorage(CONFIG.MAINTENANCE_FLAG);
  history.push(CONFIG.UI_URL.HOME);
  window.location.reload(false);
}

export function isInMaintenanceMode() {
  return getFromLocalStorageAsJSON(CONFIG.MAINTENANCE_FLAG);
}

export function overrideMaintenanceMode() {
  sendJSONToLocalStorage(CONFIG.MAINTENANCE_FLAG, 'off');
}