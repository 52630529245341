import { withRouter, Redirect, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { name as userReducerName, USER_STATUS } from 'redux/api/user/user';
import CONFIG from 'config';
import * as Styled from './ProposalBuilderDetails.style';
import { LABELS, STORYLINE, SESSION_LIST } from 'utils/proposalBuilder/labels';
import {CONTENT as PROPOSAL_DETAILS_CONTENT} from 'containers/ProposalBuilder/mockContent';
import { findClosestMatch, pageScroll as sectionScroll } from 'utils/proposalBuilder/utils';

// Components
import { Sessions } from './Sessions/Sessions';
import { StoryLine } from './StoryLine/StoryLine';
import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';

export const ProposalBuilderDetailsContainer = () => {
  const { UI_URL: {PROPOSALBUILDER}, EXPORT_OPTIONS, FEATURE_TOGGLES: { SHOW_PROPOSALBUILDER } } = CONFIG;
  const { GO_BACK, PROPOSAL_DETAILS_TITLE } = LABELS;
  const {hasAIAccessRetrievalStatus, hasAIAccess } = useSelector((state) => state[userReducerName]);
  const itemsScrollPos = useRef([]);
  const [nextItem, setNextItem] = useState(0);
  const contentRef = useRef(null);
  const history = useHistory();

  const handleClick = useCallback((index) => {
    history.push(`#${index}`);
    pageScroll(index);
  }, []);

  const exportOptions = useMemo(() => ([
    {
      key: EXPORT_OPTIONS.PDF.KEY,
      text: EXPORT_OPTIONS.PDF.TEXT,
      value: EXPORT_OPTIONS.PDF.VALUE
    },
    {
      key: EXPORT_OPTIONS.DOC.KEY,
      text: EXPORT_OPTIONS.DOC.TEXT,
      value: EXPORT_OPTIONS.DOC.VALUE
    }
  ]));

  //Caculate page section based on window scroll
  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    
    const nextItem = findClosestMatch(scrollTop, itemsScrollPos.current, 200);

    setNextItem(nextItem);
    history.push(`#${nextItem}`);
  }, []);

  //attach scroll event 
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    //Setting up page title
    document.title = PROPOSAL_DETAILS_TITLE;

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const pageScroll = useCallback((id = '') => {
    if(!contentRef.current) {
      return;
    }
  
    itemsScrollPos.current = sectionScroll(id, 'scrollable');
  }, []);

  //Scroll page to the section as per the url #
  useEffect(() => pageScroll(), [contentRef.current]);

  const getContent = () => PROPOSAL_DETAILS_CONTENT;
    
  return (
    <Layout id="proposalbuilderDetails" pageName="Proposal Details" mainId="proposalbuilderDetails">
      {hasAIAccessRetrievalStatus === USER_STATUS.PENDING && 
            <Loader active />
      }
      {hasAIAccessRetrievalStatus === USER_STATUS.DONE && 
            <>
              { (!SHOW_PROPOSALBUILDER || !hasAIAccess) && <Redirect to={CONFIG.UI_URL.ERROR_WITH_SOURCE('404')} /> }
              <Styled.ProposalBuilderDetails>
                <div className="leftbar">
                  <Sessions listItems={SESSION_LIST}/>
                </div>
                <div className="main">
                  <div className="wrapper">
                    <div className="leftsec">
                      <Styled.ContentWrapper>
                        <div className='col1'>
                          <Button
                            className="backButton"
                            onClick={() => history.push(PROPOSALBUILDER)}
                          >{GO_BACK}</Button>
                        </div>
                        <div className='col2'>
                          <h1>Proposal Details</h1>
                          <div className='content' ref={contentRef} dangerouslySetInnerHTML={{__html: getContent()}}>
                          </div>
                        </div>
                      </Styled.ContentWrapper>
                    </div>
                    <div className="rightsec">
                      <div className='sticky'>
                        <StoryLine onClick={handleClick} selected={nextItem} items={STORYLINE}/>
                        <div className="export">
                          <Styled.MenuWrapper compact>
                            <Dropdown text='Export' options={exportOptions}/>
                          </Styled.MenuWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Styled.ProposalBuilderDetails>
            </>
      }
    </Layout>
  );
};

export default withRouter(ProposalBuilderDetailsContainer);