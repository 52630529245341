import styled from 'styled-components';
// import Button from 'components/Button/Button';

export const ProposalFormStyles = styled.div`
  padding:20px 0 0 0;
  text-align: center;
  position: relative;
  width: 100%;
  height: calc(100% - 30px);
  text-align: left;
  .inner-chatbot{
    position: sticky;
    height: calc(100vh - 217px);
    top: 200px;
    overflow-y: hidden;
    width: 100%;
    text-align: left;
  }
`;