import { createContext } from 'react';

export const ResponsiveContext = createContext({
  isMobile: undefined,
  isXS: undefined,
  isSM: undefined,
  isMD: undefined,
  isLG: undefined,
  isXL: undefined,
  isMobileSafari: undefined,
  isIos: undefined,
  isSafari: undefined
});