import axios from 'axios';

export const API_HTML_PENDING = 'API_HTML_PENDING';
export const API_HTML_SUCCESS = 'API_HTML_SUCCESS';
export const API_HTML_FAILURE = 'API_HTML_FAILURE';

export const name = 'html';

const sources = [];
const promiseBackingStore = {};
const initialState = {
  htmls: {},
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getHtmlData: (state) => state[name].htmls };

export const actions = {
  fetchHtmls: (url) => async (dispatch) => {
    if (promiseBackingStore[url]) return;
    promiseBackingStore[url] = true;

    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_HTML_PENDING });

    try {
      const response = await axios.get(url, { cancelToken: newSource.token });
      dispatch({ type: API_HTML_SUCCESS, payload: { url, response } });
    } catch (error) {
      delete promiseBackingStore[url];
      dispatch({ type: API_HTML_FAILURE, payload: error.message });
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_HTML_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_HTML_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_HTML_SUCCESS:
      return {
        ...state,
        loading: false,
        htmls: {
          ...state.htmls,
          [action.payload.url]: action.payload.response
        }
      };
    default:
      return state;
  }
}
