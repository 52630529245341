import PropTypes from 'prop-types';
import React from 'react';
import * as Analytics from './QuerySuggestions.analytics';
import { SuggestionButton, Header, StyledCarousel, StyledCard } from './QuerySuggestions.styles';

// hijack onclick and do

export const QuerySuggestions = ({ items, onClick, pageAnalytics }) => {
  const onSuggestionClick = (qs, index) => {
    // analytics
    Analytics.SuggestionInteraction(qs, index + 1, pageAnalytics);
    // fire onclick
    onClick(qs);
  };

  const onSuggestionScroll = (direction) => {
    Analytics.CarouselInteraction(direction, pageAnalytics);
  };

  return (
    items.length > 0 ?
      <StyledCard>
        <Header>Try also</Header>
        <StyledCarousel onScroll={onSuggestionScroll}>
          {items.map((qs, index) =>
            <SuggestionButton data-testid="qs-button" key={`sb-${qs}`} onClick={() => onSuggestionClick(qs, index)}>
              {qs}
            </SuggestionButton>)}
        </StyledCarousel>
      </StyledCard>
      : null);
};

QuerySuggestions.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  pageAnalytics: PropTypes.object.isRequired
};

export const isValidQuery = (query) => {
  if (!query || !query.trim()) return false;

  const lowerQuery = query.trim().toLowerCase();
  const queryWordCount = lowerQuery.split(/\s+/).length;
  const isValid = queryWordCount <= 3 &&
    !lowerQuery.includes(' or ') &&
    !lowerQuery.includes(' and ') &&
    !lowerQuery.includes(' near ') &&
    !lowerQuery.includes(' not ');

  return isValid;
};