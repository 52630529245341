import axios from 'axios';
import CONFIG from 'config';
import { getAccessTokenFromCookie } from 'utils/auth/auth';

export const getSlides = async (doc, searchTerm, aiDocs, imgRes) => {
  if (doc && !doc.slides) {
    const data = await axios.post(
      CONFIG.API_URL.SLIDE_LEVEL_SEARCH,
      {query: searchTerm, guid: [doc.kpCmsId], resultsPerPage: 100000, matchMode: 'exact'}
    );

    const baseUrl = data.attachments[0].url;
    const s3Path = data.attachments[0].s3Object?.replace('.pptx', '');
    const mergedSlideData = data.attachments[0].previews.map((p) => {
      let relSlide;
      if (aiDocs.length > 0) {
        const page = aiDocs.find((doc) => p.pageNumber === doc.page);
        if (page) {
          relSlide = { relevance: Math.floor(page.globalRelevance * 100), slideNumber: page.page };
        }
      }
      else {
        relSlide = data.attachments[0].relevantSlides.find((rs) => p.pageNumber === rs.slideNumber);
      }
      const slide = {
        htmlUrl: [baseUrl, s3Path, p.html.hqUrl].join('/'),
        imageRef: [baseUrl, s3Path, p[imgRes]?.hqUrl].join('/'),
        slideNumber: p.pageNumber,
      };
      if (relSlide) {
        return {
          ...relSlide,
          ...slide
        };
      }

      return slide;
    });

    return {
      ...doc,
      previewAvailable: doc.hasHtmlPreview,
      entitled: doc.openAccessInd,
      slides: mergedSlideData,
      relevantSlides: mergedSlideData.filter((p) => p.relevance),
      hasSlides: true,
      kpId: doc.kpCmsId,
      searchAbstract: doc.searchAbstract || doc.materialDesc,
      previews: data.attachments[0].previews,
      s3Object: data.attachments[0].s3Object
    };
  }
  else {
    return doc;
  }
};

export const getApiEnvironment = () => {
  const envArr = CONFIG.SERVER_URL.split('.');
  return envArr.length <= 3 ?
    envArr[0] :
    envArr.slice(0, 2).join('.');
};

export const getApiConfig = () => ({
  key: CONFIG.X_API_KEY,
  token: getAccessTokenFromCookie,
  env: getApiEnvironment()
});

export function getSlideNumbers(selectedSlides) {
  const slideNumbers = [];
  selectedSlides.forEach((slide) => slideNumbers.push(Number(slide.slideNumber)));
  return slideNumbers;
}

export const genaiSlideSearch = async (query, document) => {
  let kpCmsId = document.kpCmsId;
  const request_body = { query: query, guid: [kpCmsId], resultsPerPage: 100000, matchMode: 'exact' };
  const retrievalData = await axios.post(CONFIG.API_URL.SLIDE_LEVEL_SEARCH, request_body);
  const augmentedData = {
    'resultCount': retrievalData.resultCount,
    'attachments': retrievalData.attachments[0]
  };
  return augmentedData;
};