import CONFIG from 'config';
import axios from 'axios';
import { getFormattedProfileString } from 'utils/search/search';

export const name = 'search';

export const API_SEARCH_CONFIG_PENDING = 'API_SEARCH_CONFIG_PENDING';
export const API_SEARCH_CONFIG_FAILURE = 'API_SEARCH_CONFIG_FAILURE';
export const API_SEARCH_CONFIG_SUCCESS = 'API_SEARCH_CONFIG_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const SET_SEARCH_SCOPE = 'SET_SEARCH_SCOPE';
export const SET_NEW_SEARCH_TRIGGERED = 'SET_NEW_SEARCH_TRIGGERED';

const sources = [];
const initialState = {
  scope: '',
  profiles: '',
  newSearchTriggered: false,
  searchConfigError: false,
  searchConfigErrorMessage: ''
};

export const selectors = {
  getSearchScope: (state) => state[name].scope,
  getSearchProfiles: (state) => state[name].profiles,
  getNewSearchTriggered: (state) => state[name].newSearchTriggered
};

export const actions = {
  searchUserConfigProfile: () => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_SEARCH_CONFIG_PENDING });

    try {
      const data = await axios.get(
        CONFIG.API_URL.SEARCH_CONFIG,
        { cancelToken: newSource.token }
      );

      dispatch({
        type: API_SEARCH_CONFIG_SUCCESS,
        payload: getFormattedProfileString(data[0].profiles)
      });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_SEARCH_CONFIG_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  setSearchScope: (scope) => (dispatch) => {
    dispatch({
      type: SET_SEARCH_SCOPE,
      payload: scope
    });
  },
  setNewSearchTriggered: (newSearchTriggered) => (dispatch) => {
    dispatch({
      type: SET_NEW_SEARCH_TRIGGERED,
      payload: newSearchTriggered
    });
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_SCOPE:
      return {
        ...state,
        scope: action.payload
      };
    case API_SEARCH_CONFIG_SUCCESS:
      return {
        ...state,
        profiles: action.payload
      };
    case API_SEARCH_CONFIG_PENDING:
      return {
        ...state,
        searchConfigError: false,
        searchConfigErrorMessage: ''
      };
    case API_SEARCH_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        searchConfigError: true,
        searchConfigErrorMessage: action.payload
      };
    case SET_NEW_SEARCH_TRIGGERED:
      return {
        ...state,
        newSearchTriggered: action.payload
      };
    default:
      return state;
  }
}