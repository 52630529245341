import axios from 'axios';
import CONFIG from 'config';

export const name = 'topicSuggestions';
export const API_TOPICSUGGESTIONS_PENDING = 'API_TOPICSUGGESTIONS_PENDING';
export const API_TOPICSUGGESTIONS_FAILURE = 'API_TOPICSUGGESTIONS_FAILURE';
export const API_TOPICSUGGESTIONS_EMPTY = 'API_TOPICSUGGESTIONS_EMPTY';
export const API_TOPICSUGGESTIONS_SUCCESS = 'API_TOPICSUGGESTIONS_SUCCESS';
export const API_TOPICSUGGESTIONS_CANCELLED = 'API_TOPICSUGGESTIONS_CANCELLED';
export const API_GET_TOPICEXPERTSMULTIPLE_PENDING = 'API_GET_TOPICEXPERTSMULTIPLE_PENDING';
export const API_GET_TOPICEXPERTSMULTIPLE_SUCCESS = 'API_GET_TOPICEXPERTSMULTIPLE_SUCCESS';
export const API_GET_TOPICEXPERTSMULTIPLE_FAILURE = 'API_GET_TOPICEXPERTSMULTIPLE_FAILURE';
export const SHOW_TOPICS_OVERLAY = 'SHOW_TOPICS_OVERLAY';
export const CLOSE_TOPICS_OVERLAY = 'CLOSE_TOPICS_OVERLAY';
export const API_OMNIA_PRODUCTS_SUCCESS = 'API_OMNIA_PRODUCTS_SUCCESS';
export const API_OMNIA_PRODUCTS_PENDING = 'API_OMNIA_PRODUCTS_PENDING';
export const API_OMNIA_PRODUCTS_FAILURE = 'API_OMNIA_PRODUCTS_FAILURE';

const sources = [];
const initialState = {
  results: [],
  products: [],
  loading: false,
  error: false,
  errorMessage: '',
  empty: true,
  expertsLoading: false,
  experts: [],
  expertsMultipleLoading: false,
  expertsMultiple: [],
  showOverlay: false
};

export const actions = {

  getOmniaProducts: (query) => async (dispatch) => {
    dispatch({ type: API_OMNIA_PRODUCTS_PENDING, query });

    try {
      const products = await fetch(CONFIG.API_URL.OMNIA_PRODUCTS)
        .then((response) => response.json())
        .then((data) => data.sort((a, b) => (a.name > b.name ? 1 : -1)).map((d) => ({ ...d, searchKeywords: d.keywords })));

      dispatch({ type: API_OMNIA_PRODUCTS_SUCCESS, payload: products });
    } catch (error) {
      dispatch({ type: API_OMNIA_PRODUCTS_FAILURE, payload: error.message });
    }
  },

  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_TOPICSUGGESTIONS_CANCELLED };
  },

  getTopicExpertsMultiple: (legacyIds) => async (dispatch) => {
    const topicIds = legacyIds.filter((t) => t); // umm....
    const newSource = axios.CancelToken.source();
    sources.push(newSource);
    dispatch({ type: API_GET_TOPICEXPERTSMULTIPLE_PENDING, topicIds });
    try {
      const topicExpertsMultipleData = await axios.get(
        CONFIG.API_URL.TOPIC_EXPERTS_MULTIPLE(topicIds),
        { cancelToken: newSource.token }
      );
      if (topicExpertsMultipleData === '') {
        dispatch({ type: API_GET_TOPICEXPERTSMULTIPLE_SUCCESS, payload: { items: [] } });
      } else {
        dispatch({ type: API_GET_TOPICEXPERTSMULTIPLE_SUCCESS, payload: topicExpertsMultipleData });
      }
    } catch (error) {
      dispatch({ type: API_GET_TOPICEXPERTSMULTIPLE_FAILURE, payload: error.message });
    }
  },

  showTopicsOverlay: () => (dispatch) => {
    document.body.style.overflow = 'hidden';
    dispatch({ type: SHOW_TOPICS_OVERLAY });
  },

  closeTopicsOverlay: () => (dispatch) => {
    document.body.style.overflow = 'inherit';
    dispatch({ type: CLOSE_TOPICS_OVERLAY });
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_TOPICSUGGESTIONS_PENDING:
    case API_OMNIA_PRODUCTS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: true
      };
    case API_TOPICSUGGESTIONS_FAILURE:
    case API_OMNIA_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        empty: true
      };
    case API_TOPICSUGGESTIONS_SUCCESS:
      if (action.payload.length === 0) {
        return {
          ...state,
          loading: false,
          error: false,
          empty: true,
          results: [],
          experts: []
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        empty: false,
        results: action.payload
      };
    case API_OMNIA_PRODUCTS_SUCCESS:
      if (action.payload.length === 0) {
        return {
          ...state,
          loading: false,
          error: false,
          empty: true,
          products: [],
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        empty: false,
        products: action.payload.slice(0)
      };
    case API_GET_TOPICEXPERTSMULTIPLE_PENDING:
      return {
        ...state,
        expertsMultipleLoading: true,
        error: false,
        errorMessage: ''
      };
    case API_GET_TOPICEXPERTSMULTIPLE_FAILURE:
      return {
        ...state,
        expertsMultipleLoading: false,
        expertsMultiple: [],
        error: true,
        errorMessage: action.payload
      };
    case API_GET_TOPICEXPERTSMULTIPLE_SUCCESS:
      return {
        ...state,
        expertsMultipleLoading: false,
        error: false,
        expertsMultiple: action.payload.items
      };
    case SHOW_TOPICS_OVERLAY:
      return {
        ...state,
        showOverlay: true
      };
    case CLOSE_TOPICS_OVERLAY:
      return {
        ...state,
        showOverlay: false
      };
    default:
      return state;
  }
}