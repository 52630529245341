import PAGE_ANALYTICS from './page_analytics';

export default {
  async activateSearchHint(searchHintTitle, searchHintLocation) {
    const adobe = {
      search: {
        searchHintTitle,
        searchHintLocation
      }
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('search_hint_activation');
  },
};