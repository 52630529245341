import { queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';
import PAGE_ANALYTICS from './page_analytics';
import ANALYTICS from './analytics';
import {
  NO_VAR,
  EMPTY_QUERY,
  IS_BEST_BET,
  IS_NOT_BEST_BET
} from './analytics_constants';

export default {
  explicitFeedback(doc, isExpanded, flavor, selectedOptionReason, resultRelevanceRating, relatedSuggestion) {
    if (relatedSuggestion) {
      const adobe = { suggTrigger: 'suggestion_rating', suggRating: resultRelevanceRating };
      ANALYTICS.page.trackEvent('suggested_material', { ...window.digitalData, ...adobe });
    } else {
      const adobe = {
        KCpage: { id: doc.kpCmsId || doc.kbCmsId || NO_VAR },
        collection: { ID: doc.kbCmsId || NO_VAR },
        selectedOptionReason,
        resultRelevanceRating,
        search: {
          bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET,
        },
        chatID: 'not available',
        threadID: 'not available',
      };
      PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded, flavor);
      window.digitalData = Object.assign(window.digitalData, adobe);
      PAGE_ANALYTICS.track('result_explicit_feedback');
      delete window.digitalData.search.bestbetflag;
    }
  },

  async typeAheadFeedback(IrrelevantSuggestions, Suggestions) {
    const query = queryWithSearchParamsOnly();
    const adobe = {
      Suggestions,
      IrrelevantSuggestions,
      search: {
        // eslint-disable-next-line no-nested-ternary
        term: query.query !== undefined ? query.emptyQuery ? EMPTY_QUERY : query.query : NO_VAR
      }
    };

    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('typehead_explicit_feedback');
  },

  async relatedConceptsFeedback(Suggestions, IrrelevantSuggestions, providedSuggestions) {
    PAGE_ANALYTICS.setDefaultQueryProps();
    const adobe = {
      Suggestions,
      IrrelevantSuggestions,
      providedSuggestions: providedSuggestions === '' ? NO_VAR : providedSuggestions
    };

    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('concept_feedback');
  }
};