import PAGE_ANALYTICS from './page_analytics';
import { NO_VAR } from './analytics_constants';

export default {
  async clickTopicSuggestion(topicSuggestion, relevancyIndex, displayMode, otherTopic) {
    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = {
      displayMode,
      suggestedTopicTitle: topicSuggestion.title || topicSuggestion.topicName,
      suggestedTopicPath: topicSuggestion.suggestedTopicPath || topicSuggestion.topicUrl,
      suggestedTopicID: topicSuggestion.newId || NO_VAR,
      suggestedTopicGroup: topicSuggestion.suggestionGroup,
      // eslint-disable-next-line no-nested-ternary
      searchResultRanking: relevancyIndex !== undefined ? relevancyIndex || relevancyIndex === 0 ? relevancyIndex + 1 : NO_VAR : NO_VAR,
      otherTopic: otherTopic ? 'true' : 'false'
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('suggested_topic_click');
  },

  topicProductSuggestionLoad(topicSuggestions, analyticEvent) {
    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = {
      displayMode: 'list',
      suggestedTopicTitle: topicSuggestions.map((ts) => ts.title).join('|'),
      suggestedTopicID: topicSuggestions.map((ts) => ts.newId).join('|'),
      suggestedTopicGroup: topicSuggestions.map((ts) => ts.suggestionGroup).join('|')
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track(analyticEvent);
  },

  topicInteractions(topicSuggestion, relevancyIndex, displayMode, authorStaffID, toggleStatus, topicAction) {
    PAGE_ANALYTICS.setDefaultQueryProps();
    const adobe = {
      displayMode,
      suggestedTopicTitle: topicSuggestion ? topicSuggestion.title : NO_VAR,
      suggestedTopicPath: topicSuggestion ? topicSuggestion.topicUrl : NO_VAR,
      suggestedTopicID: topicSuggestion ? topicSuggestion.newId : NO_VAR,
      suggestedTopicGroup: topicSuggestion ? topicSuggestion.suggestionGroup : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      suggestedToggleStatus: toggleStatus !== null ? toggleStatus ? 'true' : 'false' : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      searchResultRanking: relevancyIndex !== undefined ? relevancyIndex || relevancyIndex === 0 ? relevancyIndex + 1 : NO_VAR : NO_VAR,
      authorStaffID: authorStaffID || NO_VAR,
      topicAction
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('topic_interactions');
  }
};