import CONFIG from 'config';
import axios from 'axios';
import { actions as notifyActions } from 'redux/components/notification/notification';
import { getSlideNumbers } from 'utils/docviz/docviz';

export const name = 'docVizSlideSelection';
export const DOWNLOAD_CLIPPED_SLIDES_STARTED = 'DOWNLOAD_CLIPPED_SLIDES_STARTED';
export const DOWNLOAD_CLIPPED_SLIDES_COMPLETE = 'DOWNLOAD_CLIPPED_SLIDES_COMPLETE';
export const DOWNLOAD_CLIPPED_SLIDES_INPROGRESS = 'DOWNLOAD_CLIPPED_SLIDES_INPROGRESS';
export const DOWNLOAD_CLIPPED_SLIDES_FAILED = 'DOWNLOAD_CLIPPED_SLIDES_FAILED';
export const FETCH_CLIPPED_ID_FAILED = 'FETCH_CLIPPED_ID_FAILED';
export const CLIPPED_FILE_FAILURE = 'CLIPPED_FILE_FAILURE';
export const CLIPPED_FILE_STATUS_ERROR = 'CLIPPED_FILE_STATUS_ERROR';
export const GENERATING_CLIPPED_SLIDES = 'GENERATING_CLIPPED_SLIDES';
export const SHOW_PROGRESS_BARS = 'SHOW_PROGRESS_BARS';
export const CLOSE_PROGRESS_BARS = 'CLOSE_PROGRESS_BARS';
export const REMOVE_CLIPPING_ID = 'REMOVE_CLIPPING_ID';
export const DOWNLOAD_DOCUMENT_STARTED = 'DOWNLOAD_DOCUMENT_STARTED';
export const DOWNLOAD_DOCUMENT_COMPLETE = 'DOWNLOAD_DOCUMENT_COMPLETE';
export const DOWNLOAD_DOCUMENT_INPROGRESS = 'DOWNLOAD_DOCUMENT_INPROGRESS';
export const DOWNLOAD_DOCUMENT_FAILED = 'DOWNLOAD_DOCUMENT_FAILED';
export const FETCH_PRESIGNEDURL_FAILED = 'FETCH_PRESIGNEDURL_FAILED';

const initialState = {
  selectedSlides: [],
  clippingData: {},
  downloadStatus: '',
  showProgress: false
};

export const selectors = {
  getSelectedSlides: (state) => state[name].selectedSlides,
  getDownloadState: (state) => state[name].downloadStatus,
  getClippedData: (state) => state[name].clippingData,
  getProgressBars: (state) => state[name].showProgress,
  getConfirmDialogStatus: (state) => state[name].open
};


async function downloadClippedFile(clippingResponse, fileName) {
  const clippedResponse = await axios({
    url: clippingResponse.clippedFile,
    method: 'GET',
    responseType: 'blob',
  });
  const clipBlob = new Blob([clippedResponse], { type: 'mime' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(clipBlob, `${fileName}`);
  } else {
    const clipDownloadResponse = window.URL.createObjectURL(clipBlob);
    const tempLink = document.createElement('a');
    tempLink.href = clipDownloadResponse;
    tempLink.setAttribute('download', `${fileName}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
  }
}

function getAttachmentDetails(payload, state) {
  if (payload) {
    const { fileName, selectedSlides, disablePopups } = payload;
    const kpCmsId = payload.kpCmsId || payload.kpId;
    return { fileName, selectedSlides, disablePopups, kpCmsId };
  }
  const { docVizOverlay: { doc: { fileName, kpCmsId } }, docVizSlideSelection: { selectedSlides, allSlidesSelected } } = state;
  return { fileName, kpCmsId, selectedSlides, allSlidesSelected };
}

function removeClippingId(clippedId) {
  return async (dispatch, getState) => {
    const { docVizSlideSelection: { clippingData } } = getState();
    delete clippingData[clippedId];
    const clippedDataLength = Object.keys(clippingData).length;
    let show;
    if (clippedDataLength > 0) {
      show = true;
    } else {
      show = false;
    }
    dispatch({ type: REMOVE_CLIPPING_ID, payload: clippingData, showProgress: true });
    if (!show) {
      setTimeout(() => {
        // Even though the code executed and waited for the download, before sending 'dispatch removing clipping Id' later, the browser download indicator seemed to render itself after the closing our progress bar panel.
        // Therefore, send 'dispatch removing clipping Id' in next cycle, so the rendering of the browser download indicator can appear first.
        dispatch({ type: CLOSE_PROGRESS_BARS, showProgress: false });
      }, 500);
    }
  };
}

function pollClippingData(payload) {
  return async (dispatch, getState) => {
    const { fileName } = getAttachmentDetails(payload.payload, getState());
    const clippingIntervalFunc = async () => {
      const clipInterval = setInterval(async () => {
        const clippingResponse = await axios.get(CONFIG.API_URL.NGEN_FETCH_CLIPPED_FILE(payload.clippedAttachments.clippingId), { headers: { 'x-api-key': CONFIG.CLIPPING_API_X_API_KEY } });

        if (clippingResponse.status === 'INPROGRESS') {
          dispatch({ type: DOWNLOAD_CLIPPED_SLIDES_INPROGRESS, downloadStatus: 'inprogress', payload: { key: payload.clippedAttachments.clippingId, value: { percentage: clippingResponse.percentageCompleted, status: 'inprogress', fileName } } });
        }
        if (clippingResponse.status === 'COMPLETED') {
          clearInterval(clipInterval);
          try {
            dispatch({ type: DOWNLOAD_CLIPPED_SLIDES_COMPLETE, downloadStatus: 'completed', payload: { key: payload.clippedAttachments.clippingId, value: { percentage: clippingResponse.percentageCompleted, status: 'completed', fileName } } });
            await downloadClippedFile(clippingResponse, fileName);
            dispatch(removeClippingId(payload.clippedAttachments.clippingId));
          } catch (error) {
            dispatch({ type: DOWNLOAD_CLIPPED_SLIDES_FAILED, downloadStatus: error.response.status, payload: { key: payload.clippedAttachments.clippingId, value: { percentage: '', status: error.response.status.toString(), fileName } } });
            dispatch(notifyActions.notifyError(
              CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
              CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
              error
            )
            );
            throw error;
          }
        } else if (clippingResponse.status === 'ERROR') {
          clearInterval(clipInterval);
          dispatch({ type: CLIPPED_FILE_STATUS_ERROR, downloadStatus: clippingResponse.status, payload: { [payload.clippedAttachments.clippingId]: { percentage: '', status: 'error', fileName } } });
          dispatch(notifyActions.notifyError(
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT
          ));
        }
      }, 5000);
    };
    clippingIntervalFunc();
  };
}

function fetchClippingIds(payload) {
  return async (dispatch, getState) => {
    const { kpCmsId, selectedSlides, disablePopups, fileName } = getAttachmentDetails(payload, getState());
    const attachments = {
      documentId: kpCmsId,
      pages: getSlideNumbers(selectedSlides)
    };

    try {
      const clippedAttachments = await axios.post(CONFIG.API_URL.NGEN_FETCH_CLIPPING_ID, attachments, { headers: { 'x-api-key': CONFIG.CLIPPING_API_X_API_KEY } });

      if (clippedAttachments.clippingId) {
        dispatch({ type: GENERATING_CLIPPED_SLIDES, downloadStatus: 'generating', payload: { key: clippedAttachments.clippingId, value: { percentage: 0, status: 'generating', fileName } } });
        dispatch(pollClippingData({
          payload,
          clippedAttachments,
          kpCmsId
        }));
      } else {
        dispatch({ type: FETCH_CLIPPED_ID_FAILED, downloadStatus: '403' });
        if (!disablePopups) {
          dispatch(notifyActions.notifyError(
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
          ));
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_CLIPPED_ID_FAILED, downloadStatus: '403' });
      if (!disablePopups) {
        dispatch(notifyActions.notifyError(
          CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
          CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
          error
        ));
      }
      throw error;
    }
  };
}


function getDocumentDetails(payload, state) {
  if (payload) {
    const { fileName, disablePopups } = payload;
    const kpCmsId = payload.kpCmsId || payload.kpId;
    return { fileName, kpCmsId, disablePopups };
  }
  const { docVizOverlay: { doc: { kpCmsId } }, docVizSlideSelection: { allSlidesSelected } } = state;
  return { kpCmsId, allSlidesSelected };
}

function downloadDocs(payload) {
  return async (dispatch, getState) => {
    const { kpCmsId, disablePopups, fileName } = getDocumentDetails(payload, getState());
    try {
      const attachment = await axios.get(CONFIG.API_URL.DOWNLOAD_DOCUMENT(kpCmsId), { headers: { 'x-api-key': CONFIG.CLIPPING_API_X_API_KEY } });
      if (attachment?.documentData?.preSignedUrl) {
        const downloadLink = document.createElement('a');
        downloadLink.href = attachment?.documentData?.preSignedUrl;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        dispatch({ type: DOWNLOAD_DOCUMENT_COMPLETE, downloadStatus: 'completed', payload: { key: attachment?.id, value: { status: 'completed', fileName } } });
      } else {
        dispatch({ type: FETCH_PRESIGNEDURL_FAILED, downloadStatus: '403' });
        if (!disablePopups) {
          dispatch(notifyActions.notifyError(
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
            CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
          ));
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_PRESIGNEDURL_FAILED, downloadStatus: '403' });
      if (!disablePopups) {
        dispatch(notifyActions.notifyError(
          CONFIG.DISPLAY_TEXT.DOC_DOWNLOAD_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
          CONFIG.DISPLAY_TEXT.DOC_DOWNLOAD_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
          error
        ));
      }
      throw error;
    }
  };
}

export const actions = {
  openProgressBars: () => (dispatch) => {
    dispatch({ type: SHOW_PROGRESS_BARS, showProgress: true });
  },
  closeProgressBars: () => (dispatch) => {
    dispatch({ type: CLOSE_PROGRESS_BARS, showProgress: false });
  },
  downloadSelectedSlides: (payload) => async (dispatch) => {
    try {
      dispatch({ type: DOWNLOAD_CLIPPED_SLIDES_STARTED, downloadStatus: 'started' });
      dispatch(fetchClippingIds(payload));
    } catch (error) {
      dispatch({ type: CLIPPED_FILE_FAILURE, downloadStatus: error.response.status });
      dispatch(notifyActions.notifyError(
        CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
        CONFIG.DISPLAY_TEXT.CLIP_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
        error
      )
      );
      throw error;
    }
  },
  downloadDocument: (payload) => async (dispatch) => {
    try {
      dispatch(downloadDocs(payload));
    } catch (error) {
      dispatch({ type: FETCH_PRESIGNEDURL_FAILED, downloadStatus: error.response.status });
      dispatch(notifyActions.notifyError(
        CONFIG.DISPLAY_TEXT.DOC_DOWNLOAD_ERROR_MESSAGE.DOWNLOAD_FAILED_TITLE,
        CONFIG.DISPLAY_TEXT.DOC_DOWNLOAD_ERROR_MESSAGE.DOWNLOAD_FAILED_TEXT,
        error
      )
      );
      throw error;

    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_CLIPPED_SLIDES_FAILED:
    case FETCH_CLIPPED_ID_FAILED:
    case DOWNLOAD_CLIPPED_SLIDES_STARTED:
    case CLIPPED_FILE_FAILURE:
      return {
        ...state,
        downloadStatus: action.downloadStatus
      };
    case GENERATING_CLIPPED_SLIDES:
      return {
        ...state,
        downloadStatus: action.downloadStatus,
        clippingData: { ...state.clippingData, [action.payload.key]: action.payload.value },
        showProgress: true
      };
    case DOWNLOAD_CLIPPED_SLIDES_COMPLETE:
    case DOWNLOAD_CLIPPED_SLIDES_INPROGRESS:
    case CLIPPED_FILE_STATUS_ERROR:
      return {
        ...state,
        downloadStatus: action.downloadStatus,
        clippingData: { ...state.clippingData, [action.payload.key]: action.payload.value }
      };
    case FETCH_PRESIGNEDURL_FAILED:
      return {
        ...state,
        downloadStatus: action.downloadStatus
      };
    case DOWNLOAD_DOCUMENT_COMPLETE:
      return {
        ...state,
        downloadStatus: action.downloadStatus,
        documentData: { ...state.documentData, [action.payload.key]: action.payload.value }
      };
    case SHOW_PROGRESS_BARS:
    case CLOSE_PROGRESS_BARS:
      return {
        ...state,
        showProgress: action.showProgress
      };
    case REMOVE_CLIPPING_ID:
      return {
        ...state,
        clippingData: { ...action.payload },
        showProgress: action.showProgress
      };
    default:
      return state;
  }
}
