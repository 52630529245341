import axios from 'axios';
import CONFIG from 'config';

export const API_DOWNLOAD_PENDING = 'API_DOWNLOAD_PENDING';
export const API_DOWNLOAD_SUCCESS = 'API_DOWNLOAD_SUCCESS';
export const API_DOWNLOAD_FAILURE = ' API_DOWNLOAD_FAILURE';

export const name = 'bioResumeDownload';

const initialState = {
  profileDownload: [],
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getDownload: (state) => state[name].profileDownload};
export const actions = {
  fetchDownload: (proposalBioResumeId,type) => async (dispatch) => {
    dispatch({ type: API_DOWNLOAD_PENDING, payload: { loading: true } });
    try {
      const response = await axios.get(
        CONFIG.API_URL.DOWNLOAD_BIO_RESUME(proposalBioResumeId,type),
        
        {
          headers: {
            'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY,
            
          },
          
        });

      const tempLink = document.createElement('a');
      tempLink.href = response?.documentData?.preSignedUrl;
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.remove();
      dispatch({ type: API_DOWNLOAD_SUCCESS, payload: response });
    } catch (error) {
      
      dispatch({ type: API_DOWNLOAD_FAILURE, payload: error.message  });
    }
    
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_DOWNLOAD_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_DOWNLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_DOWNLOAD_SUCCESS:
      return {
        ...state,   
        loading: false,
        profileDownload: {...action.payload}
        
      };
    default:
      return state;
  }
}