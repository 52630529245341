import ANALYTICS from 'utils/analytics/analytics';

export const SuggestionInteraction = (suggestedTerm, suggestedTermPos, pageAnalytics) => {
  const adobe = {
    search: {
      suggestedTerm,
      suggestedTermPos,
    }
  };
  ANALYTICS.page.trackEvent('Query_Selection_Click', { ...pageAnalytics, ...adobe, search: { ...pageAnalytics?.search, ...adobe.search } });
};

export const CarouselInteraction = (direction, pageAnalytics) => {
  // kept direction parameter for possible future use
  ANALYTICS.page.trackEvent('Query_Selection_Scroll', pageAnalytics);
};