import { useCallback, useMemo } from 'react';
import { useHistory, withRouter } from 'react-router';
import CONFIG from 'config';
import * as Styled from './ProposalBuilderNaviSwitch.style';

export const ProposalBuilderNaviSwitch = ({align = 'left', marginLeft = 0, defaultValue = ''}) => {
  const history = useHistory();
  const { UI_URL:{KNOWLEDGE, PROPOSALBUILDER}, PROPOSAL_DROPDOWN_OPTION } = CONFIG;

  const options = useMemo(() => ([
    {
      key: PROPOSAL_DROPDOWN_OPTION.PROPOSALBUILDER.KEY,
      value: PROPOSAL_DROPDOWN_OPTION.PROPOSALBUILDER.VALUE,
      text:PROPOSAL_DROPDOWN_OPTION.PROPOSALBUILDER.TEXT
    },
    {
      key: PROPOSAL_DROPDOWN_OPTION.NAVI.KEY,
      value: PROPOSAL_DROPDOWN_OPTION.NAVI.VALUE,
      text:PROPOSAL_DROPDOWN_OPTION.NAVI.TEXT
    }
  ]));

  const handleDropdownChange = useCallback((e, data) => {
    history.push(data.value === 'navi' ? KNOWLEDGE + '?chatbot=true' : PROPOSALBUILDER);
  });

  return (
    <Styled.ProposalBuilderNaviSwitch
      fluid
      selection
      options={options}
      defaultValue={defaultValue}
      onChange={handleDropdownChange}
      $align={align}
      $marginLeft={marginLeft}
    />
  );
};

export default withRouter(ProposalBuilderNaviSwitch);