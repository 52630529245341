import { useEffect, useState } from 'react';
import * as Styled from './StoryLine.style';
import { LABELS } from 'utils/proposalBuilder/labels';

export const StoryLine = ({items = [], onClick, selected = 0}) => {
  const { STORYLINE_HEADING } = LABELS;
  const [activeItem, setActiveItem] = useState(selected);

  const handleClick = (e, i) => {
    e.preventDefault();

    if(onClick) {
      onClick(i);
    }

    setActiveItem(i);
  };

  useEffect(() => setActiveItem(selected), [selected]);

  return (
    <Styled.StoryLineWrapper>
      <h3>{STORYLINE_HEADING}</h3>
      <ul>
        {items.map((item, i) => (
          <li className={i === activeItem ? 'active' : ''} key={i}>
            <a data-testid='item' href={`#${i}`} onClick={(e) => handleClick(e, i)}><span className='circle'></span><span className='label'>{item.label}</span></a>
          </li>
        ))}
      </ul>
    </Styled.StoryLineWrapper>
  );
};