/* eslint-disable react/no-unused-state */
// eslint rules dont account for passing in state values into context as per react docs so disabling that rule
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import { ResponsiveContext } from 'context/ResponsiveContext/ResponsiveContext';
import { isXS, isSM, isMD, isLG, isXL, isMobileSafari, isIos, isSafari } from 'utils/mobile/mobile';

class ResponsiveWrapper extends Component {
  updateBreakpoints = debounce(() =>
    this.setState({
      isMobile: isXS() || isSM() || isMD(),
      isXS: isXS(),
      isSM: isSM(),
      isMD: isMD(),
      isLG: isLG(),
      isXL: isXL()
    }), 500);

  constructor(props) {
    super(props);
    this.state = {
      isMobile: isXS() || isSM() || isMD(),
      isXS: isXS(),
      isSM: isSM(),
      isMD: isMD(),
      isLG: isLG(),
      isXL: isXL(),
      isMobileSafari: isMobileSafari(),
      isIos: isIos(),
      isSafari: isSafari()
    };
  }

  componentDidMount = async () => {
    window.addEventListener('resize', this.updateBreakpoints);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateBreakpoints);
  };

  render = () => (
    <ResponsiveContext.Provider value={this.state}>
      {this.props.children}
    </ResponsiveContext.Provider>
  );
}

ResponsiveWrapper.propTypes = { children: PropTypes.shape().isRequired };

export default ResponsiveWrapper;
