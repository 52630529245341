import styled, { css } from 'styled-components';
import Dropdown from  'components/Dropdown/Dropdown';

export const ProposalBuilderNaviSwitch = styled(Dropdown)`{
    &&&& {
        width: 200px;
        ${({$align}) => $align === 'left' && css`
            align-self: flex-start;
        `}
        ${({$marginLeft}) => $marginLeft && css`
            margin-left: ${$marginLeft}px;
        `}
    }
}`;