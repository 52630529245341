import axios from 'axios';
import CONFIG from 'config';


export const API_LANGUAGE_PENDING = 'API_LANGUAGE_PENDING';
export const API_LANGUAGE_SUCCESS = 'API_LANGUAGE_SUCCESS';
export const API_LANGUAGE_FAILURE = ' API_LANGUAGE_FAILURE';

export const name = 'languages';

const initialState = {
  language: [],
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getLanguage: (state) => state[name]};

export const actions = {
  fetchLanguage: () => async (dispatch) => {
   
    dispatch({ type: API_LANGUAGE_PENDING, payload: { loading: true } });
    try {
      const response = await axios.get(
        CONFIG.API_URL.LANGUAGES,
        {
          headers: {
            'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY,
            
          },         
        });
     
      dispatch({ type: API_LANGUAGE_SUCCESS, payload: response });
    } catch (error) {
      
      dispatch({ type: API_LANGUAGE_FAILURE, payload: error.message  });
    }
    
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_LANGUAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_LANGUAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'error'
      };
    case API_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        language: action.payload
        
      };
    default:
      return state;
  }
}