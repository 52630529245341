import axios from 'axios';
import CONFIG from 'config';

export const name = 'material';

export const API_MATERIAL_PENDING = 'API_MATERIAL_PENDING';
export const API_MATERIAL_FAILURE = 'API_MATERIAL_FALILURE';
export const API_MATERIAL_EMPTY = 'API_MATERIAL_EMPTY';
export const API_MATERIAL_SUCCESS = 'API_MATERIAL_SUCCESS';
export const API_MATERIAL_CANCELLED = 'API_MATERIAL_CANCELLED';

const sources = [];
const initialState = {
  results: {},
  loading: false,
  empty: true,
  error: false,
  errorMessage: ''
};

export const selectors = {
  getMaterial: (state) => state[name].results,
  getLoading: (state) => state[name].loading,
  getEmpty: (state) => state[name].empty,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage
};

export const actions = {
  getMaterialDetails: (docid) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_MATERIAL_PENDING });

    try {
      const data = await axios.get(
        CONFIG.API_URL.MATERIAL(`KP_CMS_ID:${docid}`),
        { cancelToken: newSource.token });
      if (data?.doc?.length > 0) {
        dispatch({ type: API_MATERIAL_SUCCESS, payload: { ...data.doc[0], contentType: 'knowledge' } });
      } else {
        dispatch({ type: API_MATERIAL_EMPTY, payload: data });
      }
    } catch (error) {
      dispatch({ type: API_MATERIAL_FAILURE, payload: error.message });
    }
  },
  getLegacyMaterialDetails: (materialId) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_MATERIAL_PENDING });

    try {
      const data = await axios.post(
        CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
        { query: materialId },
        { cancelToken: newSource.token });
      if (data?.doc?.length > 0 && data.doc[0].materialId === Number(materialId)) {
        dispatch({ type: API_MATERIAL_SUCCESS, payload: { ...data.doc[0], contentType: 'legacy_mat' } });
      } else {
        dispatch({ type: API_MATERIAL_EMPTY, payload: data });
      }
    } catch (error) {
      dispatch({ type: API_MATERIAL_FAILURE, payload: error.message });
    }
  },
  getLegacyAttachmentDetails: (attachmentId) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_MATERIAL_PENDING });

    try {
      const data = await axios.post(
        CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
        { query: attachmentId },
        { cancelToken: newSource.token });
      if (data?.doc?.length > 0 && data.doc[0].attachmentId === Number(attachmentId)) {
        dispatch({ type: API_MATERIAL_SUCCESS, payload: { ...data.doc[0], contentType: 'legacy_att' } });
      } else {
        dispatch({ type: API_MATERIAL_EMPTY, payload: data });
      }
    } catch (error) {
      dispatch({ type: API_MATERIAL_FAILURE, payload: error.message });
    }
  },
  getInternalDetails: (docid) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    dispatch({ type: API_MATERIAL_PENDING });

    try {
      const data = await axios.post(
        CONFIG.API_URL.BCG_INTERNAL_SEARCH(''),
        { query: `KP_CMS_ID:${docid}` },
        { cancelToken: newSource.token });
      if (data?.doc?.length > 0) {
        dispatch({ type: API_MATERIAL_SUCCESS, payload: { ...data.doc[0], contentType: 'internal' } });
      } else {
        dispatch({ type: API_MATERIAL_EMPTY, payload: data });
      }
    } catch (error) {
      dispatch({ type: API_MATERIAL_FAILURE, payload: error.message });
    }
  },
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_MATERIAL_CANCELLED };
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_MATERIAL_PENDING:
      return {
        ...state,
        loading: true,
        empty: true,
        error: false,
        errorMessage: ''
      };
    case API_MATERIAL_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        empty: true,
        errorMessage: action.payload[0]
      };
    case API_MATERIAL_EMPTY:
      return {
        ...state,
        loading: false,
        empty: true,
        results: action.payload
      };
    case API_MATERIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        empty: false,
        results: action.payload
      };
    default:
      return state;
  }
}