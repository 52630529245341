import CONFIG from 'config';
import PAGE_ANALYTICS from './page_analytics';
import { NO_VAR } from './analytics_constants';

export default {

  async contactAdmin(doc) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR }
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('contact_administrator');
  },

  async downloadOtherFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('download_attachment_otherDocument');
  },

  async emailAuthor(doc, authorStaffID) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    const adobe = { authorStaffID };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_email_interaction');
  },

  async emailFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    const adobe = {
      caseID: doc?.projectId || NO_VAR,
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('email_interactions');
  },

  async emailOtherFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('email_attachment_otherDocument');
  },

  async pinOtherFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('push_pin_attachment_otherDocument', {
      integrations: {
        Adobe: true,
        DataDog: true
      }
    });
  },

  async pinVisitUrlOther(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('visit_url_interactions_other_attachments');
  },

  async previewOtherFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('preview_attachment_otherDocument', {
      integrations: {
        Adobe: true,
        DataDog: true
      }
    });
  },

  async slackAuthor(doc, authorStaffID) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    const adobe = { authorStaffID };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_slack_interaction');
  },

  async unPinOtherFile(doc) {
    PAGE_ANALYTICS.setDefaultMaterialAttachmentFileProps(doc);
    PAGE_ANALYTICS.track('Push_UnPin_Attachment_OtherDocuments', {
      integrations: {
        Adobe: true,
        DataDog: true
      }
    });
  },

  async viewAuthor(doc, authorStaffID) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    const adobe = { authorStaffID };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_profile_result_expanded_view');
  },

  async viewMaterialPage(doc, isExpanded, displayMode, parentDoc, chatId) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded, displayMode, undefined, undefined, parentDoc);
    let SERPview;
    if (displayMode === CONFIG.DISPLAY_MODE.GRID || displayMode === CONFIG.SEARCH_FLAVORS.GRID_VIEW) {
      SERPview = CONFIG.DISPLAY_TEXT.DISPLAY_CONTROL_TOOLTIPS.GRID;
    }
    else if (displayMode === CONFIG.DISPLAY_MODE.AI || displayMode === CONFIG.SEARCH_FLAVORS.AI_VIEW) {
      SERPview = CONFIG.DISPLAY_TEXT.DISPLAY_CONTROL_TOOLTIPS.AI_VIEW;
    }
    else {
      SERPview = CONFIG.DISPLAY_TEXT.DISPLAY_CONTROL_TOOLTIPS.LIST;
    }
    if (chatId) {
      SERPview = 'Navi';
    }
    const adobe = {
      chatID: chatId,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? 'yes' : 'no' },
      SERPview,
      displayMode
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_material_page');
    delete window.digitalData.search.bestbetflag;
  },

  async viewOriginalMaterialPage(doc) {
    PAGE_ANALYTICS.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);
    PAGE_ANALYTICS.track('View_Original_Material_Page');
  },
  async visitAssociatedUrl(doc, isExpanded, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded, displayMode);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR }
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('visit_url_interactions');
  }
};