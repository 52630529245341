import CONFIG from 'config';

export const getDateRange = (filter) => {
  const endDate = new Date();
  let startDate;
  
  switch (filter) {
    case 'Past 30 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 30);
      break;
    case 'Past 60 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 60);
      break;
    case 'Past 90 Days':
      startDate = new Date();
      startDate.setDate(endDate.getDate() - 90);
      break;
    case 'Past 6 Months':
      startDate = new Date();
      startDate.setMonth(endDate.getMonth() - 6);
      break;
    case 'Past Year':
      startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    case 'Past Two Years':
      startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 2);
      break;
    case 'Past Five Years':
      startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 5);
      break;
    default:
      startDate = null;
  }
  
  return {
    startDate: startDate ? startDate.toISOString() : null,
    endDate: startDate ? endDate.toISOString() : null,
  };
};

export const getDomainNames = (domainUrls = []) => {
  return domainUrls.map(domainUrl => {
    const domain = CONFIG.WEB_SEARCH_DOMAIN_FILTERS.find(filter => filter.domain_url === domainUrl);
    return domain ? domain.domain_name : null;
  });
};