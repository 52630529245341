import CONFIG from 'config';

export const name = 'helpfulLinks';
export const HELPFUL_LINKS_PENDING = 'HELPFUL_LINKS_PENDING';
export const HELPFUL_LINKS_FAILURE = 'HELPFUL_LINKS_FAILURE';
export const HELPFUL_LINKS_FETCH_AND_FILTER_SUCCESS = 'HELPFUL_LINKS_FETCH_AND_FILTER_SUCCESS';

const initialState = {
  helpfulLinks: [],
  loading: false,
  error: false,
  errorMessage: '',
};

export const actions = {
  getHelpfulLinks: (query) => async (dispatch) => {
    dispatch({ type: HELPFUL_LINKS_PENDING, query });
    try {
      const allHelpfulLinks = await fetch(`${CONFIG.API_URL.HELPFUL_LINKS}`)
        .then((response) => response.json())
        .then((data) => data);
      dispatch({ type: HELPFUL_LINKS_FETCH_AND_FILTER_SUCCESS, payload: allHelpfulLinks.sort((a, b) => (a.title > b.title ? 1 : -1)) });
    } catch (error) {
      dispatch({ type: HELPFUL_LINKS_FAILURE, payload: error.message });
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case HELPFUL_LINKS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case HELPFUL_LINKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case HELPFUL_LINKS_FETCH_AND_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        helpfulLinks: action.payload
      };
    default:
      return state;
  }
}
