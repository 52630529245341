import CONFIG from 'config';

export const name = 'officepages';
export const OFFICEPAGES_PENDING = 'OFFICEPAGES_PENDING';
export const OFFICEPAGES_FAILURE = 'OFFICEPAGES_FAILURE';
export const OFFICEPAGES_FETCH_AND_FILTER_SUCCESS = 'OFFICEPAGES_FETCH_AND_FILTER_SUCCESS';

const initialState = {
  officepages: [],
  loading: false,
  error: false,
  errorMessage: '',
};

export const actions = {
  getOfficePages: (query) => async (dispatch) => {
    dispatch({ type: OFFICEPAGES_PENDING, query });
    try {
      const allOfficepages = await fetch(`${CONFIG.API_URL.OFFICEPAGES}`)
        .then((response) => response.json())
        .then((data) => data);
      dispatch({ type: OFFICEPAGES_FETCH_AND_FILTER_SUCCESS, payload: allOfficepages.map((m) => ({ ...m, searchKeywords: m.keywords })).sort((a, b) => (a.title > b.title ? 1 : -1)) });
    } catch (error) {
      dispatch({ type: OFFICEPAGES_FAILURE, payload: error.message });
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case OFFICEPAGES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case OFFICEPAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case OFFICEPAGES_FETCH_AND_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        officepages: action.payload
      };
    default:
      return state;
  }
}
