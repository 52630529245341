import PAGE_ANALYTICS from './page_analytics';

export default {
  async pageLoad(pageName) {
    PAGE_ANALYTICS.initialize(pageName, 'Page', true);
  },

  async refreshButton() {
    PAGE_ANALYTICS.setNoVarForSearchType();
    PAGE_ANALYTICS.track('Error_Page_Refresh');
  }
};