import { useEffect, memo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { actions as notifyActions, selectors as notifySelectors } from 'redux/components/notification/notification';

import Notification from 'components/Notification/Notification';
import DownloadProgressBarWrapper from 'components/DownloadProgressBarWrapper/DownloadProgressBarWrapper';
import UsabillaSetup from 'components/UsabillaSetup/UsabillaSetup';
import { useBrandContext } from 'context/BrandContext/BrandContext';
import DatadogRum from 'utils/datadog/datadog';
import AppInit from 'components/AppInit/AppInit';
import Button from 'components/Button/Button';
import { BCGFooter } from '@kdshared/global-nav';

import { enableMaintenanceMode, isInMaintenanceMode } from 'utils/maintenance/maintenance';
import ANALYTICS from 'utils/analytics/analytics';
import CONFIG from 'config';

import './Layout.scss';
import './Layout-XFactory.scss';
import { DownloadProgress } from 'components/DownloadProgress/DownloadProgress';

export const Layout = (props) => {
  const {
    id,
    notifyMode,
    notifyTitle,
    notifyMessage,
    notifyAutoClose,
    notifyTimestamp,
    children,
    footer,
    downloadProgressBar,
    justLayout,
    pageName,
    style,
    mainId
  } = props;
  const brand = useBrandContext();
  const [footerLinks, setFooterLinks] = useState([]);

  useEffect(() => {
    if (pageName && justLayout) {
      ANALYTICS.page.initialize(pageName, 'Page', true);
    }
  }, []);

  useEffect(() => {
    if (brand?.FEATURES) {
      setFooterLinks([
        { display: `Version ${CONFIG.SMART_SEARCH_VERSION}${brand.FEATURES?.SHOW_POWERED_BY ? `| Powered by ${CONFIG.APP_NAME}` : ''}` },
        {
          display: `${CONFIG.APP_NAME} Tutorial`,
          url: 'https://enrich.bcg.com/trainings/',
          isExternal: true
        }
      ]);
    }
  }, [brand]);

  return (
    <>
      <div className={`layout ${brand.CSS} ${id === 'chat' ? 'mobile-layout' : ''}`} id={id} style={style}>
        <main role="main" id={mainId} tabIndex="-1" className={!footer ? 'nofooter' : ''}>
          {children}
        </main>
        {!justLayout &&
          <>
            <AppInit pageName={pageName} />
            <DatadogRum />
            {
              footer &&
              <BCGFooter environment={CONFIG.ENVIRONMENT === 'local' ? 'qa' : CONFIG.ENVIRONMENT} links={footerLinks} apiConfig={CONFIG.SHARED_CONFIG}/>
            }
            {
              downloadProgressBar &&
              <DownloadProgressBarWrapper />
            }
            <DownloadProgress />
            <Notification
              mode={notifyMode}
              title={notifyTitle}
              message={notifyMessage}
              autoClose={notifyAutoClose}
              timestamp={notifyTimestamp}
            />
            <UsabillaSetup />
          </>}
        {
          isInMaintenanceMode() &&
          <Button className="invert maintenance" onClick={enableMaintenanceMode}>Back To Maintenance Mode</Button>
        }
      </div>
    </>
  );
};

Layout.propTypes = {
  id: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
  downloadProgressBar: PropTypes.bool,
  justLayout: PropTypes.bool,
  pageName: PropTypes.string,
  style: PropTypes.shape(),
  mainId: PropTypes.string
};

Layout.defaultProps = {
  footer: false,
  downloadProgressBar: false,
  justLayout: false,
  style: null,
  pageName: '',
  mainId: 'main'
};

const mapStateToProps = (state) => ({
  notifyMode: notifySelectors.getMode(state),
  notifyTitle: notifySelectors.getTitle(state),
  notifyMessage: notifySelectors.getMessage(state),
  notifyAutoClose: notifySelectors.getAutoClose(state),
  notifyTimestamp: notifySelectors.getTimestamp(state)
});

const mapDispatchToProps = (dispatch) => ({ notifyActions: bindActionCreators({ ...notifyActions }, dispatch) });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(memo(Layout)));