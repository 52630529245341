import axios from 'axios';
import CONFIG from 'config';
import FILTERS from 'config-filters';
import { filterSorter, setAppliedFilters } from 'utils/filters/filters';
import { filterKeywordItemsBy } from 'utils/search/search';
import { flattenQuery, queryWithSearchParamsOnly, sortByNames } from 'utils/string-mapper/string-mapper';
export const name = 'paTopics';
export const API_PA_TOPICS_SEARCH_SUCCESS = 'API_PA_TOPICS_SEARCH_SUCCESS';
export const API_PA_TOPICS_SEARCH_PENDING = 'API_PA_TOPICS_SEARCH_PENDING';
export const API_PA_TOPICS_SEARCH_FAILURE = 'API_PA_TOPICS_SEARCH_FAILURE';
export const SET_PA_TOPICS_APPLIED_FILTERS_PENDING = 'SET_PA_TOPICS_APPLIED_FILTERS_PENDING';
export const SET_PA_TOPICS_APPLIED_FILTERS_SUCCESS = 'SET_PA_TOPICS_APPLIED_FILTERS_SUCCESS';
export const SET_PA_TOPICS_APPLIED_FILTERS_FAILED = 'SET_PA_TOPICS_APPLIED_FILTERS_FAILED';
export const SET_PA_TOPICS_ACTIVE_FILTER_INDEX = 'SET_PA_TOPICS_ACTIVE_FILTER_INDEX';
export const API_PA_TOPICS_LOAD_MORE_SUCCESS = 'API_PA_TOPICS_LOAD_MORE_SUCCESS';
export const API_PA_TOPICS_LOAD_MORE_PENDING = 'API_PA_TOPICS_LOAD_MORE_PENDING';
export const API_PA_TOPICS_LOAD_MORE_FAILURE = 'API_PA_TOPICS_LOAD_MORE_FAILURE';
export const CHANGE_SORTER = 'CHANGE_SORTER';

const sources = [];
export const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  empty: true,
  results: [],
  filters: [],
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  totalCount: 0,
  pageCount: 0,
  currentPage: 0,
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilterAndQueryParams: {},
  query: queryWithSearchParamsOnly(),
  activeFilter: ''
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query
};

export const actions = {
  search: (query) => async (dispatch) => {
    try {
      if(!query?.query){
        const payload = {
          filters: [],
          query: {},
          pageCount: 0,
          results: [],
          empty: true,
          pageNumber: 0,
          totalCount: 0
        };
  
        dispatch({
          type: API_PA_TOPICS_SEARCH_SUCCESS,
          payload
        });
        return;
      }
      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      const flattenedQuery = flattenQuery(query);
      dispatch({ type: API_PA_TOPICS_SEARCH_PENDING, payload: { query: flattenedQuery } });
      const response = await fetch(CONFIG.API_URL.PRACTICES);
      const json = await response.json();
      
      const filteredTopics = flattenedQuery?.query ?
        filterKeywordItemsBy(json, flattenedQuery.query) : 
        json;
      
      const sortedTopics = filteredTopics.sort((a, b) => (a.topicUrl.match(/\//ig)?.length > b.topicUrl.match(/\//ig)?.length ? 1 : -1));

      const payload = {
        filters: [],
        query: flattenedQuery,
        pageCount: 1,
        results: sortedTopics,
        empty: !sortedTopics.length,
        pageNumber: 0,
        totalCount: sortedTopics.length 
      };

      dispatch({
        type: API_PA_TOPICS_SEARCH_SUCCESS,
        payload
      });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_PA_TOPICS_SEARCH_FAILURE, payload: error.message });
      throw error;
    }
  },
  setAppliedFilters: (filters = []) => setAppliedFilters(filters, CONFIG.PA_TOPICS_FILTERS.FILTER_BAR, SET_PA_TOPICS_APPLIED_FILTERS_PENDING, SET_PA_TOPICS_APPLIED_FILTERS_SUCCESS, SET_PA_TOPICS_APPLIED_FILTERS_FAILED),
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_PA_TOPICS_ACTIVE_FILTER_INDEX, payload: filterName }),
  loadMoreFromSearch: (query, currentPage) => async (dispatch) => {
    try {
      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      const flattenedQuery = flattenQuery(query);
      
      dispatch({
        type: API_PA_TOPICS_LOAD_MORE_PENDING,
        payload: {
          query: flattenQuery
        }
      });

      const response = { ...initialState };

      const payload = {
        ...response,
        query: flattenedQuery,
        pageCount: Math.ceil(response.totalCount / (response?.pageSize || 20)),
        results: response.results,
        empty: response.count === 0,
        pageNumber: currentPage + 1
      };

      dispatch({ type: API_PA_TOPICS_LOAD_MORE_SUCCESS, payload });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_PA_TOPICS_LOAD_MORE_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_SORTER, payload })
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_PA_TOPICS_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_PA_TOPICS_SEARCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
        currentPage: action.payload?.pageNumber,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_PA_TOPICS_SEARCH_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload
      };
    case SET_PA_TOPICS_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_PA_TOPICS_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_PA_TOPICS_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_PA_TOPICS_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case API_PA_TOPICS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        filters: state.filters,
        loading: false,
        error: false,
        currentPage: action.payload?.pageNumber,
        results: state.results.concat(action.payload.results),
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_PA_TOPICS_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case API_PA_TOPICS_LOAD_MORE_PENDING:
      return {
        ...state,
        error: false
      };
    case CHANGE_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    default:
      return state;
  }
};