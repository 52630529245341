import styled, { keyframes } from 'styled-components';


const ani1 = keyframes`
  0% {
    width: 8px;
    height: 8px;
  }
  20% {
    width: 16px;
    height: 16px;
  }
  40% {
    width: 8px;
    height: 8px;
  }
`;
const ani2 = keyframes`
  0% {
    width: 8px;
    height: 8px;
  }
  20% {
    width: 8px;
    height: 8px;
  }
  40% {
    width: 16px;
    height: 16px;
  }
  60% {
    width: 16px;
    height: 16px;
  }
  80% {
    width: 8px;
    height: 8px;
  }
`;
const ani3 = keyframes`
  0% {
    width: 8px;
    height: 8px;
  }
  60% {
    width: 8px;
    height: 8px;
  }
  80% {
    width: 16px;
    height: 16px;
  }
  100% {
    width: 8px;
    height: 8px;
  }
`;

export const ChatLoader = styled.div`
  height: 16px;
  display: flex;
  align-items: center;

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border: 1px solid ${({ theme }) => theme.NEW.grays.gray500};
    border-radius: 9px;
    margin-right: 6px;
    filter: drop-shadow(2px 2px 2px rgba(68, 252, 186, 0.5));
  }
  .dot.one {
    animation: ${ani1} 1.5s linear 0s infinite;
  }
  .dot.two {
    animation: ${ani2} 1.5s linear 0s infinite;
  }
  .dot.three {
    animation: ${ani3} 1.5s linear 0s infinite;
  }
`;