export const LABELS = {
  SUBMIT_PLAN: 'Execute Plan',
  PROPOSALBUILDER_TITLE : 'Proposal Builder',
  PROPOSAL_DETAILS_TITLE : 'Proposal Builder Details',
  STORYLINE_HEADING : 'PROPOSAL OUTLINE',
  BETA: 'BETA',
  CLICK_HERE: 'Click here',
  GO_BACK: 'Go Back',
  ACTION_PLAN_HEADING: 'Please indicate which sources would you like to use for this section',
  ACTION_PLAN_SUB_HEADING: 'Configure Action plan',
  DISABLE_DRAG_DROP_MESSAGE:'To enable this drag drop feature please collapse the open element',
  DRAG_DROP_MESSAGE: 'Use this as drag handle to change the order sequence',
  DRAG_HANDLER: 'Drag handler',
  DELETE_ICON: 'Delete',
  WENT_WRONG: 'Sorry, something went wrong.',
  API_FAILED:'API call failed.',
  TRY_LATER: 'We experienced a minor issue, please try again later',
  POSITIVE: 'Tell us more about what you enjoyed.',
  NEGATIVE: 'What was the issue with the response? How could it be improved?',
  KN_CHAT_IMG: 'KN Chat Bot Image',
  PROFILE_IMG: 'User Profile Image',
  EXCLAMATION_TRIANGLE: 'exclamation triangle',
  HOURGLASS_HALF: 'hourglass half',
  COPY_MESSAGE: 'Copy Message',
  THUMBS_UP: 'Thumbs Up',
  CONTINUE_CHAT: 'Continue the conversation',
  START_CHAT: 'Start the conversation',
  SEND_MESSAGE: 'Send Message',
  DELETE_TITLE: 'You are about to delete this section. Do you want to proceed?',
  CANCEL_LABEL: 'Cancel',
  DELETE_LABEL: 'Delete',
  START_NEW_CHAT: 'Start a new chat. Your current chat will be lost.',
  START_NEW_CHAT_LABEL: 'Start New Chat',
  START_NEW_CHAT_TEXT: 'New Chat',
  EDIT_ICON: 'Edit',
  CROSS_ICON: 'Cancel',
  CHECK_ICON: 'Approve'
};

export const ACTION_PLAN = {
  LISTING: [
    {label: 'EV Market overview',
      id:'evmarketoverview',
      options: [{
        'label': 'Search for EV Market overview in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for EV Market overview in research center',
        isChecked: false
      },
      {
        'label': 'Search for EV Market overview in Internet sources',
        isChecked: false
      }]},
    {
      label: 'Trends Analysis',
      id:'trendanalysis',
      options: [{
        'label': 'Search for Trends Analysis in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for Trends Analysis in research center',
        isChecked: false
      },
      {
        'label': 'Search for Trends Analysis in Internet sources',
        isChecked: false
      }]},
    {
      label: 'Competitor Landscape',
      id:'competitorlandscape',
      options: [{
        'label': 'Search for Competitor Landscape in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for Competitor Landscape in research center',
        isChecked: false
      },
      {
        'label': 'Search for Competitor Landscape in Internet sources',
        isChecked: false
      }]},
    {
      label: 'Cost of Ownership Analysis',
      id: 'costofownership',
      options: [{
        'label': 'Search for Cost of Ownership Analysis in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for Cost of Ownership Analysis in research center',
        isChecked: false
      },
      {
        'label': 'Search for Cost of Ownership Analysis in Internet sources',
        isChecked: false
      }]},
    {
      label: 'Consumer Insights',
      id: 'consumerInsights',
      options: [{
        'label': 'Search for Consumer Insights in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for Consumer Insights in research center',
        isChecked: false
      },
      {
        'label': 'Search for Consumer Insights in Internet sources',
        isChecked: false
      }]},
    {
      label: 'Supply Chain Analysis',
      id:'supplychainanalysis',
      options: [{
        'label': 'Search for Supply Chain Analysis in KN Materials',
        isChecked: false
      },
      {
        'label': 'Search for Supply Chain Analysis in research center',
        isChecked: false
      },
      {
        'label': 'Search for Supply Chain Analysis in Internet sources',
        isChecked: false
      }]},
  ]
};

export const STORYLINE = [{
  label: 'Hypothesis and Perspective'
},{
  label: 'Customer situation and needs'
},{
  label: 'Why BCG'
},{
  label: 'Credentials'
},{
  label: 'Approach and deliverables'
}];

export const SESSION_LIST = [
  {
    title: 'today',
    items: [
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
    ]
  },
  {
    title: 'previous 30 days',
    items: [
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
      {
        title: 'Previous search query'
      },
    ]
  }
];
