import xFactoryLogo from 'assets/images/xfactory/xfactory.svg';
import Image from 'components/Image/Image';
import CONFIG from 'config';
import knLogo from 'assets/images/KN_Logo.png';

const BRANDS = [
  {
    NAME: () => CONFIG.APP_NAME,
    HOME_PAGE: { SEARCH_TITLE: <Image src={knLogo} alt="Knowledge Navigator" /> },
    URL: process.env.REACT_APP_FRONTEND_URL,
    DEFAULT_SEARCH_PARAMS: { // apply filters when user first enters the tab
      LAB: [
        {
          PARAM: 'dateRange',
          VALUE: 'Past%20Five%20Years'
        }
      ],
      KNOWLEDGE: [
        {
          PARAM: 'dateRevised',
          VALUE: 'Past%20Five%20Years'
        },
      ],
      BCG_INTERNAL: [
        {
          PARAM: 'dateRevised',
          VALUE: 'Past%20Five%20Years'
        },
        {
          PARAM: 'bcgInternalRegionOffice',
          VALUE: ''
        }
      ],
      PEOPLE: [
      ],
      CASE: [
      ],
      EXPERTS: [
      ],
      PA_TOPICS: []
    },
    FEATURES: {
      SEARCH_SUGGESTIONS: true,
      REQUEST_ACTIONS: false,
      BROWSE: true,
      SHOW_POWERED_BY: false,
      PEOPLE_SEARCH: process.env.REACT_APP_FEATURE_PEOPLE_SEARCH.trim() === 'true'
    },
    LOGO: () => CONFIG.APP_NAME,
    CSS: '',
    TOOLNAME_IN_ADOBE: 'minerva',
    PAGE_TITLES: {
      HOME: () => `${CONFIG.APP_NAME} - Home`,
      CASE: () => `${CONFIG.APP_NAME} - Cases`,
      PEOPLE: () => `${CONFIG.APP_NAME} - People`,
      MATERIAL: () => `${CONFIG.APP_NAME} - Materials`,
      KNOWLEDGE: () => `${CONFIG.APP_NAME} - Knowledge`,
      EXPERTS: () => `${CONFIG.APP_NAME} - Experts`,
      LAB: () => `${CONFIG.APP_NAME} - Lab`,
      BCG_INTERNAL: () => `${CONFIG.APP_NAME} - BCG Internal`,
      PA_TOPICS: () => `${CONFIG.APP_NAME} - PA Topics`
    },
    CSS_OVERRIDES: { NAV: { alignItems: 'center' } }
  },
  {
    NAME: () => 'X-Factory',
    HOME_PAGE: { SEARCH_TITLE: 'X-Factory' },
    URL: process.env.REACT_APP_XFACTORY,
    DEFAULT_SEARCH_PARAMS: { // apply filters when user first enters the tab
      LAB: [
        {
          PARAM: 'dateRange',
          VALUE: 'Past%20Five%20Years'
        }
      ],
      KNOWLEDGE: [
        {
          PARAM: 'dateRevised',
          VALUE: 'Past%20Five%20Years'
        }
      ],
      BCG_INTERNAL: [],
      PEOPLE: [],
      CASE: [],
      EXPERTS: [],
      PA_TOPICS: []
    },
    FEATURES: {
      SEARCH_SUGGESTIONS: false,
      REQUEST_ACTIONS: true,
      BROWSE: false,
      SHOW_POWERED_BY: true,
      PEOPLE_SEARCH: false
    },
    LOGO: () => <Image src={xFactoryLogo} alt="X-Factory" />,
    CSS: 'xfactory',
    TOOLNAME_IN_ADOBE: 'xfactory',
    PAGE_TITLES: {
      HOME: () => 'XFactory - Home',
      RESULTS: () => 'XFactory - Results',
      CASE: () => 'XFactory - Cases',
      PEOPLE: () => 'XFactory - People',
      MATERIAL: () => 'XFactory - Material',
      KNOWLEDGE: () => 'XFactory - Knowledge',
      LAB: () => 'XFactory - Lab'
    },
    CSS_OVERRIDES: { NAV: { marginTop: '8px' } }
  }
];

export default BRANDS;
