import axios from 'axios';
import CONFIG from 'config';


export const API_PROPOSAL_BIO_PENDING = 'API_PROPOSAL_BIO_PENDING';
export const API_PROPOSAL_BIO_SUCCESS = 'API_PROPOSAL_BIO_SUCCESS';
export const API_PROPOSAL_BIO_FAILURE = ' API_PROPOSAL_BIO_FAILURE';

export const name = 'proposalBios';


const initialState = {
  proposalBio: [],
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getProposalBio: (state) => state[name].proposalBio};

export const actions = {
  fetchProposalBio: (hrId) => async (dispatch) => {
    dispatch({ type: API_PROPOSAL_BIO_PENDING, payload: { loading: true } });
    try {
      const response = await axios.get(
        CONFIG.API_URL.PROPOSAL_BIO(hrId),
        {
          headers: {
            'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY,            
          },          
        });
      dispatch({ type: API_PROPOSAL_BIO_SUCCESS, payload:{hrId, response }});
    } catch (error) {      
      dispatch({ type: API_PROPOSAL_BIO_FAILURE, payload:{hrId, error: error.message}   });
    }   
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_PROPOSAL_BIO_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_PROPOSAL_BIO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'error',       
      };
    case API_PROPOSAL_BIO_SUCCESS:
      return {
        ...state,
        loading: false,
        proposalBio: {
          ...state.proposalBio,
          [action.payload?.hrId]:action.payload.response
        }        
      };
    default:
      return state;
  }
}