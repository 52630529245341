import PAGE_ANALYTICS from './page_analytics';
import { NO_VAR, CARD_EXPANDED, PA_RECOMMENDED, PA_NOT_RECOMMENDED } from './analytics_constants';

export default {
  viewCasePage(project, isExpanded = false) {
    PAGE_ANALYTICS.setDefaultFileProps(project, isExpanded);
    const adobe = {
      caseID: project.projectId,
      materialPAStatus: NO_VAR
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_material_page');
  },

  expandCard(project) {
    PAGE_ANALYTICS.setDefaultFileProps(project, true);
    const adobe = {
      caseID: project.projectId,
      materialPAStatus: NO_VAR
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('expand_result_card');
  },

  collapseCard(project) {
    PAGE_ANALYTICS.setDefaultFileProps(project, false);
    const adobe = {
      caseID: project.projectId,
      materialPAStatus: NO_VAR
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('collapse_result_card');
  },

  viewCaseVignettes(project, isExpanded=true) {
    PAGE_ANALYTICS.setDefaultFileProps(project, isExpanded);
    const adobe = {
      caseID: project.projectId,
      materialPAStatus: NO_VAR
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_other_attachment');
  },

  async viewAuthor(project, authorContact, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(project, true, displayMode);
    const adobe = {
      authorStaffID: authorContact.id,
      caseID: project.projectId,
      materialPAStatus: project.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED,
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_profile_result_expanded_view');
  },

  async viewAuthorAssistant(project, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(project, true, displayMode);

    const adobe = {
      caseID: project.projectId,
      materialPAStatus: NO_VAR,
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Contact_Assistant');
  },

  async slackAuthor(project, authorContact, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(project, true, displayMode);

    const adobe = {
      authorStaffID: authorContact.id,
      caseID: project.projectId,
      materialPAStatus: project.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_slack_interaction');
  },

  async emailAuthor(project, authorContact, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(project, true, displayMode);

    const adobe = {
      authorStaffID: authorContact.id,
      caseID: project.projectId,
      materialPAStatus: project.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_email_interaction');
  },

  async emailFile(project) {
    PAGE_ANALYTICS.setDefaultFileProps(project);
    const adobe = {
      materialID: project.materialId || NO_VAR,
      attachmentID: project.attachmentId || NO_VAR,
      caseID: project.projectId,
      cardExpansionStatus: CARD_EXPANDED,
      materialPAStatus: project.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('email_interactions');
  },

  async aboutMaterial(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded, '');

    PAGE_ANALYTICS.track('docVis_about_material');
  }
};