import CONFIG from 'config';
import axios from 'axios';
import { isValidQuery } from 'components/QuerySuggestions/QuerySuggestions';

export const name = 'querySuggestions';

export const API_QUERY_SUGGESTIONS_PENDING = 'API_QUERY_SUGGESTIONS_PENDING';
export const API_QUERY_SUGGESTIONS_FAILURE = 'API_QUERY_SUGGESTIONS_FAILURE';
export const API_QUERY_SUGGESTIONS_SUCCESS = 'API_QUERY_SUGGESTIONS_SUCCESS';

const sources = [];
const initialState = {
  querySuggestions: [],
  loading: true,
  error: false,
  errorMessage: ''
};

export const actions = {
  getQuerySuggestions: (query) => async (dispatch) => {
    dispatch({ type: API_QUERY_SUGGESTIONS_PENDING });

    if (!isValidQuery(query)) {
      dispatch({ type: API_QUERY_SUGGESTIONS_SUCCESS, payload: [] });
      return;
    }

    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    try {
      const data = await axios.post(CONFIG.API_URL.QUERY_SUGGESTIONS,
        { query, },
        {
          headers: { 'x-api-key': CONFIG.DATA_SCIENCE_API_X_API_KEY },
          cancelToken: newSource.token
        }
      );

      dispatch({ type: API_QUERY_SUGGESTIONS_SUCCESS, payload: JSON.parse(data) });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_QUERY_SUGGESTIONS_FAILURE, payload: error.toString() });
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_QUERY_SUGGESTIONS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_QUERY_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_QUERY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        querySuggestions: action.payload
      };
    default:
      return state;
  }
}