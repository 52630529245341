/* eslint-disable no-plusplus */

/* SEE TESTS for example of what this code does
 * USE TESTS to test new scenarios and ensure no regressions

 * for the sake of expediting dev and testing locally, you can use node to dev faster
 * uncomment the last blocks of code referring to node tests
 * remove `export` from getSearchSyntax and getuserSearchPhrase (make sure to put them back after)
 * run `nodemon advancedsearch.js` in command line to run this code and debug in a JavaScript Debug Terminal
*/
const isBoolean = (p) => p in { OR: '', NOT: '', NEAR: '', AND: '' }; // a little cheat so I can use in statement

const removeANDs = (phraseArray) => phraseArray.filter((p) => p.trim() !== 'AND');

const addANDs = (phraseArray) => {
  const newArray = [];
  phraseArray.forEach((p, i) => {
    if (isBoolean(p)) {
      newArray.push(p);
    } else if (isBoolean(phraseArray[i + 1]) || i === phraseArray.length - 1) { // if next word is boolean, do nothing
      newArray.push(p);
    } else { // next word is not a boolean, add AND
      newArray.push(p);
      newArray.push('AND');
    }
  });
  return newArray;
};

const handleQuoted = (phraseArray) => {
  const newArray = [];
  for (let index = 0; index < phraseArray.length; index++) {
    const p = phraseArray[index];
    if (p.indexOf('"') === -1) {
      newArray.push(p);
    } else {
      let newPhrase = p; // first word with the opening quote
      if (newPhrase.match(/"/g).length === 1) { // ensure the word only contains one quote, two quotes can just go right into the array
        for (let i = index + 1; i < phraseArray.length; i++) {
          const nextWord = phraseArray[i];
          newPhrase = `${newPhrase} ${nextWord}`; // append the next word
          index++; // increment the outer loop, which counts for the next word
          if (nextWord.indexOf('"') > -1) { // this word has the closing quote
            break;
          }
        }
      }
      newArray.push(newPhrase);
    }
  }
  return newArray;
};

const handleNear = (phraseArray) => {
  const newArray = [];

  for (let i = 0; i < phraseArray.length; i++) {
    if (phraseArray[i + 1] !== 'NEAR') { // if next word is NEAR, skip this word because it'll be included in next
      const word = phraseArray[i];
      if (word === 'NEAR' && phraseArray[i - 1] && phraseArray[i + 1]) {
        let newWord = `${phraseArray[i - 1]} ${phraseArray[i]} ${phraseArray[i + 1]}`;
        if (!newWord.match(/\([a-z0-9\s]*\)/i)) {
          newWord = `(${newWord})`;
        }
        newArray.push(newWord);
        i++;
      } else {
        newArray.push(word);
      }
    }
  }
  return newArray;
};

const addFieldName = (phraseArray, fieldName) => phraseArray.map((p) => {
  if (isBoolean(p)) {
    return p;
  }
  if (p.indexOf('(') === 0 && p.indexOf('NEAR') === -1) {
    return `(${fieldName}::${p.replace('(', '')}`;
  }
  return `${fieldName}::${p}`;
});

export const getSearchSyntax = (phrase, fieldName) => {
  let newPhrase = phrase;
  if (phrase) {
    let subPhrases = newPhrase.match(/\S+/g);
    subPhrases = handleQuoted(subPhrases);
    subPhrases = handleNear(subPhrases);
    subPhrases = removeANDs(subPhrases); // remove ANDs the user typed in
    subPhrases = addANDs(subPhrases); // add ANDs where WE want them
    subPhrases = addFieldName(subPhrases, fieldName);
    newPhrase = subPhrases.join(' ');
    newPhrase = `(${newPhrase})`;
  }
  return newPhrase;
};

const removeFieldName = (phrase, fieldName) => {
  const fieldRegex = new RegExp(`${fieldName}::`, 'g');
  return phrase.replace(fieldRegex, '');
};

const getFieldName = (syntax) => {
  const fieldNames = syntax.match(/\((?:[A-Z]*\s)?([a-zA-Z_|]*)::/);
  return fieldNames[1];
};

export const getUserSearchPhrase = (syntax) => {
  const fieldName = getFieldName(syntax);
  let phrase = syntax.substring(1, syntax.length - 1); // removes first ( and last )
  phrase = removeFieldName(phrase, fieldName);
  phrase = phrase.replace(/ AND /g, ' ');
  phrase = phrase.replace(/\|/g, '');
  return [phrase, fieldName];
};

/**
* Creates a new query params object, and calls the callback function if it differs from the currentParams
* @param {object} currentParams - Object containg the current query params
* @param {object} updatedParams - Object containg the new query params to compare to currentParams
* @param {callback} callbackFunc - Callback function called when the new params do not match currentParams
*/
export const applyAdvancedSearch = (currentParams, updatedParams, callbackFunc) => {
  const newParams = { ...currentParams };
  let newFilters = newParams.advSearch || [];
  Object.keys(updatedParams)
    .filter((key) => key !== 'query').forEach((key) => {
      newFilters = newFilters.filter((item) => item && getFieldName(item.name || item) !== key);
      newFilters.push(getSearchSyntax(updatedParams[key], key));
    });
  newFilters = newFilters.filter((field) => field !== '');
  newParams.advSearch = newFilters.map((filter) => ((typeof filter === 'string' || filter instanceof String) ? filter : filter.name));
  if (typeof updatedParams?.query === 'string' && currentParams.query !== updatedParams.query) {
    newParams.query = updatedParams.query;
  }

  if (JSON.stringify(newParams) !== JSON.stringify(currentParams)) {
    callbackFunc && callbackFunc(newParams);
  }
};

/* uncomment the following to run with node/nodemon
 * see the .test.js file for any updates to test logic
*/
// const test = require('./advancedsearch.vars');

// let errorCount = 0;
// console.log('\nTesting user phrase to search phrase\n');
// test.testPhrases.forEach((phrase) => {
//   const output = getSearchSyntax(phrase.userPhrase, phrase.fieldName);
//   console.log(phrase.userPhrase);
//   console.log(' \x1b[93m', phrase.searchPhrase, '\x1b[0m===', output === phrase.searchPhrase ? '\x1b[32m' : '\x1b[31m', output, '\x1b[0m');
//   errorCount += output !== phrase.searchPhrase;
// });

// console.log('\nTesting search phrase to user phrase\n');
// test.testPhrases.forEach((phrase) => {
//   const output = getUserSearchPhrase(phrase.searchPhrase);
//   const userPhrase = output[0].replace(/[()]/g, ''); // for testing, removing parenths as they may or may not be there and don't impact the results
//   const newPhrase = phrase.userPhrase.replace(/ AND /g, ' ').replace(/\s\s/ig, ' ').replace(/[()]/g, '').trim();
//   console.log(phrase.searchPhrase);
//   console.log(' \x1b[93m', phrase.userPhrase, '\x1b[0m===', userPhrase === newPhrase ? '\x1b[32m' : '\x1b[31m', userPhrase, '\x1b[0m');
//   errorCount += userPhrase !== newPhrase;
// });

// console.log('\n\nError Count:', errorCount, '\n\n');