import ANALYTICS from 'utils/analytics/analytics';

export const name = 'relevancyfeedback';

export const RELEVANT_YES = 'RELEVANT_YES';
export const RELEVANT_NO = 'RELEVANT_NO';
export const RELEVANT_ANSWERS = 'RELEVANT_ANSWERS';
export const RELEVANT_OTHER_TEXT = 'RELEVANT_OTHER_TEXT';
export const RELEVANT_INDICATORTEXT = 'RELEVANT_INDICATORTEXT';
export const RELEVANT_SAVED = 'RELEVANT_SAVED';
export const RELEVANT_CLEAR = 'RELEVANT_CLEAR';
export const SHOW_DOCVIZ_FEEDBACK = 'SHOW_DOCVIZ_FEEDBACK';
export const OTHER_CHECKBOX = 'OTHER_CHECKBOX';

const initialState = {
  docs: {},
  showPopup: false,
  otherCheckbox: false
};

const initialRelevancy = {
  indicatorText: '',
  indicatorSet: false,
  answers: '',
  showOther: false,
  otherText: '',
  relevancySaved: false
};

export const selectors = {
  getRelevancy: (state, syntheticId) => (state[name].docs[syntheticId] !== undefined ? state[name].docs[syntheticId] : initialRelevancy),
  getIsRelevancySaved: (state, syntheticId) => (state[name].docs[syntheticId] !== undefined ? state[name].docs[syntheticId].relevancySaved : initialRelevancy.relevancySaved),
  getIsIndicatorSet: (state, syntheticId) => (state[name].docs[syntheticId] !== undefined ? state[name].docs[syntheticId].indicatorSet : initialRelevancy.indicatorSet),
  getShowPopup: (state) => state[name].showPopup,
  getIsOtherCheckboxClicked: (state) => state[name].otherCheckbox
};

export const actions = {
  showDocvizPopup: (show) => (dispatch) => {
    dispatch({
      type: SHOW_DOCVIZ_FEEDBACK,
      showPopup: show
    });
  },
  setIsOther: (otherCheckbox) => (dispatch) => {
    dispatch({
      type: OTHER_CHECKBOX,
      otherCheckbox
    });
  },
  setIsRelevant: (syntheticId, indicatorText) => async (dispatch) => {
    dispatch({
      type: RELEVANT_YES,
      payload: {
        syntheticId,
        indicatorText,
        indicatorSet: true,
        answers: '',
        showOther: false,
        otherText: ''
      }
    });
  },
  setIsNotRelevant: (syntheticId, indicatorText) => async (dispatch) => {
    dispatch({
      type: RELEVANT_NO,
      payload: {
        syntheticId,
        indicatorText,
        indicatorSet: true,
        answers: '',
        showOther: false,
        otherText: ''
      }
    });
  },
  setAnswers: (syntheticId, answers, showOther, otherText) => async (dispatch) => {
    dispatch({
      type: RELEVANT_ANSWERS,
      payload: {
        syntheticId,
        answers,
        showOther,
        otherText
      }
    });
  },
  setOtherAnswer: (syntheticId, otherText) => async (dispatch) => {
    dispatch({
      type: RELEVANT_OTHER_TEXT,
      payload: {
        syntheticId,
        otherText
      }
    });
  },
  setIndicatorText: (syntheticId, indicatorText) => async (dispatch) => {
    dispatch({
      type: RELEVANT_INDICATORTEXT,
      payload: {
        syntheticId,
        indicatorText
      }
    });
  },
  clearRelevancy: (syntheticId) => async (dispatch) => {
    dispatch({
      type: RELEVANT_CLEAR,
      payload: { syntheticId }
    });
  },
  saveRelevancy: (syntheticId, doc, isExpanded, flavor, relatedSuggestion) => async (dispatch, getState) => {
    dispatch({
      type: RELEVANT_SAVED,
      payload: { syntheticId }
    });

    const relevancy = selectors.getRelevancy(getState(), syntheticId);

    ANALYTICS.relevancy.explicitFeedback(doc, isExpanded, flavor, `${relevancy.answers}${relevancy.otherText}`, relevancy.indicatorText, relatedSuggestion);
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RELEVANT_YES:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            selectedYes: true,
            selectedNo: false,
            indicatorText: action.payload.indicatorText,
            indicatorSet: action.payload.indicatorSet,
            answers: action.payload.answers,
            showOther: action.payload.showOther
          }
        }
      };
    case RELEVANT_NO:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            selectedYes: false,
            selectedNo: true,
            indicatorText: action.payload.indicatorText,
            indicatorSet: action.payload.indicatorSet,
            answers: action.payload.answers,
            showOther: action.payload.showOther
          }
        }
      };
    case RELEVANT_ANSWERS:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            ...state.docs[action.payload.syntheticId],
            answers: action.payload.answers,
            showOther: action.payload.showOther,
            otherText: action.payload.otherText,
          }
        }
      };
    case RELEVANT_OTHER_TEXT:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            ...state.docs[action.payload.syntheticId],
            otherText: action.payload.otherText,
          }
        }
      };
    case RELEVANT_INDICATORTEXT:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            ...state.docs[action.payload.syntheticId],
            indicatorText: action.payload.indicatorText
          }
        }
      };
    case RELEVANT_CLEAR:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: { initialState }
        }
      };
    case RELEVANT_SAVED:
      return {
        ...state,
        docs: {
          ...state.docs,
          [action.payload.syntheticId]: {
            ...state.docs[action.payload.syntheticId],
            relevancySaved: true
          }
        }
      };
    case SHOW_DOCVIZ_FEEDBACK:
      return {
        ...state,
        showPopup: action.showPopup
      };
    case OTHER_CHECKBOX:
      return {
        ...state,
        otherCheckbox: action.otherCheckbox
      };
    default:
      return state;
  }
}