import styled from 'styled-components';

export const StoryLineWrapper = styled.div`
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.COLORS.$black200};
        padding: 10px;
        padding-top: 0;
        margin-bottom: 0;
        border-bottom: 0.5px solid ${({ theme }) => theme.COLORS.$grey};
    }

    li {
        padding: 10px 0;
        position: relative;

        a {
            font-size: 16px;
            color: ${({ theme }) => theme.COLORS.$graydarker};
            line-height: 21px;
            padding: 0 0 0 10px;
        }
        
        a:hover {
            color: ${({ theme }) => theme.COLORS.$bcggreen};
            font-weight: bold;

            .circle {
                display: inline-block;
            }
        }

        .circle {
            display: none;
            position: absolute;
            top: calc(50% - 3px);
            background: ${({ theme }) => theme.COLORS.$bcggreen};
            border-radius: 50%;
            width: 5px;
            height: 5px;
        }

        .label {
            padding-left: 10px;
            display: inline-block
        }
    }

    li.active {
        a {
            color: ${({ theme }) => theme.COLORS.$bcggreen};
            font-weight: bold;
        }

        .circle {
            display: inline-block;
        }
    }
`;