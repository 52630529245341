import { merge } from 'lodash';
import ANALYTICS from 'utils/analytics/analytics';

export const closeChat = () => {
  _satellite.track('chatbot_close');
};

export const sendMessage = () => {
  _satellite.track('chatbot_send');
};

export const submitChatFeedback = ({ chatId, requestId, sentiment }, userFeedback) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    resultExplicitFeedback: userFeedback,
    displayMode: 'chat',
    SERPview: 'chat view',
    selectedOptionReason: userFeedback,
    materialID: 'not available',
    resultType: 'not available',
    materialURL: 'not available',
    globalRelevance: 'not available',
    materialPAStatus: 'not available',
    attachmentID: 'not available',
    searchResultRanking: 'not available',
    resultRelevanceRating: sentiment,
    labCourseID: 'not available',
    labCourseName: 'not available',
    labLessonID: 'not available',
    labLessonName: 'not available',
    caseID: 'not available',
    KCPage: {
      id: 'not available',
    }
  };
  const appDefaults = ANALYTICS.page.getAppDefaults();
  const expertsDefaults = ANALYTICS.expertsDefaults.getDefaultQueryProps();
  const chatAdobe = merge({}, appDefaults, expertsDefaults, adobe);
  ANALYTICS.page.trackEvent('result_explicit_feedback', chatAdobe);
};