import React, { useState } from 'react';
import { Row, Col } from 'components/FlexBoxGrid';
import CONFIG from 'config';
import { name as downloadName } from 'redux/api/downloadProgress/downloadProgress';
import { useSelector } from 'react-redux';
import { DownloadProgressBar } from './DownloadProgressBar/DownloadProgressBar';

import * as Styled from './DownloadProgressModal.styles';

export const DownloadProgressModal = () => {
  const { currentDownloads } = useSelector((state) => state[downloadName]);

  const [collapsed, setCollapsed] = useState(false);
  const handleCloseProgressBars = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Styled.ProgressBarMain display={currentDownloads && currentDownloads.length > 0} data-testid={CONFIG.TEST_IDS.DOWNLOAD_PROGRESS.PROGRESS_CONTAINER}>
      <Styled.ModalHeadingBar>
        <Col xs={12}>
          <Styled.ModalHeader>{CONFIG.DOWNLOAD_PROGRESS.MODAL_HEADER_TEXT}</Styled.ModalHeader>
          <Styled.HideButton name="minus" onClick={() => handleCloseProgressBars()} />
          <Styled.ClearFix />
        </Col>
      </Styled.ModalHeadingBar>
      <Styled.ModalBody collapsed={collapsed}>
        <Col xs={12}>
          <Row>
            <Styled.RefreshMessage xs={12}>
              <div>
                <Styled.HiddenErrorIcon />

              </div>
              <div>
                <Styled.RefreshMessageText>{CONFIG.DOWNLOAD_PROGRESS.REFRESH_WARNING_TEXT}</Styled.RefreshMessageText>
              </div>
            </Styled.RefreshMessage>
          </Row>
          <Styled.ProgressBarsContainers>
            {currentDownloads.map((download) =>
              <DownloadProgressBar key={download.fileId} downloadProgressLink={download.downloadProgressLink} fileName={download.fileName} fileId={download.fileId} downloadType={download.downloadType} />
            )}
          </Styled.ProgressBarsContainers>
        </Col>
      </Styled.ModalBody>
    </Styled.ProgressBarMain>
  );
};
