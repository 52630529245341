import Icon from 'components/Icon/Icon';
import { Grid, Row, Col } from 'components/FlexBoxGrid';
import styled from 'styled-components';

export const ProgressBarMain = styled(Grid)`
  &&& { 
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    max-width: 390px;
    position: fixed;
    right: 50px;
    bottom: 15px;
    overflow: hidden;
    background: ${({ theme }) => theme.COLORS.$white};
    display:${({ display }) => (display ? 'block' : 'none')};
  }
`;
export const ModalHeadingBar = styled(Row)`
  &&& { 
    background-color: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
`;
export const ModalHeader = styled.div`
  &&& { 
    font-family: ${({ theme }) => theme.FONTS.$sansbold};
    font-size: ${({ theme }) => theme.SIZE(14)};
    color: ${({ theme }) => theme.COLORS.$white};
    padding: 7px 4px;
    float: left;
  }
`;
export const ModalBody = styled(Row)`
  &&& { 
    ${({ collapsed }) => (collapsed ? 'display:none;' : null)}
  }
`;
export const RefreshMessage = styled(Col)`
  &&& { 
    display:flex;
  }
`;
export const RefreshMessageText = styled.span`
  &&& { 
    font-size: ${({ theme }) => theme.SIZE(11)};
    font-family: ${({ theme }) => theme.FONTS.$sansreg};
  }
`;
export const HiddenErrorIcon = styled(Icon)`
  &&& { 
    visibility:hidden;
  }
`;
export const HideButton = styled(Icon)`
  &&& { 
    cursor: pointer;
    float: right;
    margin-right: 5px;
    color: ${({ theme }) => theme.COLORS.$white};
    padding: 7px 0;
  }
`;
export const ClearFix = styled.div`
  &&& { 
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    max-width: 390px;
  }
`;
export const ProgressBarsContainers = styled.div`
  &&& { 
    background: ${({ theme }) => theme.COLORS.$white};
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
`;