import styled from 'styled-components';
import { Checkbox as InputCheckbox } from '@kdshared/enrich-ui';

export const ActionPlan = styled.div`
    margin-right: 80px;
    margin-bottom: 50px;
    .planbutton{
        padding: 10px 15px;
        color: black;
        float: right;
        border-color: black;
        font-size: ${({ theme }) => theme.SIZE(16)};
        text-transform: none;
        margin-top: 13px;
        position: absolute;
        bottom: 10px;
        right: 115px;
    }
    .draggable__handler{
        display: inline-block;
        height: 20px;
        width: 20px;
        background: red;
    }
    .subHeading {
        display: flex;
    }
    .h2{
        color: ${({ theme }) => theme.COLORS.$black};
        font-size: ${({ theme }) => theme.SIZE(18)};
        margin-bottom: 16px;
    }
    .loader {
        margin: 4px 0 0 15px;
    }
`;

export const Listing = styled.div`
    border: 1px solid ${({ theme }) => theme.NEW.lightGray};
    margin: 0 0 15px;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    h5{
        display: inline-block;
        margin: 0;
        margin-left: ${({ theme }) => theme.SIZE(30)};
        line-height: inherit;
        color: ${({ theme }) => theme.COLORS.$gvgray};
        font-family: ${({ theme }) => theme.FONTS.$sansbold};
        font-size: ${({ theme }) => theme.SIZE(20)};
    }
    .openiconactive.ui.image{
        display: none;
    }
    &.active, 
    &:hover{
        border: 1px solid ${({ theme }) => theme.COLORS.$bcggreen};
        background-color: ${({ theme }) => theme.NEW.grays.gray100};
        h5{
            color: ${({ theme }) => theme.COLORS.$black};
        }
        .openicon{
            display: none;
        }
        .openiconactive.ui.image{
            display: block;
        }
    }
    .icon{
        display: inline-block;
        position: absolute;
        .ui.image{
            max-width: none;
            height: 22px;
        }
    }
`;

export const ListItem = styled.div`
    color: ${({ theme }) => theme.COLORS.$black};
    position: relative;

    .heading {
        display: flex;
        padding: 15px;
        justify-content: space-between;
    }
    h3 {
        font-size: ${({ theme }) => theme.SIZE(14)};
        color: ${({ theme }) => theme.COLORS.$black};
        margin-bottom: ${({ theme }) => theme.SIZE(4)};
    }
    .dragicon {
        position: absolute;
        left: -23px;
        top: -1px;
        &.dragicon-disable{
            opacity: 0.2;
        }
        .dicon{
            display: none;
        }
        &:hover:not(.dragicon-disable){
            .dicon-active{
                display: none;
            }
            .dicon{
                display: block;
            }
        }
    }
    .content {
        margin-left: ${({ theme }) => theme.SIZE(46)};
        margin-bottom: 20px;
    }
    .options li {
        font-weight: 700;
        margin-bottom: ${({ theme }) => theme.SIZE(4)};
    }
    .show {
        display: block;
    }
    .hide {
        display: none;
    }
`;

export const Checkbox = styled(InputCheckbox)`
  &&&& {
    > label {
      display: inline-block;
      overflow-wrap: break-word;
      vertical-align: middle;

      color: ${({ theme }) => theme.COLORS.$black};
      padding-left: ${({ theme }) => theme.SIZE(22)};
      letter-spacing: 0;
      font-family: ${({ theme }) => theme.FONTS.$sansreg};
      font-size: ${({ theme }) => theme.SIZE(14)};
      font-weight: ${({ checked }) => checked && 'bold'};
    }
  }
`;

export const Overlay = styled.div`
  position: relative;

  .backdrop {
    position: absolute;
    height: 100%;
    width: calc(100% + 23px);
    z-index: 1;
    margin-left: -23px;
    background: ${({ theme }) => theme.COLORS.$graylight};
    opacity: 0.5;
  }
`;