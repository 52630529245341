import { withRouter, useHistory } from 'react-router';
import { Image } from '@kdshared/enrich-ui';
import Button from 'components/Button/Button';
import openIcon from 'assets/images/open.svg';
import openIconHover from 'assets/images/open-hover.svg';
import binIcon from 'assets/images/bin.svg';
import dragIcon from 'assets/images/dragicon2.svg';
import dragIconHover from 'assets/images/dragicon.svg';
import * as Styled from './ActionPlan.style';
import { useCallback, useEffect, useState, useRef } from 'react';
import CONFIG from 'config';
import {LABELS} from 'utils/proposalBuilder/labels';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ChatLoader from 'components/ChatLoader/ChatLoader';
import {Modal} from 'containers/ProposalBuilder/Modal/Modal';


export const ActionPlan = ({listing = [], disabled = false}) => {
  const { UI_URL: { PROPOSALBUILDER_DETAILS }} = CONFIG;
  const { 
    SUBMIT_PLAN, 
    ACTION_PLAN_HEADING, 
    ACTION_PLAN_SUB_HEADING, 
    DISABLE_DRAG_DROP_MESSAGE, 
    DRAG_DROP_MESSAGE, 
    DRAG_HANDLER, 
    DELETE_ICON, 
    DELETE_LABEL, 
    DELETE_TITLE, 
    CANCEL_LABEL
  } = LABELS;
  const [activeItem, setActiveItem] = useState(false);
  const [actionListing, setActionListing] = useState(listing);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const itemDelRef = useRef(0);

  useEffect(() => {
    setActionListing(listing);
  }, [listing]);

  const handleClick = useCallback((index = false) => {
    if(activeItem === index) {
      setActiveItem(false);
      return;
    }

    setActiveItem(index);
  }, [activeItem]);

  const handleDeletePlan = useCallback((e, index = false) => {
    e.preventDefault();
    e.stopPropagation();
    itemDelRef.current = index;
    setShowDeleteModal(true);
  });

  const handleDeleteApproval = () => {
    setActionListing(oldValues => {
      return oldValues.filter((_, i) => i !== itemDelRef.current);
    });
    setActiveItem(false);
    setShowDeleteModal(false);
  };

  const SortableItem = ({children, ele, index, disabled}) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: ele.id, disabled});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    
    return (
      <div ref={setNodeRef} style={style} >
        <Styled.Listing className={`${index === activeItem ? 'active' : ''}`} key={ele.label}>
          <Styled.ListItem>
            {children}
            <span {...attributes} {...listeners} className={`dragicon ${activeItem !== false ? 'dragicon-disable' : ''}`}>
              <Image className="dicon-active" src={dragIcon} title={activeItem !== false ? DISABLE_DRAG_DROP_MESSAGE : DRAG_DROP_MESSAGE} alt={DRAG_HANDLER} />
              <Image className="dicon" src={dragIconHover} title={DRAG_DROP_MESSAGE} alt={DRAG_HANDLER} />
            </span>
          </Styled.ListItem>
        </Styled.Listing>
      </div>
    );
  };

  const onSortEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setActionListing((items) => {
        const oldIndex = items.findIndex(f => f.id === active.id);
        const newIndex = items.findIndex(f => f.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleCheckboxClick = (e, listIndex, materialIndex) => {
    setActionListing((state) => {
      const newState = [...state];
      newState.map((_, i) => {
        if(i=== listIndex) {
          newState[i] = {
            ...newState[i],
            options: newState[i].options.map((item, index) => {
              if(index === materialIndex) {
                return {
                  ...item,
                  isChecked: !item.isChecked
                };
              }

              return {...item};
            })
          };
        }

        return newState[i];
      });

      return newState;
    });
  };

  const getContainer = () => {
    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={onSortEnd}>
        <SortableContext items={actionListing} strategy={verticalListSortingStrategy}>
          {actionListing.map((ele, index) => {
            return (
              <SortableItem key={ele.id} ele={ele} index={index} disabled={activeItem !== false}>
                <div onClick={() => handleClick(index)} className='heading' data-testid="list-item">
                  <span className="icon">
                    <Image className="openiconactive" src={openIconHover} alt="opencloseicon" />
                    <Image className="openicon" src={openIcon} alt="opencloseicon" />
                  </span>
                  <h5>{ele.label}</h5>
                  <Image data-testid="delIcon" src={binIcon} alt={DELETE_ICON} onClick={(e) => handleDeletePlan(e, index)}/>
                </div>
                <div className={`content ${index === activeItem ? 'show': 'hide'}`} data-testid="list-item-expanded">
                  <h3>{ACTION_PLAN_HEADING}</h3>
                  <ul className='options'>
                    {ele.options.map((o, i) => (
                      <li key={i} data-testid="checkbox">
                        <Styled.Checkbox label={o?.label} checked={o.isChecked} onClick={(e) => handleCheckboxClick(e, index, i)}/>
                      </li>
                    ))}
                  </ul>
                </div>
              </SortableItem>
            );
          })}
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <>
      <Modal
        title={DELETE_TITLE} 
        cancelButtonLabel={CANCEL_LABEL}
        approvedButtonLabel={DELETE_LABEL}
        onCancel={() => {setShowDeleteModal(false);}} 
        onApproved={handleDeleteApproval} 
        show={showDeleteModal}
      />
      <Styled.ActionPlan>
        <div className='subHeading'>
          <h2 className='h2'>{ACTION_PLAN_SUB_HEADING}</h2>
          {disabled && 
        <span data-testid="loader">
          <ChatLoader className="loader" />
        </span>
          }
        </div>
        {disabled ? <Styled.Overlay>
          <div className='backdrop' data-testid="backdrop"></div>
          {getContainer()}
        </Styled.Overlay>
          :
          getContainer()
        }
        {!!actionListing.length && <Button
          className="planbutton"
          disabled={disabled}
          onClick={() => history.push(PROPOSALBUILDER_DETAILS())}
        >{SUBMIT_PLAN}</Button>}
      </Styled.ActionPlan>
    </>
  );
};

export default withRouter(ActionPlan);