import styled from 'styled-components';
import { Icon } from '@kdshared/enrich-ui';
import Button from 'components/Button/Button';

export const MenuWrapper = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    background-color: ${({ theme }) => theme.COLORS.$graydarkest};
    transition: all 0.3s ease-in-out;
    transform: ${({ $stickyProps }) => $stickyProps === 'no-sticky' ? 'translateY(-100%)' : 'none'};
    z-index: 101;
`;

export const Burger = styled(Icon)`
  &&&& {
    margin: 0 !important;
    font-size: 24px;
  }
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  min-height: ${({ theme }) => theme.SIZE(40)};
  padding: ${({ theme }) => `${theme.SIZE(12)} ${theme.SIZE(9)} ${theme.SIZE(10)} ${theme.SIZE(12)}`};
  justify-content: space-between;
  align-content: center;
  font-family: ${({ theme }) => theme.FONTS.$sansreg};

  i.icon {
    color: ${({ theme }) => theme.COLORS.$graydark};
    /* position: relative;
    right: -9px; */
    margin-left: 8px;
    width: 7px;
  }

  ${({ header, theme }) => header && `
    font-family: ${theme.FONTS.$sansbold};
    margin-top: 8px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  `}

  ${({ title, theme }) => title && `
    color: ${theme.COLORS.$graydark};
    letter-spacing: 1.5px;
  `}

  ${({ backButton, theme }) => backButton && `
    color: ${theme.COLORS.$bcggreenbluegreen};
    justify-content: flex-start;
    padding-top: 20px;
    padding-left: 0px;
    font-size: 14px;
    i.icon {
      position: initial;
      color: ${theme.COLORS.$bcggreenbluegreen};
      font-size: 12px;
      margin-right: 8px;
      right: initial;
      width: initial;
    }
  `}

  ${({ isButton, theme }) => isButton && `
      background-color: ${theme.COLORS.$bcggreenbluegreen};
      border-bottom: 0px;
      border-radius: 5px;
      color: ${theme.COLORS.$white};
      font-family: ${theme.FONTS.$sansbold};
      font-size: 12px;
      height: 40px;
      letter-spacing: 0.8px;
      letter-spacing: 0.8px;
      margin: 24px 8px 0px 8px;
      padding: 15px 15px !important;
      text-transform: uppercase;
      width: auto;

      i.icon {
        font-size: 14px;
        margin: 0px 0px 0px 5px;
        color: ${theme.COLORS.$white};
      }

      &:hover, &:active, &:visited {
        background-color: ${theme.COLORS.$bcggreenbluegreen};
        color: ${theme.COLORS.$white};
        border-bottom: 0px;
      }
  `}
`;

export const Backdrop = styled.div`
    content: "";    
    background: rgba(0,0,0,0.65);    
    position: fixed;    
    top: ${({ theme }) => theme.SIZE(45)};    
    left:0;    
    bottom: 0;    
    right: 0; 
    z-index: 102;
`;

export const MenuContentWrapper = styled.div`
  .menu-enter {
    display: block;
    height: 100vh;
    opacity: 0;
    transform: translateX(-100%) translateY(5px);
  }
  .menu-enter-active {
    display: block;
    height: 100vh;
    opacity: 1;
    transform: translateX(0) translateY(5px);
    transition: opacity 300ms, transform 300ms;
  }
  .menu-enter-done {
    display: block;
    height: 100vh;
    transform: translateX(0) translateY(5px);
  }
  .menu-exit {
    display: block;
    height: 100vh;
    opacity: 1;
    transform: translateX(0) translateY(5px);
  }
  .menu-exit-active {
    display: block;
    height: 100vh;
    opacity: 0;
    transform: translateX(-100%) translateY(5px);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  position: fixed;
  background-color: ${({ theme }) => theme.COLORS.$graydarkest};
  padding: 20px 10px;
  justify-content: center;
  min-height: 250px;
  box-shadow: -5px 4px 5px rgba(155,155,155,0.1);
  z-index: 102;

  ${({ location, theme }) => location === 'main' && `
    top: ${theme.SIZE(40)};
    width: 75%;
  `}

  ${({ location }) => location === 'secondary' && `
    top: 57px;
    width: 440px;
    height: 100vh;
    right: 0;
  `}
`;

export const DropdownWrapper = styled.div`
    padding: 20px;
    & > .ui.dropdown {
      // width: 100%;
      border: 1px solid ${({ theme }) => theme.COLORS.$graydark};
      border-radius: 4px;
      padding: 10px;
    }
`;

export const StartChatWrapper = styled.div`
    padding: 20px;
`;

export const StartChatButton = styled(({ ...rest }) => <Button {...rest} />)`
  &&& {
    width: 100%;
    height: 40px;
    border-color: ${({ theme }) => theme.COLORS.$graydarklight};
    color: ${({ theme }) => theme.COLORS.$graydarklight};
    background-color: #202123; // ${({ theme }) => theme.COLORS.$blue};
  }
`;

export const HeaderTitle = styled.span`
  color: ${({ theme }) => theme.COLORS.$white};
  font-weight: ${({ theme }) => theme.FONTS.$weightbold};
  font-size: ${({ theme }) => theme.SIZE(20)};
  padding-right: ${({ theme }) => theme.SIZE(3)};
`;

export const HeaderDescription = styled.span`
  flex: 1;
  color: ${({ theme }) => theme.COLORS.$graydark};
  font-size: ${({ theme }) => theme.SIZE(16)};
  padding-left: 7vw;
`;