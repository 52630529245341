import PAGE_ANALYTICS from './page_analytics';
import RESULTS_ANALTYICS from './results_analytics';
import REFINERS_ANALTYICS from './refiners_analytics';
import DISPLAYCONTROL_ANALYTICS from './displaycontrol_analytics';
import RECENT_SEARCHES_ANALYTICS from './recentsearches_analytics';
import TYPEAHEAD_ANALYTICS from './typeahead_analytics';
import SEARCH_INPUT_ANALYTICS from './searchinput_analytics';
import SEARCH_HINTS_ANALYTICS from './hints_analytics';
import RELEVANCY_ANALYTICS from './relevancy_analytics';
import SPELL_CHECK_ANALYTICS from './spellcheck_analytics';
import LAB_ANALYTICS from './lab_analytics';
import MATERIAL_ANALYTICS from './material_analytics';
import CASE_ANALYTICS from './case_analytics';
import SEARCH_KN_ANALYTICS from './searchKN_analytics';
import TOPICSUGGESTIONS_ANALYTICS from './topicsuggestions_analytics';
import PEOPLE_ANALYTICS from './people_analytics';
import ERROR_ANALYTICS from './error_analytics';
import COVID_ANALYTICS from './covid_analytics';
import PEOPLE_DEFAULTS from './people_defaults';
import CASESV2_DEFAULTS from './casesv2_defaults';
import LAB_DEFAULTS from './lab_defaults';
import KNOWLEDGE_DEFAULTS from './knowledge_defaults';
import INTERNAL_DEFAULTS from './bcginternal_defaults';
import EXPERT_DEFAULTS from './experts_defaults';
import PA_TOPIC_DEFAULTS from './pa_topic_defaults';

export default {
  page: PAGE_ANALYTICS,
  results: RESULTS_ANALTYICS,
  refiners: REFINERS_ANALTYICS,
  displayControl: DISPLAYCONTROL_ANALYTICS,
  recentSearches: RECENT_SEARCHES_ANALYTICS,
  typeahead: TYPEAHEAD_ANALYTICS,
  searchInput: SEARCH_INPUT_ANALYTICS,
  searchHints: SEARCH_HINTS_ANALYTICS,
  relevancy: RELEVANCY_ANALYTICS,
  spellCheck: SPELL_CHECK_ANALYTICS,
  lab: LAB_ANALYTICS,
  material: MATERIAL_ANALYTICS,
  case: CASE_ANALYTICS,
  searchKN: SEARCH_KN_ANALYTICS,
  topicSuggestions: TOPICSUGGESTIONS_ANALYTICS,
  people: PEOPLE_ANALYTICS,
  errorPage: ERROR_ANALYTICS,
  covid: COVID_ANALYTICS,
  knowledgeDefaults: KNOWLEDGE_DEFAULTS,
  bcgInternalDefaults: INTERNAL_DEFAULTS,
  peopleDefaults: PEOPLE_DEFAULTS,
  casesv2Defaults: CASESV2_DEFAULTS,
  labDefaults: LAB_DEFAULTS,
  expertsDefaults: EXPERT_DEFAULTS,
  paTopicsDefaults: PA_TOPIC_DEFAULTS
};
