import history from 'redux/history';
import store from 'redux/store';
import { actions as typeAheadActions } from 'redux/components/type-ahead/type-ahead';
import { getFromLocalStorageAsJSON, sendJSONToLocalStorage, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { queryObjToQueryString } from 'utils/string-mapper/string-mapper';
import ANALYTICS from 'utils/analytics/analytics';
import { applyFilter } from './queryintent.analytics';

const QI = 'queryintent';
const QI_EXPIRE = 1000 * 60 * 60 * 24; // 1000ms x 60 seconds x 60 minutes x 24 hours

export const disableQueryIntent = () => {
  sendJSONToLocalStorage(QI, new Date());
};

export const isQueryIntentEnabled = () => {
  const val = getFromLocalStorageAsJSON(QI);
  if (val) {
    const dateDisabled = new Date(val);
    if (new Date() - dateDisabled >= QI_EXPIRE) {
      removeFromLocalStorage(QI);
      return true;
    }
    return false;
  }
  return true;
};

export const replaceSpecialRegExCharacters = (value) => {
  const specialCharacters = ['^', '$', '.', '|', '?', '*', '+', '(', ')', '[', ']', '{', '}'];
  return value ?
    specialCharacters.reduce((prevValue, currValue) => prevValue.replaceAll(currValue, `\\${currValue}`), value)
    :
    '';
};

export const getNewSearchTerm = (queryIntents, searchTerm) => {
  let newSearchTerm = searchTerm;

  if (queryIntents?.length > 0) {
    queryIntents.forEach((qi) => {
      if (qi.entities && qi.entities.length > 0) {
        const firstEntityVal = qi.entities[0].value;
        const queryEx = new RegExp(replaceSpecialRegExCharacters(firstEntityVal), 'i');
        newSearchTerm = newSearchTerm.replace(queryEx, '');
      }
    });
  }

  return newSearchTerm.trim().replace(/\s\s/ig, ' ');
};

export const goToQiUrl = (queryIntents, query) => {
  const newSearchTerm = getNewSearchTerm(queryIntents, query.query);
  const newQuery = {
    ...query,
    query: newSearchTerm,
    [queryIntents[0].refinerName]: queryIntents[0].entities[0].normalization
  };
  history.push({ search: queryObjToQueryString(newQuery) });
};

export const applyFilterAnalytics = (queryIntents, query, originalTerm) => {
  applyFilter(queryIntents[0].refinerName, [queryIntents[0].entities[0].normalization], originalTerm);
};

export const undoQueryIntent = (queryIntents, appliedFilters, appliedQueryParams, origSearchTerm) => {
  const newFilters = {
    ...appliedFilters
  };

  const newQueryParams = {
    ...appliedQueryParams,
    query: origSearchTerm
  };

  disableQueryIntent();

  if (queryIntents) {
    queryIntents.forEach((qi) => {
      delete newFilters[qi.refinerName];
      ANALYTICS.refiners.clearFilter(qi.refinerName, [qi.entities[0].normalization], false, true);
    });
  }

  typeAheadActions.changeSearchTerm(origSearchTerm)(store.dispatch);

  // Return the 'appliedFilterAndQueryParams' object!
  return {
    appliedFilters: newFilters,
    appliedQueryParams: newQueryParams
  };
};
