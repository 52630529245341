// eslint-disable-next-line import/no-cycle
import store from 'redux/store';
import CONFIG from 'config';
import ANALYTICS from 'utils/analytics/analytics';
import { NO_VAR, EMPTY_QUERY } from './analytics_constants';

let bcginternalDefaults;

export default {
  getDefaultQueryProps() {
    const {
      search: { profiles }
    } = store.getState();

    if (bcginternalDefaults) {
      bcginternalDefaults.sinequaProfile = profiles || NO_VAR;
    }

    return bcginternalDefaults;
  },
  setDefaultQueryProps(brand) {
    const {
      bcgInternal: { lastSearch: { query }, autoCorrect: { isSearchTermCorrected, correctedSearchTerm } },
      search: { profiles }
    } = store.getState();

    let term;
    if (isSearchTermCorrected) {
      term = correctedSearchTerm;
    } else if (query.query !== undefined) {
      if (query.emptyQuery) {
        term = EMPTY_QUERY;
      } else {
        term = query.query;
      }
    } else {
      term = NO_VAR;
    }

    const recommendedBy = query.recommendedBy ? ('recommendedBy=').concat(query.recommendedBy) : NO_VAR;

    const sortBy = query.sortingOrder || null;

    const filterTerms = Object.values(CONFIG.BCG_INTERNAL_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
    const terms = filterTerms
      .filter((term) => query
        && query[term] !== undefined
        && term !== CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT
        && term !== CONFIG.QUERY_PARAMS.SORTING_ORDER
        && term !== CONFIG.QUERY_PARAMS.QUERY)
      .map((term) => (`${term}=`).concat(query[term]))
      .join('|');

    const adobe = {
      page: {
        pageInfo: { pageName: brand?.PAGE_TITLES.BCG_INTERNAL() }
      },
      search: {
        term,
        type: CONFIG.SEARCH.SCOPES.BCG_INTERNAL
      },
      preFilterTerm: terms || NO_VAR,
      preOrderTerm: [sortBy,
        decodeURIComponent(recommendedBy)]
        .filter((x) => x !== NO_VAR).join('|') || NO_VAR,
      sinequaProfile: profiles || NO_VAR
    };

    bcginternalDefaults = adobe;
  }
};