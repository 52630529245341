import { useState, useCallback, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';

import { actions as chatActions } from 'redux/api/chat/chat';

import * as Styled from './ChatHeaderMenu.styles';

const ChatHeaderMenu = () => {

  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [stickyProps, setStickyProps] = useState('');
  const ref = useRef('');

  let lastScroll = 0;
  const stickyThreshold = 108;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleNewChat = useCallback(() => {
    dispatch(chatActions.resetChatState());
    setShowMenu(false);
  }, [showMenu]);

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    if (currentScroll > lastScroll && currentScroll > stickyThreshold && stickyProps !== 'no-sticky') {
      setStickyProps('no-sticky');
    } else if ((currentScroll < lastScroll && stickyProps !== 'sticky')) {
      setStickyProps('sticky');
    }

    lastScroll = currentScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', () => handleScroll, false);
    };
  }, []);

  return (
    <>
      <Styled.MenuWrapper $stickyProps={stickyProps} ref={ref}>
        <Styled.MenuItem tight square onClick={toggleMenu} data-testid="burgerbutton">
          {showMenu && <Styled.Burger name="times" />}
          {!showMenu && <Styled.Burger name="bars" />}
          <Styled.HeaderTitle $variant="title">Navi</Styled.HeaderTitle>
        </Styled.MenuItem>
      </Styled.MenuWrapper>
      {showMenu && <Styled.Backdrop data-testid="menu-backdrop" onClick={() => setShowMenu(false)} />}
      <Styled.MenuContentWrapper data-testid="menu-content-wrapper" type="button">
        <CSSTransition
          in={showMenu}
          timeout={600}
          classNames="menu"
          unmountOnExit>
          <Styled.MenuContent location="main">
            <Styled.StartChatWrapper>
              <Styled.StartChatButton
                title="Start a new chat. Your current chat will be lost."
                onClick={handleNewChat}
              >
                Start New Chat
              </Styled.StartChatButton>
            </Styled.StartChatWrapper>
          </Styled.MenuContent>
        </CSSTransition>
      </Styled.MenuContentWrapper>
    </>
  );
};

export default ChatHeaderMenu;