export const name = 'docViz2Card';
export const LOADING_DOC_VIZ_2 = 'LOADING_DOC_VIZ_2';
export const SET_OR_UPDATE_DOC_SLIDES = 'SET_OR_UPDATE_DOC_SLIDES';

const initialState = {
  docMap: {},
  isLoading: false,
};

export const actions = {
  /**
   * @param {doc} docWithSlides see `src/utils/docviz/getSlides()` for doc shape
   */
  setOrUpdateDocSlides: (docWithSlides) => (dispatch, getState) => {
    const { docViz2Card: { docMap } } = getState();
    const updatedDocMap = {
      ...docMap,
      [docWithSlides.docId]: docWithSlides
    };
    dispatch({ type: SET_OR_UPDATE_DOC_SLIDES, payload: updatedDocMap });
    dispatch({ type: LOADING_DOC_VIZ_2, payload: false });
  },
  setIsLoading: (loading) => (dispatch, getState) => {
    dispatch({ type: LOADING_DOC_VIZ_2, payload: loading });
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_DOC_VIZ_2:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_OR_UPDATE_DOC_SLIDES:
      return {
        ...state,
        docMap: action.payload
      };
    default:
      return state;
  }
}