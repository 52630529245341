import PAGE_ANALYTICS from './page_analytics';

export default {
  async recentSearchOption(recentTerm) {
    const adobe = { search: { recentTerm } };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('recent_search_option');
  },

  async clear() {
    PAGE_ANALYTICS.track('recent_search_clear', {
      integrations: {
        Adobe: false,
        DataDog: true
      }
    });
  }
};
