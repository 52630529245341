import axios from 'axios';
import { actions as downloadSlidesActions } from 'redux/components/downloadSlides/downloadSlides';

export function downloadDocvizDocument(doc) {
  const { downloadDocument } = downloadSlidesActions;
  const payload = {
    ...doc,
    kpCmsId: doc.kpCmsId || doc.materialId
  };
  return (dispatch) => downloadDocument(payload)(dispatch);
}

export async function downloadPreparedFile(downloadUrl, fileName) {
  let downloadResponse;
  try {
    downloadResponse = await axios.get(downloadUrl, { responseType: 'blob', });
  } catch (err) {
    console.log('Error downloading:', err);
    throw err;
  }
  const fileBlob = new Blob([downloadResponse], { type: 'mime' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(fileBlob, `${fileName}`);
  } else {
    const blobDownloadResponse = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement('a');
    tempLink.href = blobDownloadResponse;
    tempLink.setAttribute('download', `${fileName}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
  }
}

export const downloadHostMapper = (originalHost, binaryMappings) => {
  const binaryMapping = JSON.parse(binaryMappings)
    .find((m) => originalHost.includes(m.from));
  return originalHost.replace(binaryMapping.from, binaryMapping.to);
};
