import axios from 'axios';
import CONFIG from 'config';
import { actions as peopleResultsActions } from 'redux/api/peopleResults/peopleResults';
import { getKnowledgeTypeAheadSuggestions } from 'components/SearchWithTypeAhead/util/typeAhead/knowledgeTypeAhead';
import { currentQueryStringToObj } from 'utils/string-mapper/string-mapper';

export const API_TYPE_AHEAD_PENDING = 'API_TYPE_AHEAD_PENDING';
export const API_TYPE_AHEAD_FAILURE = 'API_TYPE_AHEAD_FAILURE';
export const API_TYPE_AHEAD_SUCCESS = 'API_TYPE_AHEAD_SUCCESS';
export const API_TYPE_AHEAD_CANCELLED = 'API_TYPE_AHEAD_CANCELLED';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const CHANGE_MATCH_VALUE = 'CHANGE_MATCH_VALUE';
export const TYPE_AHEAD_RESET = 'TYPE_AHEAD_RESET';
export const CHANGE_SEARCH_TERM_OBJECT = 'CHANGE_SEARCH_TERM_OBJECT';
export const CHANGE_SEARCH_TERM_QI = 'CHANGE_SEARCH_TERM_QI';
export const KNOWLEDGE = 'KNOWLEDGE';
export const BCG_INTERNAL = 'BCG_INTERNAL';
export const CASE = 'CASE';
export const PEOPLE = 'PEOPLE';

export const name = 'typeAhead';
export const getSearchTerm = () => (currentQueryStringToObj().query ? currentQueryStringToObj().query : '');
const sources = [];
export const initialState = { // should match whats in CONFIG.TYPEAHEAD
  MATERIAL: {
    results: [],
    hasResults: false,
    loading: false,
    error: false,
    errorMessage: ''
  },
  KNOWLEDGE: {
    results: [],
    hasResults: false,
    loading: false,
    error: false,
    errorMessage: ''
  },
  LAB: {
    results: [],
    hasResults: false,
    loading: false,
    error: false,
    errorMessage: ''
  },
  CASE: {
    results: [],
    hasResults: false,
    loading: false,
    error: false,
    errorMessage: ''
  },
  PEOPLE: {
    results: [],
    hasResults: false,
    loading: false,
    error: false,
    errorMessage: ''
  },
  searchTerm: getSearchTerm(),
  searchTermObject: {},
  matchValue: ''
};

export const selectors = {
  getFeedbackList: (state) => state[name].MATERIAL.results,
  getTypeAheadSearchObject: (state) => state[name].searchTermObject
};

const setPeopleDisplayValue = (employee) => {
  const title = employee.title && employee.title !== 'NULL' ? `- ${employee.title}` : '';
  const office = employee.hostOfficeLocation ? `| ${employee.hostOfficeLocation.name}` : '';
  const displayValue = `${employee.firstName} ${employee.lastName} ${title} ${office}`;
  return {
    ...employee,
    display: displayValue,
    category: CONFIG.TYPEAHEAD.PEOPLE.CATEGORY
  };
};

const formatTypeaheadSuccessPayload = (SERPType, results) => ({
  [SERPType]: {
    results,
    hasResults: results?.length > 0,
    loading: false,
    error: false,
    errorMessage: ''
  }
});

export const actions = {
  typeAheadSearch: (searchTerm) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const searchTermEncoded = encodeURIComponent(searchTerm);

    dispatch({ type: API_TYPE_AHEAD_PENDING });

    // KNOWLEDGE TYPE AHEAD
    try {
      axios.post(
        CONFIG.API_URL.KNOWLEDGE_TYPE_AHEAD(), 
        {
          [CONFIG.QUERY_PARAMS.QUERY]: searchTerm
        },
        { cancelToken: newSource.token }
      )
        .then((response) => {
          const results = response.items?.length > 0 ? getKnowledgeTypeAheadSuggestions(response.items) : [];
          const payload = formatTypeaheadSuccessPayload(KNOWLEDGE, results);
          dispatch({ type: API_TYPE_AHEAD_SUCCESS, payload });
        });
    } catch (error) {
      dispatch({ type: API_TYPE_AHEAD_FAILURE, payload: { KNOWLEDGE: { error: true, errorMessage: error.message } } });
    }

    // INTERNAL TYPE AHEAD
    if (CONFIG.FEATURE_TOGGLES.SHOW_BCG_INTERNAL_TAB) {
      try {
        axios.post(
          CONFIG.API_URL.INTERNAL_TYPE_AHEAD(),
          {
            [CONFIG.QUERY_PARAMS.QUERY]: searchTerm
          },
          { cancelToken: newSource.token }
        )
          .then((response) => {
            const results = response.items?.length > 0 ? getKnowledgeTypeAheadSuggestions(response.items) : [];
            const payload = formatTypeaheadSuccessPayload(BCG_INTERNAL, results);
            dispatch({ type: API_TYPE_AHEAD_SUCCESS, payload });
          });
      } catch (error) {
        dispatch({ type: API_TYPE_AHEAD_FAILURE, payload: { BCG_INTERNAL: { error: true, errorMessage: error.message } } });
      }
    }

    // PEOPLE TYPE AHEAD
    try {
      peopleResultsActions.peopleTypeAheadSearch(searchTermEncoded)
        .then((response) => {
          const results = response.data.searchFilter.employees.map((employee, index) => ({ ...setPeopleDisplayValue(employee), index }));
          const payload = formatTypeaheadSuccessPayload(PEOPLE, results);
          dispatch({ type: API_TYPE_AHEAD_SUCCESS, payload });
        });
    } catch (error) {
      dispatch({ type: API_TYPE_AHEAD_FAILURE, payload: { PEOPLE: { error: true, errorMessage: error.message } } });
    }

    // CASE TYPE AHEAD
    if (CONFIG.FEATURE_TOGGLES.SHOW_CASE_TAB) {
      try {
        axios.post(CONFIG.API_URL.CASE_TYPEAHEAD_V2(''),
          {
            [CONFIG.QUERY_PARAMS.QUERY]: searchTerm
          },
          { cancelToken: newSource.token })
          .then((response) => {
            const results = response.items && response.items.splice(0, CONFIG.TYPEAHEAD.CASE.MAX_LENGTH).map((item, index) => ({ ...item, index, display: item.display.toLowerCase() }));
            const payload = formatTypeaheadSuccessPayload(CASE, results);
            dispatch({ type: API_TYPE_AHEAD_SUCCESS, payload });
          });
      } catch (error) {
        dispatch({ type: API_TYPE_AHEAD_FAILURE, payload: { CASE: { error: true, errorMessage: error.message } } });
      }
    }
  },
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_TYPE_AHEAD_CANCELLED };
  },
  // searchTerm can be a string or an object
  changeSearchTerm: (searchTerm) => async (dispatch) => {
    if (searchTerm && searchTerm.display) {
      dispatch({ type: CHANGE_SEARCH_TERM_OBJECT, payload: { ...searchTerm } });
      dispatch({ type: CHANGE_SEARCH_TERM, payload: searchTerm.display });
    } else {
      dispatch({ type: CHANGE_SEARCH_TERM, payload: searchTerm });
    }
  },
  changeMatchValue: (searchTerm) => async (dispatch) => {
    dispatch({ type: CHANGE_MATCH_VALUE, payload: searchTerm });
  },
  resetState: () => async (dispatch) => dispatch({ type: TYPE_AHEAD_RESET })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_TYPE_AHEAD_PENDING:
      return {
        ...state,
        MATERIAL: {
          ...state.MATERIAL,
          loading: true
        },
        CASE: {
          ...state.CASE,
          loading: true
        },
        PEOPLE: {
          ...state.PEOPLE,
          loading: true
        }
      };
    case API_TYPE_AHEAD_FAILURE:
    case API_TYPE_AHEAD_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case CHANGE_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload
      };
    case CHANGE_SEARCH_TERM_QI: { // called from knowledge.js
      return {
        ...state,
        searchTerm: action.payload.query
      };
    }
    case CHANGE_SEARCH_TERM_OBJECT:
      return {
        ...state,
        searchTermObject: action.payload
      };
    case CHANGE_MATCH_VALUE:
      return {
        ...state,
        matchValue: action.payload
      };
    case TYPE_AHEAD_RESET:
      return initialState;
    default:
      return state;
  }
}