import PAGE_ANALYTICS from './page_analytics';

export default {
  async load() {
    const adobe = { bannerTrigger: 'load' };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Banner_Alert');
  },
  async openSite() {
    const adobe = { bannerTrigger: 'link' };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Banner_Alert');
  }
};