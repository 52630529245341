import React from 'react';
import { name as downloadName } from 'redux/api/downloadProgress/downloadProgress';
import { useSelector } from 'react-redux';
import { DownloadProgressModal } from './DownloadProgressModal/DownloadProgressModal';

export const DownloadProgress = () => {
  const { currentDownloads } = useSelector((state) => state[downloadName]);
  return (
    <>
      {currentDownloads && currentDownloads.length > 0 &&
      <DownloadProgressModal />}
    </>
  );
};