export function getFromLocalStorageAsArray(key) {
  return !(localStorage.getItem(key)) ? [] : JSON.parse(localStorage.getItem(key));
}

export function getFromLocalStorageAsJSON(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function getFromLocalStorage(key) {
  return localStorage.getItem(key);
}

export function sendJSONToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function sendToLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}