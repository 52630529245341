/* eslint-disable no-return-assign */
// TODO minerva-refiners
import CONFIG from 'config';
import qs from 'qs';
import moment from 'moment';
import { getAppliedFiltersArray } from 'utils/search/search';


export const getEntries = (data) => Object.keys(data).map((key) => [key, data[key]]);

export const parseQueryString = () => window.location.search.substring(1).split('&')
  .map((q) => q.split('=').map(decodeURIComponent))
  .reduce((a, [key, val]) => {
    if (Array.isArray(a[key])) {
      a[key].push(val);
    } else if (a[key]) {
      return { ...a, [key]: [a[key], val] };
    } else {
      return { ...a, [key]: val };
    }
    return a;
  }, {});

export const formatNumberThousands = (num) => (num > 999 ? `${Math.ceil((num / 1000) * 10) / 10}k` : new Intl.NumberFormat().format(num));
export const formatDownloadsNumber = (num) => (num > 99999 ? `${Math.ceil(num / 1000)}k` : new Intl.NumberFormat().format(num));

export const formatNumber = (num) => (new Intl.NumberFormat().format(num));

export const buildQueryString = (obj) => {
  const o = getEntries(obj)
    .filter(([key, val]) => (key !== undefined && val !== undefined))
    .map(([key, val]) => {
      let ret = { [key]: val };

      if (val[0] && typeof val[0] === 'object' && val[0].QUERY_PARAM) {
        ret = { [val[0].QUERY_PARAM]: val.map((v) => v.name) };
      }

      return ret;
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return qs.stringify(o, { indices: false });
};

export function queryObjToQueryString(query) {
  // qs adds array index to the query string. Need to remove them.
  return `?${qs.stringify(query).replace(/%5B([0-9]+)%5D/g, '')}`;
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/</g, '&lt;').replace(/>/g, '&gt;'); // $& means the whole matched string
}

export function queryStringToObj(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

export function currentQueryStringToObj() {
  const querySearch = window.location.search;
  return qs.parse(querySearch, { ignoreQueryPrefix: true });
}

export function convertValuetoArray(value) {
  // qs uses different data types depending on how many matching query params there are. ?industryPA=test1 will
  // give { industryPA: 'test1' }, but ?industryPA=test1&industryPA=test2 gives { industryPA: ['test1', 'test2'] }
  if (typeof value === 'string') {
    return [value];
  }
  return value;
}

export function currentQueryStringToObjArrays() {
  const queryObj = queryStringToObj(window.location.search);
  Object.keys(queryObj).forEach((key) => { queryObj[key] = convertValuetoArray(queryObj[key]); });
  return queryObj;
}

export const getAppliedFiltersFromQuery = (filters, sortParams, defaultSort, additionalQueryParams) => {
  const query = parseQueryString();
  const ret = {};

  Object.values(filters).forEach((val) => {
    if (query[val.QUERY_PARAM]) {
      ret[val.QUERY_PARAM] = query[val.QUERY_PARAM];
    }
  });

  Object.values({ ...CONFIG.QUERY_PARAMS, ...additionalQueryParams }).forEach((key) => {
    if (query[key] && !ret[key]) {
      ret[key] = query[key];
    }
  });

  if (sortParams) {
    if (defaultSort && !sortParams.map((s) => s.id).includes(ret[CONFIG.QUERY_PARAMS.SORTING_ORDER])) {
      ret[CONFIG.QUERY_PARAMS.SORTING_ORDER] = defaultSort;
    }
  } else {
    delete ret[CONFIG.QUERY_PARAMS.SORTING_ORDER];
  }

  return ret;
};

// From a stored set of appliedFilters and appliedQueryParams,
// which currently contains the full config parameters,
// build an object in the format:
// { filterKey1: ['value1', 'value2'], filterKey2: 'value'}
export const flattenQuery = (query) => {
  const ret = {};
  Object.keys(query).forEach((key) => {
    if (Array.isArray(query[key]) && query[key].every((child) => typeof child === 'object')) {
      ret[key] = query[key].map((k) => k.name);
    } else {
      ret[key] = query[key];
    }
  });

  return ret;
};

export function queryStringToQueryObj(queryString, FILTERS) {
  const queryObj = queryStringToObj(queryString);
  //  Decode the date revised if retrieved directly from query string
  if (queryObj?.dateRevised) {
    queryObj.dateRevised = decodeURIComponent(queryObj.dateRevised);
  }

  Object.values(FILTERS).forEach((FILTER_VALUE) => {
    const newValue = convertValuetoArray(queryObj[FILTER_VALUE.QUERY_PARAM]);
    if (newValue) queryObj[FILTER_VALUE.QUERY_PARAM] = newValue;
  });
  return queryObj;
}

export function currentQueryStringToQueryObj(filters) {
  return queryStringToQueryObj(window.location.search, filters);
}

export const camelToSnakeCase = (string = '') => string.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`);

export function queryWithSearchParamsOnly() {
  const q = currentQueryStringToQueryObj(CONFIG.FILTERS);
  return { // TODO map these from config instead of hardcoding
    [CONFIG.QUERY_PARAMS.QUERY]: q[CONFIG.QUERY_PARAMS.QUERY],
    [CONFIG.QUERY_PARAMS.SORTING_ORDER]: q[CONFIG.QUERY_PARAMS.SORTING_ORDER],
    [CONFIG.QUERY_PARAMS.RESULTS_PER_PAGE]: q[CONFIG.QUERY_PARAMS.RESULTS_PER_PAGE],
    [CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT]: q[CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT],
    [CONFIG.QUERY_PARAMS.SLIDES]: q[CONFIG.QUERY_PARAMS.SLIDES],
    [CONFIG.QUERY_PARAMS.MATERIAL_ID]: q[CONFIG.QUERY_PARAMS.MATERIAL_ID],
    [CONFIG.QUERY_PARAMS.ATTACHMENT_NAME]: q[CONFIG.QUERY_PARAMS.ATTACHMENT_NAME],
    [CONFIG.QUERY_PARAMS.ATTACHMENT_ID]: q[CONFIG.QUERY_PARAMS.ATTACHMENT_ID],
    [CONFIG.QUERY_PARAMS.DATE_RANGE]: q[CONFIG.QUERY_PARAMS.DATE_RANGE],
    [CONFIG.QUERY_PARAMS.AUTHOR]: q[CONFIG.QUERY_PARAMS.AUTHOR],
    [CONFIG.QUERY_PARAMS.FUNCTIONAL_PA]: q[CONFIG.QUERY_PARAMS.FUNCTIONAL_PA],
    [CONFIG.QUERY_PARAMS.INDUSTRY_PA]: q[CONFIG.QUERY_PARAMS.INDUSTRY_PA],
    [CONFIG.QUERY_PARAMS.MATERIAL_TYPE]: q[CONFIG.QUERY_PARAMS.MATERIAL_TYPE],
    [CONFIG.QUERY_PARAMS.DATE_OPENED]: q[CONFIG.QUERY_PARAMS.DATE_OPENED],
    [CONFIG.QUERY_PARAMS.DATE_CLOSED]: q[CONFIG.QUERY_PARAMS.DATE_CLOSED],
    [CONFIG.QUERY_PARAMS.DATEPUBLISHED]: q[CONFIG.QUERY_PARAMS.DATEPUBLISHED],
    [CONFIG.QUERY_PARAMS.STARTDATEPUBLISHED]: q[CONFIG.QUERY_PARAMS.STARTDATEPUBLISHED],
    [CONFIG.QUERY_PARAMS.ENDDATEPUBLISHED]: q[CONFIG.QUERY_PARAMS.ENDDATEPUBLISHED],
    [CONFIG.QUERY_PARAMS.STARTDATEOPENED]: q[CONFIG.QUERY_PARAMS.STARTDATEOPENED],
    [CONFIG.QUERY_PARAMS.ENDDATEOPENED]: q[CONFIG.QUERY_PARAMS.ENDDATEOPENED],
    [CONFIG.QUERY_PARAMS.DATEREVISED]: q[CONFIG.QUERY_PARAMS.DATEREVISED],
    [CONFIG.QUERY_PARAMS.DOCUPDATEDDATERANGE]: q[CONFIG.QUERY_PARAMS.DOCUPDATEDDATERANGE],
    [CONFIG.QUERY_PARAMS.STARTDATEREVISED]: q[CONFIG.QUERY_PARAMS.STARTDATEREVISED],
    [CONFIG.QUERY_PARAMS.ENDATEREVISED]: q[CONFIG.QUERY_PARAMS.ENDDATEREVISED],
    [CONFIG.QUERY_PARAMS.TRAININGANDTOOLS]: q[CONFIG.QUERY_PARAMS.TRAININGANDTOOLS],
    [CONFIG.QUERY_PARAMS.LANGUAGES]: q[CONFIG.QUERY_PARAMS.LANGUAGES],
    [CONFIG.QUERY_PARAMS.BCGOFFICENAME]: q[CONFIG.QUERY_PARAMS.BCGOFFICENAME],
    [CONFIG.QUERY_PARAMS.BCGREGIONCOUNTRYNAME]: q[CONFIG.QUERY_PARAMS.BCGREGIONCOUNTRYNAME],
    [CONFIG.QUERY_PARAMS.SUBJECT_PA]: q[CONFIG.QUERY_PARAMS.SUBJECT_PA]
  };
}

export function getLabQueryObj(brand, scope, query, autoCorrect = true) {
  let queryObj;
  const targetTab = 'lab';
  const appliedFilters = getAppliedFiltersArray(targetTab);
  const queryParams = `${encodeURIComponent(query)}&${CONFIG.QUERY_PARAMS.SORTING_ORDER}=${CONFIG.DEFAULT_SORT}`;
  let searchUrl = `${CONFIG.QUERY_PARAMS.QUERY}=${queryParams}&${CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT}=${autoCorrect}`;
  if (scope !== CONFIG.SEARCH.SCOPES.LAB) {
    if (query !== undefined) {
      if (appliedFilters.length === 0 || appliedFilters.length === undefined) {
        brand.DEFAULT_SEARCH_PARAMS.LAB.forEach((param) => {
          searchUrl += `&${param.PARAM}=${param.VALUE}`;
        });
      }
      appliedFilters.map((f) => searchUrl += `&${f.QUERY_PARAM}=${encodeURIComponent(f.name)}`);
    }
    queryObj = qs.parse(searchUrl, { ignoreQueryPrefix: true });
  } else if (!autoCorrect) {
    queryObj = qs.parse(searchUrl, { ignoreQueryPrefix: true });
  } else {
    queryObj = qs.parse(currentQueryStringToObj(), { ignoreQueryPrefix: true });
  }
  const labQueryObj = {};
  Object.values(CONFIG.QUERY_PARAMS).forEach((q) => {
    const queryValue = queryObj[q];
    if (queryObj[q] !== undefined) labQueryObj[q] = queryValue;
  });
  return labQueryObj;
}

export function htmlDecode(title) {
  const e = document.createElement('textarea');
  e.innerHTML = title;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function truncateString(title, length) {
  if (title.length > length) {
    return title.slice(0, length).concat('...');
  }
  return title;
}

export function truncateFileName(title, length) {
  const extension = title.substring(title.lastIndexOf('.'));
  const remainingText = title.substring(0, title.lastIndexOf('.'));
  const text = truncateString(remainingText, length);
  return text.concat(extension);
}

export function isNotNull(value) {
  return (value && (typeof value === 'string' && value.toLowerCase() === 'null')) ? null : value;
}

export function nullCheck(object, fn) {
  return (
    Object.keys(object).reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = fn(object[key]);
      return obj;
    }, {})
  );
}

export function cleanNulls(object) {
  return nullCheck(object, (value) => (isNotNull(value)));
}

export function getMonthDateYear(date) {
  return moment(date).format('MMM DD, YYYY');
}

export function getMonthYear(date) {
  return moment(date).format('MMMM YYYY');
}

export function decodeStringForColon(input) {
  return input.replace(/([^ ]):/g, '$1 :');
}

export const checkForColonDecoding = (searchTerm) => {
  const updatedSearchTerm = searchTerm ? searchTerm.trim() : '';
  if (updatedSearchTerm && (updatedSearchTerm.indexOf(':') === updatedSearchTerm.lastIndexOf(':')) && (updatedSearchTerm.match(new RegExp(':', 'g')) || []).length === 1) {
    return decodeStringForColon(updatedSearchTerm);
  }
  return updatedSearchTerm;
};

export const sortByNames = (a, b) => {
  const option1 = a.display?.toUpperCase() || a.name?.toUpperCase();
  const option2 = b.display?.toUpperCase() || b.name?.toUpperCase();
  if (option1 < option2) {
    return -1;
  }
  if (option1 > option2) {
    return 1;
  }
  return 0;
};

export function getFnameLname(name) {
  if (name && name.length > 0) {
    const nameArr = name.split(' ');
    nameArr.splice(nameArr.length - 1, 1);
    return nameArr.join(' ');
  }
  return name;
}

export const formatAppliedFilterDisplay = (text, maxLength = 50) => (text.length > maxLength ? `.../${text.split('/').pop()}` : text);

export const formatAppliedFreeTextFilterDisplay = (text, maxLength = 50) => (text.length > maxLength ? `${text.slice(0, maxLength - 1)}...` : text);

export const getCaseNumberFromSearchQuery = (string) => string.replace(/^(\d+-\d+)\s.*/, '$1');

export const getParameterValueFromUrl = (parameterName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has(parameterName)) {
    //could replace functionality for getAll instead (it returns an array, useful if the parameter is used multiple times)
    return decodeURIComponent(urlParams.get(parameterName));
  }
  else {
    return null;
  }
};

export const removeLineBreaksUri = (url) => {
  return encodeURI(decodeURI(url?.replaceAll(/[\r\n]/g, '')));
};

export const removeLineBreaksUriComponent = (params) => {
  return decodeURIComponent(encodeURIComponent(params?.replaceAll(/[\r\n]/g, '')));
};

// See http://pinyin.info/unicode/diacritics.html
export const diacriticCharacters = [
  // uppercase letters
  [/[\u00C1\u0102\u1EAE\u1EB6\u1EB0\u1EB2\u1EB4\u01CD\u00C2\u1EA4\u1EAC\u1EA6\u1EA8\u1EAA\u00C4\u1EA0\u00C0\u1EA2\u0100\u0104\u00C5\u01FA\u00C3\u00C6\u01FC]/g, 'a'],
  [/[\u1E04\u0181\u029A\u025E]/g, 'b'],
  [/[\u0106\u010C\u00C7\u0108\u010A\u0186\u0297]/g, 'c'],
  [/[\u010E\u1E12\u1E0C\u018A\u1E0E\u01F2\u01C5\u0110\u00D0\u01F1\u01C4]/g, 'd'],
  [/[\u00C9\u0114\u011A\u00CA\u1EBE\u1EC6\u1EC0\u1EC2\u1EC4\u00CB\u0116\u1EB8\u00C8\u1EBA\u0112\u0118\u1EBC\u0190\u018F]/g, 'e'],
  [/[\u0191]/g, 'f'],
  [/[\u01F4\u011E\u01E6\u0122\u011C\u0120\u1E20\u029B]/g, 'g'],
  [/[\u1E2A\u0124\u1E24\u0126]/g, 'h'],
  [/[\u00CD\u012C\u01CF\u00CE\u00CF\u0130\u1ECA\u00CC\u1EC8\u012A\u012E\u0128\u0132]/g, 'i'],
  [/[\u0134]/g, 'j'],
  [/[\u0136\u1E32\u0198\u1E34]/g, 'k'],
  [/[\u0139\u023D\u013D\u013B\u1E3C\u1E36\u1E38\u1E3A\u013F\u01C8\u0141\u01C7]/g, 'l'],
  [/[\u1E3E\u1E40\u1E42]/g, 'm'],
  [/[\u0143\u0147\u0145\u1E4A\u1E44\u1E46\u01F8\u019D\u1E48\u01CB\u00D1\u01CA]/g, 'n'],
  [/[\u00D3\u014E\u01D1\u00D4\u1ED0\u1ED8\u1ED2\u1ED4\u1ED6\u00D6\u1ECC\u0150\u00D2\u1ECE\u01A0\u1EDA\u1EE2\u1EDC\u1EDE\u1EE0\u014C\u019F\u01EA\u00D8\u01FE\u00D5\u0152\u0276]/g, 'o'],
  [/[\u00DE]/g, 'p'],
  // [/[]/g, 'q'],
  [/[\u0154\u0158\u0156\u1E58\u1E5A\u1E5C\u1E5E\u0281]/g, 'r'],
  [/[\u015A\u0160\u015E\u015C\u0218\u1E60\u1E62\u1E9E]/g, 's'],
  [/[\u0164\u0162\u1E70\u021A\u1E6C\u1E6E\u0166\u00DE\u00D0]/g, 't'],
  [/[\u00DA\u016C\u01D3\u00DB\u00DC\u01D7\u01D9\u01DB\u01D5\u1EE4\u0170\u00D9\u1EE6\u01AF\u1EE8\u1EF0\u1EEA\u1EEC\u1EEE\u016A\u0172\u016E\u0168]/g, 'u'],
  // [/[]/g, 'v'],
  [/[\u1E82\u0174\u1E84\u1E80\u02AC]/g, 'w'],
  // [/[]/g, 'x'],
  [/[\u00DD\u0176\u0178\u1E8E\u1EF4\u1EF2\u01B3\u1EF6\u0232\u1EF8]/g, 'y'],
  [/[\u0179\u017D\u017B\u1E92\u1E94\u01B5]/g, 'z'],

  // lowercase letters
  [/[\u00E1\u0103\u1EAF\u1EB7\u1EB1\u1EB3\u1EB5\u01CE\u00E2\u1EA5\u1EAD\u1EA7\u1EA9\u1EAB\u00E4\u1EA1\u00E0\u1EA3\u0101\u0105\u00E5\u01FB\u00E3\u00E6\u01FD\u0251\u0250\u0252]/g, 'a'],
  [/[\u1E05\u0253\u00DF]/g, 'b'],
  [/[\u0107\u010D\u00E7\u0109\u0255\u010B]/g, 'c'],
  [/[\u010F\u1E13\u1E0D\u0257\u1E0F\u0111\u0256\u02A4\u01F3\u02A3\u02A5\u01C6\u00F0]/g, 'd'],
  [/[\u00E9\u0115\u011B\u00EA\u1EBF\u1EC7\u1EC1\u1EC3\u1EC5\u00EB\u0117\u1EB9\u00E8\u1EBB\u0113\u0119\u1EBD\u0292\u01EF\u0293\u0258\u025C\u025D\u0259\u025A\u029A\u025E]/g, 'e'],
  [/[\u0192\u017F\u02A9\uFB01\uFB02\u0283\u0286\u0285\u025F\u0284]/g, 'f'],
  [/[\u01F5\u011F\u01E7\u0123\u011D\u0121\u0260\u1E21\u0261\u0263]/g, 'g'],
  [/[\u1E2B\u0125\u1E25\u0266\u1E96\u0127\u0267\u0265\u02AE\u02AF\u0173]/g, 'h'],
  [/[\u00ED\u012D\u01D0\u00EE\u00EF\u1ECB\u00EC\u1EC9\u012B\u012F\u0268\u0129\u0269\u0131\u0133\u025F]/g, 'i'],
  [/[\u01F0\u0135\u029D\u0237\u025F\u0284]/g, 'j'],
  [/[\u0137\u1E33\u0199\u1E35\u0138\u029E]/g, 'k'],
  [/[\u013A\u019A\u026C\u013E\u013C\u1E3D\u1E37\u1E39\u1E3B\u0140\u026B\u026D\u0142\u019B\u026E\u01C9\u02AA\u02AB]/g, 'l'],
  [/[\u1E3F\u1E41\u1E43\u0271\u026F\u0270]/g, 'm'],
  [/[\u0149\u0144\u0148\u0146\u1E4B\u1E45\u1E47\u01F9\u0272\u1E49\u0273\u00F1\u01CC\u014B\u014A]/g, 'n'],
  [/[\u00F3\u014F\u01D2\u00F4\u1ED1\u1ED9\u1ED3\u1ED5\u1ED7\u00F6\u1ECD\u0151\u00F2\u1ECF\u01A1\u1EDB\u1EE3\u1EDD\u1EDF\u1EE1\u014D\u01EB\u00F8\u01FF\u00F5\u025B\u0254\u0275\u0298\u0153]/g, 'o'],
  [/[\u0278\u00FE]/g, 'p'],
  [/[\u02A0]/g, 'q'],
  [/[\u0155\u0159\u0157\u1E59\u1E5B\u1E5D\u027E\u1E5F\u027C\u027D\u027F\u0279\u027B\u027A]/g, 'r'],
  [/[\u015B\u0161\u015F\u015D\u0219\u1E61\u1E63\u0282\u017F\u0283\u0286\u00DF\u0285]/g, 's'],
  [/[\u0165\u0163\u1E71\u021B\u1E97\u1E6D\u1E6F\u0288\u0167\u02A8\u02A7\u00FE\u00F0\u02A6\u0287]/g, 't'],
  [/[\u0289\u00FA\u016D\u01D4\u00FB\u00FC\u01D8\u01DA\u01DC\u01D6\u1EE5\u0171\u00F9\u1EE7\u01B0\u1EE9\u1EF1\u1EEB\u1EED\u1EEF\u016B\u0173\u016F\u0169\u028A]/g, 'u'],
  [/[\u028B\u028C]/g, 'v'],
  [/[\u1E83\u0175\u1E85\u1E81\u028D]/g, 'w'],
  // /[]/g, 'x'],
  [/[\u00FD\u0177\u00FF\u1E8F\u1EF5\u1EF3\u01B4\u1EF7\u0233\u1EF9\u028E]/g, 'y'],
  [/[\u017A\u017E\u0291\u017C\u1E93\u1E95\u0290\u01B6]/g, 'z'],
];

// const matchesQuery = (option, query) => (option.display || option.name).toLowerCase().indexOf(query.replace(/[^a-zA-Z0-9\s&-@,:]/gi, '').toLowerCase()) > -1;
export const matchesQuery = (displayOrName, query) => {
  const regExpOthersToBlank = /[^a-zA-Z0-9\s&-@,:]/gi;

  return diacriticCharacters.reduce((previousValue, currentValue, currentIndex, collection) => {
    const [regularExpression, replacingCharacter] = currentValue;
    return previousValue.replace(regularExpression, replacingCharacter);
  }, displayOrName).replace(regExpOthersToBlank, '').toLowerCase().indexOf(
    diacriticCharacters.reduce((previousValue, currentValue, currentIndex, collection) => {
      const [regularExpression, replacingCharacter] = currentValue;
      return previousValue.replace(regularExpression, replacingCharacter);
    }, query).replace(regExpOthersToBlank, '').toLowerCase()
  ) > -1;
};

export const getRandomString = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  return randomString;
};