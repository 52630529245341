import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { Row, Col } from 'components/FlexBoxGrid';

import Loader from 'components/Loader/Loader';
import Layout from 'containers/Layout/Layout';

import './Loading.scss';

const LoadingComponent = () => {
  useEffect(() => {
    // see if datadog will work here
  }, []);

  return (
    <Layout id="loading" justLayout>
      <Grid>
        <Row className="loading-header">
          <Col />
        </Row>
        <Row>
          <Col className="loading-wrapper">
            <Loader size="large" active>Loading</Loader>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

export default LoadingComponent;
