import styled from 'styled-components';

export const ProposalBuilder = styled.div`
  margin-top: 57px;
  border-top: 2px solid ${({ theme }) => theme.COLORS.$grey};
  background-color: ${({ theme }) => theme.COLORS.$black200};
  display: flex;

  .leftbar{
    padding: 14px 20px 20px 20px;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
    width: 236px;
    position: relative;
  }

  .main{
    background: white;
    flex: 80%;
    min-height:calc(100vh - 59px);
  }

  .wrapper{
    padding: 20px;
    display: flex;
    height: calc(100% - 60px);
  }

  .leftsec, .rightsec{
    height: 100%;
    position: relative;
    flex-basis: 50%;
  }

  .leftsec{
    margin: 0 15px 0 0;
  }

  .rightsec{
    border-left: 2px solid #000;
    padding: 20px 35px;
    flex-basis: 55%;
  }
`;

export const Banner = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  color: black;
  font-weight: 600;
  justify-content: center;
  white-space: pre-line;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.NEW.grays.gray300};


  .bannerText {
    display: flex;
    align-items: center;
    flex-direction: column;
  };

  .betaTag {
    line-height: ${({ theme }) => theme.SIZE(27)};
    color: ${({ theme }) => theme.COLORS.$white};
    margin-right: 12px;
    padding: ${({ theme }) => theme.SIZE(8)};
    border-radius: ${({ theme }) => theme.SIZE(7)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 33px;
    font-family: HendersonBCGSans-Regular, Arial, Helvetica, sans-serif;
    background-color: #10A7A0;
  }
`;