import axios from 'axios';
import CONFIG from 'config';

export const API_RESUME_PENDING = 'API_RESUME_PENDING';
export const API_RESUME_SUCCESS = 'API_RESUME_SUCCESS';
export const API_RESUME_FAILURE = ' API_RESUME_FAILURE';

export const name = 'resumes';

const initialState = {
  profileResume: [],
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getResume: (state) => state[name].profileResume};


export const actions = {
  fetchResume: (hrId) => async (dispatch) => {
    dispatch({ type: API_RESUME_PENDING, payload: { loading: true } });
    try {
      const response = await axios.get(
        CONFIG.API_URL.RESUME(hrId),
        {
          headers: {
            'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY,           
          },         
        });
      dispatch({ type: API_RESUME_SUCCESS, payload:{hrId, response }});
    } catch (error) {
      
      dispatch({ type: API_RESUME_FAILURE, payload:{hrId, error: error.message  }});
    }
    
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_RESUME_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_RESUME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: 'error'
      };
    case API_RESUME_SUCCESS:
      return {
        ...state,
        loading: false,

        profileResume:{
          ...state.profileResume,
          [action.payload.hrId]:action.payload.response
        } 
        
      };
    default:
      return state;
  }
}