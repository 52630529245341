import styled from 'styled-components';

export const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => theme.COLORS.$black};
    opacity: 0.5;
`;

export const Modal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 101;

    .modalWrapper {
        width: 27%;
        padding: 18px 26px;
        background: ${({ theme }) => theme.COLORS.$graylight};
        color: ${({ theme }) => theme.COLORS.$graydarkest};
    }

    .heading {
        font-size ${({ theme }) => theme.SIZE(14)};;
        font-weight: 700;
        line-height: ${({ theme }) => theme.SIZE(20)};
        color: ${({ theme }) => theme.COLORS.$graydarkest};
    }
    .body {

    }
    .buttonWrapper {
        text-align: center;
    }
    .button {
        font-size ${({ theme }) => theme.SIZE(12)};
        font-weight: 700;
        line-height: ${({ theme }) => theme.SIZE(20)};
        border: 2px solid ${({ theme }) => theme.COLORS.$graydarkest};
        text-transform: uppercase;
        padding: 5px 15px;
        cursor: pointer;
    }
    .buttonApproved {
        background: ${({ theme }) => theme.COLORS.$bcggreen};
        border: 2px solid ${({ theme }) => theme.COLORS.$bcggreen};
        color: ${({ theme }) => theme.NEW.white};
    }
    .ml10 {
        margin-left: 20px;
    }
`;