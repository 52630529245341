export const BREAKPOINTS = {
  XS: {
    MIN: 0,
    MAX: 575,
  },
  SM: {
    MIN: 576,
    MAX: 666,
  },
  MD: {
    MIN: 667,
    MAX: 1023,
  },
  LG: {
    MIN: 1024,
    MAX: 1199,
  },
  XL: { MIN: 1200 },
};

export function isSmall() {
  return window.matchMedia('(max-width: 767px)').matches;
}

export function isMedium() {
  return (
    window.matchMedia('(min-width: 768px)').matches &&
    window.matchMedia('(max-width: 992px)').matches
  );
}

export function isSmallOrMedium() {
  return isSmall() || isMedium();
}

export function isXS() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.XS.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.XS.MAX}px)`).matches
  );
}

export function isSM() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.SM.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.SM.MAX}px)`).matches
  );
}

export function isMD() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.MD.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.MD.MAX}px)`).matches
  );
}

export function isLG() {
  return (
    window.matchMedia(`(min-width: ${BREAKPOINTS.LG.MIN}px)`).matches &&
    window.matchMedia(`(max-width: ${BREAKPOINTS.LG.MAX}px)`).matches
  );
}

export function isXL() {
  return window.matchMedia(`(min-width: ${BREAKPOINTS.XL.MIN}px)`).matches;
}

export function isSafari() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(ua);
  return isSafariBrowser;
}

export function isIos() {
  return /(iPad|iPhone|iPod)/g.test(navigator.userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export function isAndroid() {
  return /Android|CrKey/g.test(navigator.userAgent);
}

export function isMobileSafari() {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  return ua.match(/(iPod|iPhone|iPad)/) && ua.match(/AppleWebKit/);
}

export function canGoToProfile() {
  if (isIos()) {
    return false;
  }
  return true;
}