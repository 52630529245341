import axios from 'axios';

export const API_IMAGE_PENDING = 'API_IMAGE_PENDING';
export const API_IMAGE_SUCCESS = 'API_IMAGE_SUCCESS';
export const API_IMAGE_FAILURE = 'API_IMAGE_FAILURE';

export const name = 'images';

const sources = [];
const promiseBackingStore = {};
const initialState = {
  images: {},
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getImageData: (state) => state[name].images };

export const actions = {
  fetchImages: (arrayOfImageUrls) => async (dispatch) => {
    arrayOfImageUrls.forEach(async (url) => {
      if (promiseBackingStore[url]) return;
      promiseBackingStore[url] = true;

      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      dispatch({ type: API_IMAGE_PENDING });

      try {
        const response = await axios.get(url, { responseType: 'arraybuffer', cancelToken: newSource.token });

        const byteSequence = new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), '');
        const imageData = `data:image/png;base64,${btoa(byteSequence)}`;
        dispatch({ type: API_IMAGE_SUCCESS, payload: { url, imageData } });
      } catch (error) {
        delete promiseBackingStore[url];
        dispatch({ type: API_IMAGE_FAILURE, payload: { url, error: error.message } });
      }
    });
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_IMAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error,
        images: {
          ...state.images,
          [action.payload.url]: 'error'
        }
      };
    case API_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: {
          ...state.images,
          [action.payload.url]: action.payload.imageData
        }
      };
    default:
      return state;
  }
}
