import CONFIG from 'config';
import axios from 'axios';
import { buildQueryString, escapeRegExp, flattenQuery, getLabQueryObj, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';
import { setAppliedFilters, formatFilters } from 'utils/filters/filters';

export const name = 'lab';

export const API_LAB_SEARCH_PENDING = 'API_LAB_SEARCH_PENDING';
export const API_LAB_SEARCH_FAILURE = 'API_LAB_SEARCH_FAILURE';
export const API_LAB_SEARCH_SUCCESS = 'API_LAB_SEARCH_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const API_LAB_LOAD_MORE_PENDING = 'API_LAB_LOAD_MORE_PENDING';
export const API_LAB_LOAD_MORE_FAILURE = 'API_LAB_LOAD_MORE_FAILURE';
export const API_LAB_LOAD_MORE_SUCCESS = 'API_LAB_LOAD_MORE_SUCCESS';
export const API_SLIDE_LEVEL_SEARCH_PENDING = 'API_SLIDE_LEVEL_SEARCH_PENDING';
export const API_SLIDE_LEVEL_SEARCH_FAILURE = 'API_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_SLIDE_LEVEL_SEARCH_SUCCESS';
export const API_INIT_SLIDE_LEVEL_SEARCH_PENDING = 'API_INIT_SLIDE_LEVEL_SEARCH_PENDING';
export const API_INIT_SLIDE_LEVEL_SEARCH_FAILURE = 'API_INIT_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS';
export const SHOW_DOC_VIZ = 'SHOW_DOC_VIZ';
export const CLOSE_DOC_VIZ = 'CLOSE_DOC_VIZ';
export const LOADING_DOC_VIZ = 'LOADING_DOC_VIZ';
export const SET_NEW_SEARCH_TRIGGERED = 'SET_NEW_SEARCH_TRIGGERED';
export const SET_FRESH_PAGE_LOAD = 'SET_FRESH_PAGE_LOAD';
export const SET_LAB_APPLIED_FILTERS_PENDING = 'SET_LAB_APPLIED_FILTERS_PENDING';
export const SET_LAB_APPLIED_FILTERS_SUCCESS = 'SET_LAB_APPLIED_FILTERS_SUCCESS';
export const SET_LAB_APPLIED_FILTERS_FAILED = 'SET_LAB_APPLIED_FILTERS_FAILED';
export const SET_LAB_ACTIVE_FILTER_INDEX = 'SET_LAB_ACTIVE_FILTER_INDEX';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const SET_LAB_APPLIED_FILTERS_ARRAY = 'SET_LAB_APPLIED_FILTERS_ARRAY';
export const SET_LAB_AUTO_CORRECT_STATE = 'SET_LAB_AUTO_CORRECT_STATE';

const sources = [];
const initialState = {
  loading: true,
  loadMorePending: false,
  error: false,
  errorMessage: '',
  empty: false,
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  results: [],
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  filters: [],
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  activeFilter: '',
  query: queryWithSearchParamsOnly(),
  appliedFiltersArray: [],
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getLoadMoreStatus: (state) => state[name].loadMorePending,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query
};

const labSearch = (reload, brand, scope, query, enableAutoCorrect) => async (dispatch) => {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  const labQuery = getLabQueryObj(brand, scope, query.query, enableAutoCorrect);

  const searchQuery = flattenQuery(query);
  if (!searchQuery.query) {
    searchQuery.query = '';
  }

  dispatch({ type: API_LAB_SEARCH_PENDING, payload: { reload, query: labQuery } });

  try {
    const data = await axios.get(CONFIG.API_URL.LAB_SEARCH(''),
      { params: searchQuery, cancelToken: newSource.token, paramsSerializer: buildQueryString });
    const filtersFromAPIAndConfig = formatFilters(data?.refiners, CONFIG.LAB_FILTERS.FILTER_BAR);

    const payload = {
      ...data,
      query: labQuery,
      results: data.results,
      filters: filtersFromAPIAndConfig,
      autoCorrect: {
        isSearchTermCorrected: false,
        correctedSearchTerm: ''
      }
    };
    if (data.didYouMeans && Array.isArray(data.didYouMeans)) {
      payload.autoCorrect.isSearchTermCorrected = data.didYouMeans.length > 0;
      payload.autoCorrect.correctedSearchTerm  = data.didYouMeans.reduce(
        (accumulator, currentValue) => {
          return accumulator.replace(new RegExp(`(${escapeRegExp(currentValue.form)})`, 'g') , currentValue.correction);
        },
        query.query
      );
    }

    dispatch({
      type: API_LAB_SEARCH_SUCCESS,
      payload
    });
  } catch (error) {
    if (error.cancelled) return;
    dispatch({ type: API_LAB_SEARCH_FAILURE, payload: error.toString() });
    throw error;
  }
};

export const actions = {
  labSearch,
  loadMoreFromLabSearch: (query, currentPage) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const loadMoreQuery = flattenQuery(query);
    if (!loadMoreQuery.query) {
      loadMoreQuery.query = '';
    }
    loadMoreQuery.resultsFromPage = parseInt(currentPage, 10) + CONFIG.DEFAULT_CURRENT_PAGE;

    dispatch({
      type: API_LAB_LOAD_MORE_PENDING,
      payload: {
        query: loadMoreQuery
      }
    });

    try {
      const data = await axios.get(
        CONFIG.API_URL.LAB_SEARCH(''),
        { params: loadMoreQuery, cancelToken: newSource.token, paramsSerializer: buildQueryString }
      );
      dispatch({
        type: API_LAB_LOAD_MORE_SUCCESS,
        payload: {
          ...data,
          query: loadMoreQuery
        }
      });
    } catch (error) {
      dispatch({
        type: API_LAB_LOAD_MORE_FAILURE,
        payload: error.message
      });
      throw error;
    }
  },
  setAppliedFiltersArray: (filtersArray) => async (dispatch) => dispatch({ type: SET_LAB_APPLIED_FILTERS_ARRAY, payload: filtersArray }),
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_LAB_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, CONFIG.LAB_FILTERS.FILTER_BAR, SET_LAB_APPLIED_FILTERS_PENDING, SET_LAB_APPLIED_FILTERS_SUCCESS, SET_LAB_APPLIED_FILTERS_FAILED),
  setAutoCorrectMode: (payload) => async (dispatch) => dispatch({ type: SET_LAB_AUTO_CORRECT_STATE, payload })

};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_SLIDE_LEVEL_SEARCH_PENDING:
    case API_INIT_SLIDE_LEVEL_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case API_LAB_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_INIT_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_LAB_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_LAB_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        empty: action.payload.results.length <= 0,
        results: action.payload.results,
        totalCount: action.payload.totalCount,
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        filters: action.payload.filters,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        autoCorrect: action.payload.autoCorrect
      };
    case API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS:
    case API_SLIDE_LEVEL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case API_LAB_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        error: false,
        errorMessage: '',
        empty: false
      };
    case API_LAB_LOAD_MORE_FAILURE:
      return {
        ...state,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload
      };
    case API_LAB_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        results: state.results.concat(action.payload.results),
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case SET_LAB_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_LAB_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_LAB_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_LAB_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_LAB_APPLIED_FILTERS_ARRAY:
      return {
        ...state,
        appliedFiltersArray: action.payload
      };
    case SET_LAB_AUTO_CORRECT_STATE:
      return {
        ...state,
        autoCorrectMode: action.payload
      };
    default:
      return state;
  }
}