// this little nugget is needed because the people API has no records for
// our test accounts, so we mock the data we need to test against in QA/STG
export const testAccountsIds = [
  {
    firstName: 'Test',
    lastName: 'One',
    hostOfficeLocation: {
      name: 'ACC - Boston'
    },
    hostOfficeRegion: 'NAMR',
    id: '999999980'
  },
  {
    firstName: 'Test',
    lastName: 'Two',
    hostOfficeRegion: 'NAMR',
    id: '999999999',
    hostOfficeLocation: {
      name: 'ACC - Boston'
    },
  },
  {
    firstName: 'Test',
    lastName: 'Three',
    id: '999999981',
    hostOfficeLocation: {
      name: 'ACC - Boston'
    },
  },
  {
    firstName: 'Test',
    lastName: 'Four',
    hostOfficeLocation: {
      name: 'Beijing'
    },
    hostOfficeRegion: 'AP',
    id: '999999982'
  },
  {
    firstName: 'Test',
    lastName: 'Five',
    hostOfficeLocation: {
      name: 'Dubai',
    },
    hostOfficeRegion: 'EMESA',
    id: '999999983'
  },
  {
    firstName: 'Test',
    lastName: 'Six',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999984'
  },
  {
    firstName: 'Test',
    lastName: 'Seven',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999985'
  },
  {
    firstName: 'Test',
    lastName: 'Eight',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999986'
  },
  {
    firstName: 'Test',
    lastName: 'Nine',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999987'
  },
  {
    firstName: 'Test',
    lastName: 'Ten',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999988'
  },
  {
    firstName: 'Test',
    lastName: 'Eleven',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999989'
  },
  {
    firstName: 'Test',
    lastName: 'Twelve',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999990'
  },
  {
    firstName: 'Test',
    lastName: 'Thirteen',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999991'
  },
  {
    firstName: 'Test',
    lastName: 'Fourteen',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999981'
  },
  {
    firstName: 'Test',
    lastName: 'Fifteen',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999982'
  },
  {
    firstName: 'Test',
    lastName: 'Sixteen',
    hostOfficeLocation: {
      name: 'Beijing',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999983'
  },
  {
    firstName: 'Test',
    lastName: 'Seventeen',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999984'
  },
  {
    firstName: 'Test',
    lastName: 'Eighteen',
    hostOfficeLocation: {
      name: 'Boston',
    },
    hostOfficeRegion: 'NAMR',
    id: '999999985'
  }
];