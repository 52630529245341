import CONFIG from 'config';

export const name = 'microsites';
export const MICROSITES_PENDING = 'MICROSITES_PENDING';
export const MICROSITES_FAILURE = 'MICROSITES_FAILURE';
export const MICROSITES_FETCH_AND_FILTER_SUCCESS = 'MICROSITES_FETCH_AND_FILTER_SUCCESS';

const initialState = {
  microsites: [],
  loading: false,
  error: false,
  errorMessage: '',
};

export const actions = {
  getMicrosites: (query) => async (dispatch) => {
    dispatch({ type: MICROSITES_PENDING, query });
    try {
      const allMicrosites = await fetch(`${CONFIG.API_URL.MICROSITES}`)
        .then((response) => response.json())
        .then((data) => data);
      dispatch({ type: MICROSITES_FETCH_AND_FILTER_SUCCESS, payload: allMicrosites.map((m) => ({ ...m, searchKeywords: m.keywords })).sort((a, b) => (a.title > b.title ? 1 : -1)) });
    } catch (error) {
      dispatch({ type: MICROSITES_FAILURE, payload: error.message });
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case MICROSITES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case MICROSITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case MICROSITES_FETCH_AND_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        microsites: action.payload
      };
    default:
      return state;
  }
}
