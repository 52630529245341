import { selectors as knowledgeSelectors } from 'redux/api/knowledge/knowledge';
import { selectors as searchSelectors } from 'redux/api/search/search';
import store from 'redux/store';
import CONFIG from 'config';
import PAGE_ANALYTICS from './page_analytics';
import { EMPTY_QUERY,
  NO_VAR,
  NO_TYPEAHEAD,
  TYPEAHEAD_SUGGESTION_MATCH,
  TYPEAHEAD_MATERIAL_SEARCH,
  TYPEAHEAD_PEOPLE_SEARCH,
  TYPEAHEAD_CASE_SEARCH,
  TYPEAHEAD_AUTHOR,
  TYPEAHEAD_ACRONYM,
  TYPEAHEAD_FILENAME,
  TYPEAHEAD_RECENT_SEARCH,
  RECENT_ACTIVITY_RECENT_SEARCH,
  SPELL_CHECK_REJECT,
  SPELL_CHECK_ACCEPT,
  EXTERNAL_SOURCE } from './analytics_constants';

export default {
  async searchTriggered(searchTerm, searchTrigger, typeAheadOptions = NO_VAR, typeAheadSuggestion, typeaheadOptionsPeople, typeaheadOptionsCases) {
    const { query: prevQuery } = knowledgeSelectors.getPreviousSearch(store.getState());
    let searchScope = searchSelectors.getSearchScope(store.getState());
    const isPeopleSearch = searchTrigger === TYPEAHEAD_PEOPLE_SEARCH;
    searchScope = isPeopleSearch ? CONFIG.TYPEAHEAD.PEOPLE.CATEGORY : searchScope;
    const term = searchTerm.length === 0 ? EMPTY_QUERY : searchTerm;
    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = {
      search: {
        // eslint-disable-next-line no-nested-ternary
        previousTerm: prevQuery.query !== undefined ? (prevQuery.emptyQuery ? EMPTY_QUERY : prevQuery.query) : NO_VAR,
        typeHeadOptionText: typeAheadSuggestion && typeAheadSuggestion.display,
        typeHeadOptionRank: typeAheadSuggestion && typeAheadSuggestion.index + 1,
        term: term === '' ? NO_VAR : term,
        searchTrigger,
        typeHeadOptions: typeAheadOptions === '' ? NO_VAR : typeAheadOptions,
        type: searchScope !== '' ? searchScope : NO_VAR,
        typeHeadOptionPeople: typeaheadOptionsPeople === '' || typeaheadOptionsPeople === undefined ? NO_VAR : typeaheadOptionsPeople,
        typeHeadOptionCase: typeaheadOptionsCases === '' || typeaheadOptionsCases === undefined ? NO_VAR : typeaheadOptionsCases,
        toolName: NO_VAR
      }
    };
    window.digitalData = Object.assign(window.digitalData || {}, adobe);
  },
  async searchInputEnterKey(searchTerm) {
    // the type ahead api events and search events are separate
    // if the search is triggered fast enough (copy-paste), the type ahead api calls may not complete before the search calls
    // delay the analytics call in order to allow type ahead actions to complete
    setTimeout(() => {
      const typeaheadIsLoading = store.getState().typeAhead.KNOWLEDGE.loading;
      const typeaheadOptions = store.getState().typeAhead.KNOWLEDGE.results;
      const searchMatches = typeaheadOptions.find((ta) => (ta.display.toLowerCase() === searchTerm.toLowerCase()));
      const typeAheadString = typeaheadIsLoading ? NO_VAR : typeaheadOptions.map((t) => t.display).join('|');

      if (searchMatches) {
        return this.searchTriggered(searchTerm, TYPEAHEAD_SUGGESTION_MATCH, typeAheadString);
      }

      return this.searchTriggered(searchTerm, NO_TYPEAHEAD, typeAheadString);
    }, 3000);
  },
  async searchInputTypeaheadOption(searchTerm, typeAheadSuggestion) {
    const typeaheadIsLoading = store.getState().typeAhead.KNOWLEDGE.loading;
    const typeaheadOptions = store.getState().typeAhead.KNOWLEDGE.results;
    const typeAheadString = typeaheadIsLoading || typeaheadOptions === undefined ? NO_VAR : typeaheadOptions.map((t) => t.display).join('|');
    const typeaheadIsLoadingPeople = store.getState().typeAhead.PEOPLE.loading;
    const typeaheadOptionsPeople = store.getState().typeAhead.PEOPLE.results;
    const typeAheadStringPeople = typeaheadIsLoadingPeople || typeaheadOptionsPeople === undefined ? NO_VAR : typeaheadOptionsPeople.map((t) => t.display).join('|');
    const typeaheadIsLoadingCases = store.getState().typeAhead.CASE.loading;
    const typeaheadOptionsCases = store.getState().typeAhead.CASE.results;
    const typeAheadStringCases = typeaheadIsLoadingCases || typeaheadOptionsCases === undefined ? NO_VAR : typeaheadOptionsCases.map((t) => t.display).join('|');
    let typeAheadCategory;
    switch (typeAheadSuggestion.category) {
      case CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.FILENAME.VALUE:
        typeAheadCategory = TYPEAHEAD_FILENAME;
        break;
      case CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.CONCEPT.VALUE:
        typeAheadCategory = TYPEAHEAD_MATERIAL_SEARCH;
        break;
      case CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.ACRONYM.VALUE:
        typeAheadCategory = TYPEAHEAD_ACRONYM;
        break;
      case CONFIG.TYPEAHEAD.KNOWLEDGE.CATEGORIES.AUTHOR.VALUE:
        typeAheadCategory = TYPEAHEAD_AUTHOR;
        break;
      case CONFIG.TYPEAHEAD.PEOPLE.CATEGORY:
        typeAheadCategory = TYPEAHEAD_PEOPLE_SEARCH;
        break;
      default:
        typeAheadCategory = '';
        break;
    }
    if (!typeAheadCategory && CONFIG.TYPEAHEAD.CASE.CATEGORY.indexOf(typeAheadSuggestion.category) > -1) {
      typeAheadCategory = TYPEAHEAD_CASE_SEARCH;
    }
    return this.searchTriggered(searchTerm, typeAheadCategory, typeAheadString, typeAheadSuggestion, typeAheadStringPeople, typeAheadStringCases); // typeaheadSelectors.getResults(store.getState()).map(t => t.display).join('|'));
  },
  async searchInputRecentSearch(searchTerm) {
    return this.searchTriggered(searchTerm, TYPEAHEAD_RECENT_SEARCH);
  },
  async recentActivityRecentSearch(searchTerm) {
    return this.searchTriggered(searchTerm, RECENT_ACTIVITY_RECENT_SEARCH);
  },
  async spellCheckReject(searchTerm) {
    return this.searchTriggered(searchTerm, SPELL_CHECK_REJECT);
  },
  async spellCheckAccept(searchTerm) {
    return this.searchTriggered(searchTerm, SPELL_CHECK_ACCEPT);
  },
  async resultsPageExternalSource(searchTerm) {
    return this.searchTriggered(searchTerm, EXTERNAL_SOURCE);
  }
};