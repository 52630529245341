// eslint-disable-next-line import/no-cycle
import store from 'redux/store';
import CONFIG from 'config';
import { NO_VAR, EMPTY_QUERY } from './analytics_constants';

let caseDefaults;

export default {
  getDefaultQueryProps() {
    const {
      search: { profiles }
    } = store.getState();

    if (caseDefaults) {
      caseDefaults.sinequaProfile = profiles || NO_VAR;
    }

    return caseDefaults;
  },
  setDefaultQueryProps(brand) {
    const {
      // router: { location: { query } },
      case: { lastSearch, query, autoCorrect: { isSearchTermCorrected, correctedSearchTerm } },
      search: { profiles }
    } = store.getState();

    let term;
    if (isSearchTermCorrected) {
      term = correctedSearchTerm;
    } else if (query.query !== undefined) {
      if (query.emptyQuery) {
        term = EMPTY_QUERY;
      } else {
        term = query.query;
      }
    } else {
      term = NO_VAR;
    }

    const recommendedBy = query.recommendedBy ? ('recommendedBy=').concat(query.recommendedBy) : NO_VAR;

    let sortBy;
    if (lastSearch && lastSearch.sortingOrder) {
      sortBy = lastSearch.sortingOrder;
    } else {
      sortBy = null;
    }

    const filterTerms = Object.values(CONFIG.CASE_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
    Object.values(CONFIG.CASE_QUERY_PARAMS).forEach(value => {
      if (filterTerms.indexOf(value) === -1) {
        filterTerms.push(value);
      }
    });
    const terms = filterTerms
      .filter((term) => query
        && query[term] !== undefined
        && term !== CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT
        && term !== CONFIG.QUERY_PARAMS.SORTING_ORDER
        && term !== CONFIG.QUERY_PARAMS.QUERY)
      .map((term) => (`${term}=`).concat(query[term]))
      .join('|');

    const adobe = {
      page: { pageInfo: { pageName: brand?.PAGE_TITLES.CASE() } },
      search: {
        term,
        type: CONFIG.SEARCH.SCOPES.CASE
      },
      preFilterTerm: terms || NO_VAR,
      preOrderTerm: [sortBy,
        decodeURIComponent(recommendedBy)]
        .filter((x) => x !== NO_VAR).join('|') || NO_VAR,
      sinequaProfile: profiles || NO_VAR
    };

    caseDefaults = adobe;
  }
};