import { useState, useEffect, useRef } from 'react';
import { Col } from 'components/FlexBoxGrid';
import './DownloadProgressBar.scss';
import axios from 'axios';
import CONFIG from 'config';
import { downloadPreparedFile, downloadHostMapper } from 'utils/download/download';
import { useDispatch } from 'react-redux';
import { actions as downloadActions } from 'redux/api/downloadProgress/downloadProgress';
import { actions as notifyActions } from 'redux/components/notification/notification';
import PropTypes from 'prop-types';

import * as Styled from './DownloadProgressBar.styles';

export const DownloadProgressBar = ({ downloadProgressLink, fileName, fileId, downloadType, }) => {
  const dispatch = useDispatch();

  const [percentComplete, setPercentComplete] = useState(0);
  const [isError, setIsError] = useState(false);

  const currentSource = useRef(null);
  const _isMounted = useRef(true); // mount flag for redundancy

  const dismissItem = () => {
    dispatch(downloadActions.removeDownload(fileId, 'fileId'));
  };

  const getProgress = async (intervalRef) => {
    const source = axios.CancelToken.source();
    currentSource.current = source;
    axios.get(downloadProgressLink, { cancelToken: source.token }).then(async (response) => {
      if (!_isMounted) {
        // if component has been unmounted for any reason and the axios cancel didnt work, abandon the callback
        return;
      }
      let percentageCompleted;
      let downloadUrl;
      let status;
      switch (downloadType) {
        // eventually have different switch cases, or file types such as docviz
        default:
          status = response.status ? response.status.toUpperCase() : null;
          percentageCompleted = Math.round(response.percentageCompleted || 0);
          downloadUrl = response.downloadUrl;
          if (downloadUrl) {
            downloadUrl = downloadHostMapper(response.downloadUrl, CONFIG.REACT_APP_BINARY_MAPPING);
          }
      }
      switch (status) {
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.NEW:
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.CREATION:
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.INPROGRESS:
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.PENDING:
          setPercentComplete(percentageCompleted);
          break;
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.COMPLETE:
          clearInterval(intervalRef);
          setPercentComplete(percentageCompleted);
          try {
            await downloadPreparedFile(downloadUrl, fileName);
            dispatch(downloadActions.removeDownload(fileId, 'fileId'));
          } catch (err) {
            setIsError(true);
            console.error(`EXPORT-PPT-ERROR: Error Occured while preparing download: ${err.message}`, err);
            dispatch(notifyActions.notifyError(
              CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TITLE,
              CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TEXT,
            ));
          }

          break;
        case CONFIG.DOWNLOAD_PROGRESS.STATUS_CODES.ERROR:
          clearInterval(intervalRef);
          setIsError(true);
          console.error('EXPORT-PPT-ERROR: Error Occured while retrieving download.', response); // can revisit this if needed if message not sufficient

          dispatch(notifyActions.notifyError(
            CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TITLE,
            CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TEXT,
          ));
          break;
        default:
          // generally this case shouldnt trigger
          console.error('EXPORT-PPT-ERROR: default case triggered on status:', status);
      }
    }).catch((err) => {
      clearInterval(intervalRef);
      if (axios.isCancel(err)) {
        console.log('successfully aborted');
      } else {
        // setIsError(true);
        // console.error(`EXPORT-PPT-ERROR: Error Occured while preparing download: ${err.message}`, err);
        console.log(dispatch);
        dispatch(notifyActions.notifyError(
          CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TITLE,
          CONFIG.DOWNLOAD_PROGRESS.DOWNLOAD_FAILED_TEXT,
        ));
      }
    });
  };

  useEffect(() => {
    const progressInterval = setInterval(() => getProgress(progressInterval), 5000);

    return () => {
      _isMounted.current = false;
      clearInterval(progressInterval);
      if (currentSource.current) {
        currentSource.current.cancel();
      }
    };
  }, []);

  return (
    <Styled.AttachmentInfo>
      <Styled.AttachTitle xs={7}>
        <Styled.StyledPopup
          trigger={<Styled.ErrorIcon name="exclamation triangle" hidden={!isError} />}
          content={CONFIG.DOWNLOAD_PROGRESS.ERROR_TOOLTIP_TEXT}
          hideOnScroll
          className="popup-xl--hide"
        />
        <Styled.AttachName>{fileName}</Styled.AttachName>
      </Styled.AttachTitle>
      <Col xs={4} className={`downloadProgressBar ${isError ? 'error' : ''}`}>
        <Styled.ProgressBar percent={percentComplete} size="small" className="bar" />
      </Col>
      <Col xs={1}>
        {isError ?
          <Styled.StyledButton>
            <Styled.DismissButton name="close" onClick={dismissItem} title="Dismiss" />
          </Styled.StyledButton>
          :
          <Styled.Percentage className="percentage">{percentComplete}%</Styled.Percentage>}
      </Col>
    </Styled.AttachmentInfo>
  );
};

DownloadProgressBar.propTypes = {
  downloadProgressLink: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  fileId: PropTypes.string.isRequired,
  downloadType: PropTypes.string.isRequired
};

DownloadProgressBar.defaultProps = { fileName: '', };

export default DownloadProgressBar;
