import CONFIG from 'config';
import axios from 'axios';
import { flattenQuery, buildQueryString } from 'utils/string-mapper/string-mapper';

export const name = 'knowledgeBestBets';

export const API_KNOWLEDGE_BEST_BETS_PENDING = 'API_KNOWLEDGE_BEST_BETS_PENDING';
export const API_KNOWLEDGE_BEST_BETS_FAILURE = 'API_KNOWLEDGE_BEST_BETS_FAILURE';
export const API_KNOWLEDGE_BEST_BETS_SUCCESS = 'API_KNOWLEDGE_BEST_BETS_SUCCESS';

const sources = [];
const initialState = {
  bestBets: [],
  loading: true,
  error: false,
  errorMessage: ''
};

export const actions = {
  getBestBets: (query) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    // best bet API needs keyword param, not query, so rename it
    const { query: keyword, ...restQuery } = query;
    const bestBetQuery = {
      ...restQuery,
      keyword: [keyword?.replace(/^['"\s]*/g, '')?.replace(/['"\s]*$/g, '')]
    };
    const searchQuery = flattenQuery(bestBetQuery);

    //fix sorting order
    if(searchQuery?.sortingOrder) {
      searchQuery.sortingOrder = [searchQuery.sortingOrder];
    }

    dispatch({ type: API_KNOWLEDGE_BEST_BETS_PENDING });
    
    try {
      const data = await axios.post(CONFIG.API_URL.KNOWLEDGE_BEST_BET,
        searchQuery,
        { cancelToken: newSource.token }
      );

      dispatch({ type: API_KNOWLEDGE_BEST_BETS_SUCCESS, payload: data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId, isBestBet: true })) || [] });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_KNOWLEDGE_BEST_BETS_FAILURE, payload: error.toString() });
      throw error;
    }
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_KNOWLEDGE_BEST_BETS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_KNOWLEDGE_BEST_BETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        bestBets: []
      };
    case API_KNOWLEDGE_BEST_BETS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        bestBets: action.payload || []
      };
    default:
      return state;
  }
}