// developer utils we can run in dev console
export default () => {
  /**
   * 
   * @param {string} reqs line break, \n, delimited list of all parameters required, will be compared against window.digitalData
   */
  window.bcgCompareToDigitalData = (reqs) => {
    const params = reqs.split('\n');
    params.forEach(p => {
      if (p.indexOf('digitalData') === 0) {
        try {
          const val = eval(p);
          if (val) {
            console.log('%cgood', 'color: #0F0', p, ': ', val);
          } else {
            console.log('%cdata', 'color: #FF0', p, ': ', val);
          }
        } catch {
          console.log('%cerrored', 'color: #F00', p);
        }
      } else {
        console.log('skipped', p);
      }
    });
  };
};