import axios from 'axios';
import CONFIG from 'config';
import * as utils from '@kdshared/okta-utils';

export const API_PEOPLE_IMAGE_PENDING = 'API_PEOPLE_IMAGE_PENDING';
export const API_PEOPLE_IMAGE_SUCCESS = 'API_PEOPLE_IMAGE_SUCCESS';
export const API_PEOPLE_IMAGE_FAILURE = 'API_PEOPLE_IMAGE_FAILURE';

export const name = 'peopleImages';

const sources = [];
const promiseBackingStore = {};
const initialState = {
  images: {},
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getPeopleImages: (state) => state[name].images, };

export const actions = {
  fetchPeopleImages: (hrIds) => async (dispatch) => {
    hrIds.forEach(async (hrId) => {
      if (promiseBackingStore[hrId]) return;
      promiseBackingStore[hrId] = true;

      const newSource = axios.CancelToken.source();
      sources.push(newSource);
      dispatch({ type: API_PEOPLE_IMAGE_PENDING });

      try {
        const response = await axios.get(
          CONFIG.PEOPLE_IMAGE(hrId),
          {
            headers: {
              'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
              psid: utils.getPsId()
            },
            responseType: 'arraybuffer',
            cancelToken: newSource.token
          });

        const byteSequence = new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), '');
        const peopleImageData = `data:image/png;base64,${btoa(byteSequence)}`;

        dispatch({ type: API_PEOPLE_IMAGE_SUCCESS, payload: { hrId, peopleImageData } });
      } catch (error) {
        delete promiseBackingStore[hrId];
        dispatch({ type: API_PEOPLE_IMAGE_FAILURE, payload: { hrId, error: error.message } });
      }
    });
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_PEOPLE_IMAGE_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_PEOPLE_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error,
        images: {
          ...state.images,
          [action.payload.hrId]: 'error'
        }
      };
    case API_PEOPLE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        images: {
          ...state.images,
          [action.payload.hrId]: action.payload.peopleImageData
        }
      };
    default:
      return state;
  }
}