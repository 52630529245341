import { withRouter } from 'react-router';
import { useRef , useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import binIcon from 'assets/images/bin.svg';
import editIcon from 'assets/images/edit-icon.svg';
import checkIcon from 'assets/images/checkmark.svg';
import crossIcon from 'assets/images/cross.svg';
import { Image } from '@kdshared/enrich-ui';
import {LABELS} from 'utils/proposalBuilder/labels';

import { SessionsStyles, StartChatButton } from './Sessions.style';
import { actions as chatActions } from 'redux/api/proposalBuilder/chat/chat';


export const Sessions = ({listItems = []}) => {
  const dispatch = useDispatch();
  const { DELETE_ICON, START_NEW_CHAT, START_NEW_CHAT_TEXT, START_NEW_CHAT_LABEL, CHECK_ICON, EDIT_ICON, CROSS_ICON } = LABELS;
  const inputRef = useRef(null);
  const [editIndex, setEditIndex] = useState(null);
  const [list, setList] = useState([...listItems]);

  useEffect(() => {
    setList([...listItems]);
  }, [listItems]);

  const handleDelete = useCallback((level1, level2) => {

    setList((prev) => {
      const newState = [...prev];
      newState[level1] = {
        ...newState[level1],
        items: newState[level1]?.items.filter((_, index) => index != level2)
      };
      
      return newState;
    });
  }, [editIndex]);

  const handleEditApproval = useCallback((e) => {
    e.preventDefault();
    setList((prev) => {
      const newState = [...prev];

      newState[editIndex?.level1].items[editIndex?.level2].title = inputRef.current?.value;
      return newState;
    });
    setEditIndex(null);
  }, [editIndex]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [editIndex]);

  const handleNewChat = () => { 
    setList((prev) => {
      const newState = [...prev];

      newState[0] = {
        title: prev[0].title,
        items: [{
          title: START_NEW_CHAT_TEXT
        }, 
        ...prev[0].items
        ]
      };

      return newState;
    });
    dispatch(chatActions.resetChatState());
  };

  const renderActions = (level1, level2) => {
    if(editIndex?.level1 === level1 && editIndex?.level2 === level2) {
      return (
        <div className='actions' data-testid="edit-ops">
          <Image className="actionIcon" src={checkIcon} alt={CHECK_ICON} onClick={handleEditApproval}/>
          <Image className="actionIcon ml5" src={crossIcon} alt={CROSS_ICON} onClick={(e) => {
            e.preventDefault();
            setEditIndex(null);}}/>
        </div>
      );
    }

    return (
      <div className='actions' data-testid="hover-ops">
        <Image data-testid="checkIcon" className="actionIcon"  src={editIcon} alt={EDIT_ICON} onClick={(e) => {
          e.preventDefault();
          setEditIndex({level1, level2});}
        }/>
        <Image data-testid="delIcon" className="actionIcon ml5" src={binIcon} alt={DELETE_ICON} onClick={() => handleDelete(level1, level2)}/>
      </div>
    );
  };

  const renderInput = (value = '', level1, level2) => {
    if(editIndex?.level1 === level1 && editIndex?.level2 === level2) {
      return (
        <input ref={inputRef} type='text' defaultValue={value} className='inlineInput'/>
      );
    }

    return value;
  };

  return (
    <SessionsStyles>
      <StartChatButton
        title={START_NEW_CHAT}
        onClick={handleNewChat}
      >
        {START_NEW_CHAT_LABEL}
      </StartChatButton>
      <div className='sessionWrapper mt10 hideScrollbar'>
        {
          list.map((item, index) => (
            <div className='sessionlist' key={index}>
              <h2>{item.title}</h2>
              <nav>
                <ul>
                  {
                    item.items.map((i, index2) => (
                      <li className='sessionItem' key={`${index}-${index2}`}><a href="" onClick={(e) => e.preventDefault()}>
                        {renderInput(i.title, index, index2)}
                        {renderActions(index, index2)}
                      </a></li>
                    ))
                  }
                
                </ul>
              </nav>
            </div>
          ))
        }
      </div>
    </SessionsStyles>
  );
};

export default withRouter(Sessions);