// query string is empty
const EMPTY_QUERY = 'empty query';
// no value for an eVar configured
const NO_VAR = 'not available';
// search provided without typeahead option selected
const NO_TYPEAHEAD = 'no typeahead';
// search provided without typeahead option selected
const TYPEAHEAD_SUGGESTION_MATCH = 'typeahead suggestion match';
// typeahead option selected is search/concept suggestion
const TYPEAHEAD_MATERIAL_SEARCH = 'typeahead search suggestion';
// typeahead option selected is people suggestion
const TYPEAHEAD_PEOPLE_SEARCH = 'typeahead people suggestion';
// typeahead option selected is case suggestion
const TYPEAHEAD_CASE_SEARCH = 'typeahead case suggestion';
// typeahead option selected is author suggestion
const TYPEAHEAD_AUTHOR = 'typeahead author suggestion';
// typeahead option selected is acronym suggestion
const TYPEAHEAD_ACRONYM = 'typeahead acronym suggestion';
// typeahead option selected is filename suggestion
const TYPEAHEAD_FILENAME = 'typeahead filename suggestion';
// recent search option from typeahead
const TYPEAHEAD_RECENT_SEARCH = 'recent search option';
// recent search option from recent activity tab
const RECENT_ACTIVITY_RECENT_SEARCH = 'recent activity recent search option';
// spell check rejected
const SPELL_CHECK_REJECT = 'spell check reject';
// spell check accepted
const SPELL_CHECK_ACCEPT = 'spell check accept';
// material PA status recommended
const PA_RECOMMENDED = 'Recommended';
// material PA status not recommended
const PA_NOT_RECOMMENDED = 'Not Recommended';
// card expansion status expanded
const CARD_EXPANDED = 'Expanded';
// card expansion status collapsed
const CARD_COLLAPSED = 'Collapsed';
// relevant status
const RELEVANT = 'Relevant';
// relevant status
const NOT_RELEVANT = 'Not Relevant';
// external source
const EXTERNAL_SOURCE = 'external source';
const IS_BEST_BET = 'yes';
const IS_NOT_BEST_BET = 'no';

export {
  EMPTY_QUERY,
  NO_VAR,
  NO_TYPEAHEAD,
  TYPEAHEAD_SUGGESTION_MATCH,
  TYPEAHEAD_MATERIAL_SEARCH,
  TYPEAHEAD_PEOPLE_SEARCH,
  TYPEAHEAD_CASE_SEARCH,
  TYPEAHEAD_AUTHOR,
  TYPEAHEAD_ACRONYM,
  TYPEAHEAD_FILENAME,
  TYPEAHEAD_RECENT_SEARCH,
  RECENT_ACTIVITY_RECENT_SEARCH,
  SPELL_CHECK_REJECT,
  SPELL_CHECK_ACCEPT,
  PA_RECOMMENDED,
  PA_NOT_RECOMMENDED,
  CARD_EXPANDED,
  CARD_COLLAPSED,
  RELEVANT,
  NOT_RELEVANT,
  EXTERNAL_SOURCE,
  IS_BEST_BET,
  IS_NOT_BEST_BET
};