import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CONFIG from 'config';
import { BrandContext } from 'context/BrandContext/BrandContext';

class BrandWrapper extends Component {
  constructor(props) {
    super(props);

    let brand = CONFIG.BRANDS.find((b) => b.URL === window.location.host);
    if (!brand) {
      [brand] = CONFIG.BRANDS;
    }
    this.state = { ...brand };
  }

  render = () => (
    <BrandContext.Provider value={this.state}>
      { this.props.children }
    </BrandContext.Provider>
  );
}

BrandWrapper.propTypes = { children: PropTypes.shape().isRequired };

export default BrandWrapper;
