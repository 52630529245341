import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Row } from 'components/FlexBoxGrid';
import downloadIcon from 'assets/images/download-icon-white.svg';
import Image from 'components/Image/Image';
import { actions as downloadSlidesActions, selectors as docVizSlideSelectionSelectors } from 'redux/components/downloadSlides/downloadSlides';

import CONFIG from 'config';
import DownloadProgressBars from 'components/DownloadProgressBars/DownloadProgressBars';
import './DownloadProgressBarWrapper.scss';

export class DownloadProgressBarWrapper extends Component {
  handleCloseProgressBars = () => {
    const { downloadSlidesActions: { closeProgressBars } } = this.props;
    closeProgressBars();
  };

  downloadButton = (length) => {
    const { downloadSlidesActions: { openProgressBars } } = this.props;
    return (
      <Row className="progressButton" role="button" onClick={openProgressBars} data-testid={CONFIG.TEST_IDS.DOWNLOAD_PROGRESS.PROGRESS_BUTTON}>
        <Image className="downloadIcon" src={downloadIcon} alt="icon" />
        <p className="downloadText">{CONFIG.DISPLAY_TEXT.DOWNLOAD_PROGRESS.TEXT(length)}</p>
      </Row>
    );
  }

  render() {
    const {
      clippedData,
      showProgressBars
    } = this.props;
    const clippedDataLength = Object.keys(clippedData).length;
    const allDownload = Object.keys(clippedData).every((key) => clippedData[key].status === 'completed');
    return (
      <div className="progressBarContainer">
        {
          clippedDataLength > 0 && !showProgressBars && !allDownload &&
          this.downloadButton(clippedDataLength)
        }
        {
          showProgressBars && !allDownload &&
          <DownloadProgressBars clippedData={clippedData} handleCloseProgressBars={this.handleCloseProgressBars} />
        }
      </div>
    );
  }
}

DownloadProgressBarWrapper.propTypes = {
  downloadSlidesActions: PropTypes.shape().isRequired,
  clippedData: PropTypes.shape().isRequired,
  showProgressBars: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  clippedData: docVizSlideSelectionSelectors.getClippedData(state),
  showProgressBars: docVizSlideSelectionSelectors.getProgressBars(state),
});

const mapDispatchToProps = (dispatch) => ({ downloadSlidesActions: bindActionCreators({ ...downloadSlidesActions }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DownloadProgressBarWrapper);