import * as utils from '@kdshared/okta-utils';
import { OktaAuth } from '@okta/okta-auth-js';
import CONFIG from 'config';

const baseConfig = utils.authServiceConfig(CONFIG.ENVIRONMENT);
const authConfig = {
  issuer: CONFIG.OKTA.ISSUER,
  clientId: CONFIG.OKTA.CLIENT_ID,
  redirectUri: `${window.location.origin}${CONFIG.OKTA.REDIRECT_URL}`
};

export default new OktaAuth({ ...baseConfig, ...authConfig });