import React, { PureComponent } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';

import Icon from 'components/Icon/Icon';

import './Notification.scss';

class Notification extends PureComponent {
  componentDidUpdate = (prevProps) => {
    if (this.props.timestamp !== prevProps.timestamp || this.props.mode !== 'info') {
      this.show();
    }
    if (this.props.mode === 'dismissNotifications') {
      toast.dismiss();
    }
  }

  show = () => {
    const {
      mode,
      title,
      message,
      autoClose
    } = this.props;

    let iconClass = '';
    switch (mode) {
      case 'error':
        iconClass = 'exclamation triangle';
        break;
      case 'info':
        iconClass = 'info circle';
        break;
      case 'warning':
        iconClass = 'info circle';
        break;
      case 'success':
        iconClass = 'check circle outline';
        break;
      default:
        // do nothing
        break;
    }

    const msg = () => (
      <div>
        <div className="title">
          <Icon className={iconClass} />
          <h4>{title}</h4>
        </div>
        <div className="message">{message}</div>
      </div>
    );

    const options = {
      autoClose,
      closeOnClick: false
    };

    switch (mode) {
      case 'error':
        toast.error(msg, options);
        break;
      case 'info':
        toast.info(msg, options);
        break;
      case 'warning':
        toast.warn(msg, options);
        break;
      case 'success':
        toast.success(msg, options);
        break;
      default:
        // do nothing
        break;
    }
  }

  render = () => (
    <ToastContainer />
  );
}

Notification.propTypes = {
  mode: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  autoClose: PropTypes.bool,
  timestamp: PropTypes.string
};

Notification.defaultProps = {
  mode: '',
  title: '',
  message: '',
  autoClose: false,
  timestamp: ''
};

export default Notification;