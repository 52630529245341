import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { name as bcgInternalReducerName, reducer as bcgInternalReducer } from 'redux/api/bcgInternal/bcgInternal';
import { name as casesv2SearchName, reducer as caseV2SearchReducer } from 'redux/api/casesv2Search/casesv2Search';
import { name as docVizSlideSelection, reducer as docVizSlideSelectionReducer } from 'redux/components/downloadSlides/downloadSlides';
import { name as downloadReducerName, reducer as downloadReducer } from 'redux/api/downloadProgress/downloadProgress';
import { name as homepageLinksName, reducer as homepageLinksReducer } from 'redux/api/homepageLinks/homepageLinks';
import { name as htmlName, reducer as htmlReducer } from 'redux/api/html/html';
import { name as imageName, reducer as imageReducer } from 'redux/api/image/image';
import { name as knowledgeBestBetsReducerName, reducer as knowledgeBestBetsReducer } from 'redux/api/knowledgeBestBets/knowledgeBestBets';
import { name as knowledgeReducerName, reducer as knowledgeReducer } from 'redux/api/knowledge/knowledge';
import { name as labDetailsReducerName, reducer as labDetailsReducer } from 'redux/api/labDetails/labDetails';
import { name as labReducerName, reducer as labReducer } from 'redux/api/lab/lab';
import { name as materialReducerName, reducer as materialReducer } from 'redux/components/material/material';
import { name as metaDataName, reducer as metaDataReducer } from 'redux/api/metaData/metaData';
import { name as micrositesName, reducer as micrositesReducer } from 'redux/api/microsites/microsites';
import { name as notifyReducerName, reducer as notifyReducer } from 'redux/components/notification/notification';
import { name as officepagesName, reducer as officepagesReducer } from 'redux/api/officepages/officepages';
import { name as helpfulLinksName, reducer as helpfulLinksReducer } from 'redux/api/helpfulLinks/helpfulLinks';
import { name as peopleImagesName, reducer as peopleImagesReducer } from 'redux/api/peopleImages/peopleImages';
import { name as peopleResultsName, reducer as peopleResultsReducer } from 'redux/api/peopleResults/peopleResults';
import { name as relevancyFeedbackName, reducer as relevancyFeedbackReducer } from 'redux/components/relevancyfeedback/relevancyfeedback';
import { name as searchReducerName, reducer as searchReducer } from 'redux/api/search/search';
import { name as serpName, reducer as serpReducer } from 'redux/components/serp/serp';
import { name as storageName, reducer as storageReducer } from 'redux/api/storage/storage';
import { name as topicSuggestionsName, reducer as topicSuggestionsReducer } from 'components/TopicProductSuggestions/redux/topicSuggestions/topicSuggestions';
import { name as typeAheadFeedback, reducer as typeAheadFeedbackReducer } from 'redux/components/typeaheadfeedback/typeaheadfeedback';
import { name as typeAheadReducerName, reducer as typeAheadReducer } from 'redux/components/type-ahead/type-ahead';
import { name as userReducerName, reducer as userReducer } from 'redux/api/user/user';
import { name as querySuggestionsName, reducer as querySuggestionsReducer } from 'redux/api/querySuggestions/querySuggestions';
import { name as expertSearchName, reducer as expertSearchReducer } from 'redux/api/expertSearch/expertSearch';
import { name as docViz2CardName, reducer as docViz2CardReducer } from 'components/GridViewCard/redux/docViz2Card';
import { name as casefieldsName, reducer as casefieldsReducer } from 'redux/api/casefields/casefields';
import { name as paTopicsSearchName, reducer as paTopicsSearchReducer } from 'redux/api/paTopics/paTopics';
import { name as aiReducerName, reducer as aiReducer } from 'redux/api/knowledgeAi/knowledgeAi';
import { name as chatReducerName, reducer as chatReducer } from 'redux/api/chat/chat';
import { name as chatHistoryReducerName, reducer as chatHistoryReducer } from 'redux/api/chatHistory/chatHistory';
import {name as proposalBios,reducer as proposalBioReducer} from 'redux/api/proposalBio/proposalBio';
import { name as resumeName, reducer as resumeReducer } from 'redux/api/resume/resume';
import {name as downloadName,reducer as downloadBioReducer} from 'redux/api/download/downloadBioResume';
import {name as languageName,reducer as languageReducer} from 'redux/api/language/language';
import { name as proposalBuilderChatName, reducer as proposalBuilderChatReducer } from 'redux/api/proposalBuilder/chat/chat';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  [bcgInternalReducerName]: bcgInternalReducer,
  [casesv2SearchName]: caseV2SearchReducer,
  [docVizSlideSelection]: docVizSlideSelectionReducer,
  [downloadReducerName]: downloadReducer,
  [expertSearchName]: expertSearchReducer,
  [homepageLinksName]: homepageLinksReducer,
  [htmlName]: htmlReducer,
  [imageName]: imageReducer,
  [knowledgeBestBetsReducerName]: knowledgeBestBetsReducer,
  [knowledgeReducerName]: knowledgeReducer,
  [labDetailsReducerName]: labDetailsReducer,
  [labReducerName]: labReducer,
  [materialReducerName]: materialReducer,
  [metaDataName]: metaDataReducer,
  [micrositesName]: micrositesReducer,
  [notifyReducerName]: notifyReducer,
  [officepagesName]: officepagesReducer,
  [helpfulLinksName]: helpfulLinksReducer,
  [serpName]: serpReducer,
  [peopleImagesName]: peopleImagesReducer,
  [peopleResultsName]: peopleResultsReducer,
  [relevancyFeedbackName]: relevancyFeedbackReducer,
  [searchReducerName]: searchReducer,
  [serpName]: serpReducer,
  [storageName]: storageReducer,
  [topicSuggestionsName]: topicSuggestionsReducer,
  [typeAheadFeedback]: typeAheadFeedbackReducer,
  [typeAheadReducerName]: typeAheadReducer,
  [userReducerName]: userReducer,
  [querySuggestionsName]: querySuggestionsReducer,
  [docViz2CardName]: docViz2CardReducer,
  [casefieldsName]: casefieldsReducer,
  [paTopicsSearchName]: paTopicsSearchReducer,
  [aiReducerName]: aiReducer,
  [chatReducerName]: chatReducer,
  [chatHistoryReducerName]: chatHistoryReducer,
  [proposalBios]:proposalBioReducer,
  [resumeName]: resumeReducer,
  [downloadName]:downloadBioReducer,
  [languageName]:languageReducer,
  [proposalBuilderChatName]: proposalBuilderChatReducer
});

export default createRootReducer;