import * as Styled from './Modal.style';

export const Modal = ({title, body, cancelButtonLabel, approvedButtonLabel, onCancel, onApproved, show = false}) => {
  if(!show) {
    return null;
  }
    
  return (
    <>
      <Styled.Overlay />
      <Styled.Modal data-testid="confirmationModal">
        <div className='modalWrapper'>
          <h2 className='heading'>{title}</h2>
          {body && 
            <div className='body'>
              {body}
            </div>
          }
          <div className='buttonWrapper'>
            {onCancel &&
            <input type='button' className="button" value={cancelButtonLabel} onClick={onCancel}/>
            }
            {onApproved && 
            <input type='button' className="button ml10 buttonApproved" value={approvedButtonLabel} onClick={onApproved}/>
            }
          </div>
        </div>
      </Styled.Modal>
    </>
  );
};