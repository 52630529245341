/* eslint-disable no-console */
import CONFIG from 'config';
import Cookies from 'js-cookie';
import * as utils from '@kdshared/okta-utils';
import authService from 'redux/auth';
import { datadogRum } from '@datadog/browser-rum';

export function clearAuthCache() {
  console.log('OKTA auth.js 001 - clearing cache');
  utils.clearOktaCookies(CONFIG.ENVIRONMENT);
}

export function setAuthAttemptTime() {
  Cookies.set(CONFIG.OKTA.AUTH_ATTEMPTED, new Date());
}

export function clearAuthAttemptTime() {
  Cookies.remove(CONFIG.OKTA.AUTH_ATTEMPTED);
}

export function isAuthAttemptSet() {
  return Cookies.get(CONFIG.OKTA.AUTH_ATTEMPTED) !== undefined;
}

export function getSecondsSinceLastAttempt() {
  const lastRetryTime = new Date(Cookies.get(CONFIG.OKTA.AUTH_ATTEMPTED));
  const now = new Date();
  const durationInMs = now - lastRetryTime;
  console.log('OKTA auth.js seconds since last attempt', durationInMs / 1000);
  return durationInMs / 1000;
}

export function getOktaExpirations() {
  try {
    const accessToken = Cookies.get(CONFIG.OKTA.ACCESS_TOKEN_STORAGE);
    const idToken = Cookies.get(CONFIG.OKTA.ID_TOKEN_STORAGE);
    const oktaAccessExpiration = new Date(JSON.parse(accessToken).expiresAt * 1000).toString();
    const oktaIdExpiration = new Date(JSON.parse(idToken).expiresAt * 1000).toString();
    return [oktaAccessExpiration, oktaIdExpiration];
  } catch {
    return [null, null];
  }
}

// gets the access token from our cookie
// use only for testing and confirmation, otherwise use the authService.getAccessToken()
export function getAccessTokenFromCookie() {
  let accessToken = '';
  try {
    const accessTokenCookie = Cookies.get(CONFIG.OKTA.ACCESS_TOKEN_STORAGE);
    if (accessTokenCookie) {
      accessToken = JSON.parse(accessTokenCookie).accessToken;
    }
  } catch (ex) {
    console.error('OKTA auth.js getAccessTokenFromCookie', ex.toString());
  }
  return accessToken;
}

export function checkDurationAndReload(durationInSeconds) {
  const duration = getSecondsSinceLastAttempt();

  datadogRum.addAction('okta', {
    function: `auth.js/checkDurationAndReload(${durationInSeconds})`,
    secondsSinceLastAttempt: duration,
    authAttempted: Cookies.get(CONFIG.OKTA.AUTH_ATTEMPTED),
  });
  if (duration > durationInSeconds) {
    clearAuthCache();
    setAuthAttemptTime();
    console.log('OKTA auth.js 002 - reloading page');
    if (window.location.href.indexOf('/error') > -1) {
      window.location.href = '/';
    } else {
      window.location.reload();
    }
  } else {
    console.error('OKTA auth.js 002a - not reloading page');
  }
}

export async function isUserAuthenticated() {
  try {
    const accessToken = await authService.getAccessToken();
    console.log('OKTA auth.js is user authenticated', !!accessToken);
    return !!accessToken;
  } catch {
    return false;
  }
}

export async function getUserEmail() {
  try {
    const userEmail = utils.getUserClaims()?.email;
    if (!userEmail) {
      throw new Error('users email does not exist');
    }
    return userEmail;
  } catch (err) {
    let missingMsg = '';
    let stopChecking = false;
    try {
      const whatsErroring = Cookies.get('okta-user-claims');
      if (whatsErroring) {
        missingMsg = `got okta-user-claims from the cookie, is ${whatsErroring.length} long|`;
      } else {
        missingMsg = 'cannot get okta-user-claims from the cookie';
        stopChecking = true;
      }
    } catch (ex) {
      missingMsg = `error trying to get okta-user-claims from the cookie. ${ex.toString}|`;
      stopChecking = true;
    }

    if (!stopChecking) {
      try {
        const whatsErroring = JSON.parse(Cookies.get('okta-user-claims'));
        if (whatsErroring) {
          missingMsg += `idToken JSON parsed, has ${Object.keys(whatsErroring).length} keys|`;
        } else {
          missingMsg += 'idToken JSON did not parse|';
          stopChecking = true;
        }
      } catch (ex) {
        missingMsg += `error trying to idToken JSON parse. ${ex.toString}|`;
        stopChecking = true;
      }
    }

    if (!stopChecking) {
      try {
        const whatsErroring = JSON.parse(Cookies.get('okta-user-claims')).claims;
        if (whatsErroring) {
          missingMsg += `claims JSON parsed, has ${Object.keys(whatsErroring).length} keys|`;
        } else {
          missingMsg += 'claims JSON did not parse|';
          stopChecking = true;
        }
      } catch (ex) {
        missingMsg += `error trying to claims JSON parse. ${ex.toString}|`;
        stopChecking = true;
      }
    }

    if (!stopChecking) {
      try {
        const whatsErroring = JSON.parse(Cookies.get('okta-user-claims')).claims.email;
        if (whatsErroring) {
          missingMsg += `email JSON parsed, has ${Object.keys(whatsErroring).length} keys|`;
        } else {
          missingMsg += 'email JSON did not parse|';
          stopChecking = true;
        }
      } catch (ex) {
        missingMsg += `error trying to claims JSON parse. ${ex.toString}|`;
        stopChecking = true;
      }
    }

    console.error('OKTA auth.js getUserEmail', missingMsg, err);

    if (!isAuthAttemptSet()) setAuthAttemptTime();

    // DataDog trying to fetch user email before login resulting in calling /implicit/callback twice
    if (window.location.href.indexOf('/implicit') === -1) {
      checkDurationAndReload(15);
    }
    return null;
  }
}
