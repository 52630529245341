export const name = 'notification';

export const ERROR = 'ERROR';
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';
export const WARNING = 'WARNING';
export const DISMISS_NOTIFICATIONS = 'DISMISS_NOTIFICATIONS';

const initialState = {
  mode: '',
  title: '',
  message: '',
  autoclose: false,
  timestamp: ''
};

export const selectors = {
  getMode: (state) => state[name].mode,
  getTitle: (state) => state[name].title,
  getMessage: (state) => state[name].message,
  getAutoClose: (state) => state[name].autoclose,
  getTimestamp: (state) => state[name].timestamp
};

export const actions = {
  notifyError: (title, message, error) => async (dispatch) => {
    dispatch({ type: ERROR, title, message });
    // eslint-disable-next-line
    console.error('notification', error);
  },
  notifyInfo: (title, message) => async (dispatch) => {
    dispatch({ type: INFO, title, message });
  },
  notifySuccess: (title, message) => async (dispatch) => {
    dispatch({ type: SUCCESS, title, message });
  },
  notifyWarning: (title, message) => async (dispatch) => {
    dispatch({ type: WARNING, title, message });
  },
  dismissNotifications: () => async (dispatch) => {
    dispatch({ type: DISMISS_NOTIFICATIONS });
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        mode: 'error',
        title: action.title,
        message: action.message,
        autoclose: false,
        timestamp: new Date().toString()
      };
    case INFO:
      return {
        ...state,
        mode: 'info',
        title: action.title,
        message: action.message,
        autoclose: true,
        timestamp: new Date().toString()
      };
    case SUCCESS:
      return {
        ...state,
        mode: 'success',
        title: action.title,
        message: action.message,
        autoclose: true,
        timestamp: new Date().toString()
      };
    case WARNING:
      return {
        ...state,
        mode: 'warning',
        title: action.title,
        message: action.message,
        autoclose: false,
        timestamp: new Date().toString()
      };
    case DISMISS_NOTIFICATIONS:
      return {
        ...state,
        mode: 'dismissNotifications'
      };
    default:
      return state;
  }
}