export const CONTENT =  `<h2 id="0" class='scrollable'>Hypothesis and Perspective</h2>
Agile is a new way of working that <strong>focuses</strong> on <u>flexibility</u>, <i>value-generation</i>, <strike>attracting talent</strike>, and driving better outcomes. It involves cross-functional teams working together across different functions such as manufacturing, maintenance, and quality
  <sup><a href="https://pages.navigator.bcg.com/kp/8d087067-7d4d-421f-999c-960a4c929eac?opendocviz=true&amp;slideno=116" target="_blank"  rel="noreferrer">[1]</a></sup>.
  <br/><br/>

<ul>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
</ul>

<ol>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
  <li>Agile is a new way of working that focuses on flexibility</li>
</ol>

Agile is characterized by iterative sprints to solve root causes for issues, focusing on minimum viable solutions. It encourages transparency-oriented teamwork with regular output, strong prioritization, adaptability, and empowered staff and teams. A constant focus on the customer and feedback is also a key aspect of Agile<sup ><a href="https://pages.navigator.bcg.com/kp/8d087067-7d4d-421f-999c-960a4c929eac?opendocviz=true&amp;slideno=116" target="_blank"  rel="noreferrer">[1]</a></sup>.<br/><br/>At its core, Agile involves a substantial mindset shift and a set of key principles. Living these principles enables a focus on customers, strategic priorities, and purpose, moving away from vertical/functional silos towards end-to-end horizontal collaboration and ownership of outcomes. Three Agile management practices are key to operationalize: autonomous cross-functional teams with dedicated members, iterative work with customers/stakeholders, and Agile leadership behaviors<sup ><a href="https://pages.navigator.bcg.com/kp/4cde5bc2-e44f-4d9f-ac10-3fabee18faec?opendocviz=true&amp;slideno=16" target="_blank"  rel="noreferrer">[2]</a></sup>.<br/><br/>Agile can also be applied in sales, where it accelerates decision making and moves it closer to customer value. It accelerates iteration to increase conversion, provides deeper transparency to sales progress and actions for accurate forecasting, increases front-line efficiency for more effective resource allocation, and iterates on front lines for product market fit to find winning strategy<sup ><a href="https://pages.navigator.bcg.com/kp/f50622fd-be52-4cd1-92c0-6b8a80a19942?opendocviz=true&amp;slideno=3" target="_blank"  rel="noreferrer">[3]</a></sup>.
Agile is a new way of working that focuses on flexibility, value-generation, attracting talent, and driving better outcomes. It involves cross-functional teams working together across different functions such as manufacturing, maintenance, and quality
  <sup><a href="https://pages.navigator.bcg.com/kp/8d087067-7d4d-421f-999c-960a4c929eac?opendocviz=true&amp;slideno=116" target="_blank"  rel="noreferrer">[1]</a></sup>.
  
  <h2 id="1" class='scrollable'>Customer situation and needs</h2>
Agile is characterized by iterative sprints to solve root causes for issues, focusing on minimum viable solutions. It encourages transparency-oriented teamwork with regular output, strong prioritization, adaptability, and empowered staff and teams. A constant focus on the customer and feedback is also a key aspect of Agile<sup ><a href="https://pages.navigator.bcg.com/kp/8d087067-7d4d-421f-999c-960a4c929eac?opendocviz=true&amp;slideno=116" target="_blank"  rel="noreferrer">[1]</a></sup>.<br/><br/>At its core, Agile involves a substantial mindset shift and a set of key principles. Living these principles enables a focus on customers, strategic priorities, and purpose, moving away from vertical/functional silos towards end-to-end horizontal collaboration and ownership of outcomes. Three Agile management practices are key to operationalize: autonomous cross-functional teams with dedicated members, iterative work with customers/stakeholders, and Agile leadership behaviors<sup ><a href="https://pages.navigator.bcg.com/kp/4cde5bc2-e44f-4d9f-ac10-3fabee18faec?opendocviz=true&amp;slideno=16" target="_blank"  rel="noreferrer">[2]</a></sup>.<br/><br/>Agile can also be applied in sales, where it accelerates decision making and moves it closer to customer value. It accelerates iteration to increase conversion, provides deeper transparency to sales progress and actions for accurate forecasting, increases front-line efficiency for more effective resource allocation, and iterates on front lines for product market fit to find winning strategy<sup ><a href="https://pages.navigator.bcg.com/kp/f50622fd-be52-4cd1-92c0-6b8a80a19942?opendocviz=true&amp;slideno=3" target="_blank"  rel="noreferrer">[3]</a></sup>.

  <h2 id="2" class='scrollable'>Why BCG</h2>
Agile is characterized by iterative sprints to solve root causes for issues, focusing on minimum viable solutions. It encourages transparency-oriented teamwork with regular output, strong prioritization, adaptability, and empowered staff and teams. A constant focus on the customer and feedback is also a key aspect of Agile<sup ><a href="https://pages.navigator.bcg.com/kp/8d087067-7d4d-421f-999c-960a4c929eac?opendocviz=true&amp;slideno=116" target="_blank"  rel="noreferrer">[1]</a></sup>.<br/><br/>At its core, Agile involves a substantial mindset shift and a set of key principles. Living these principles enables a focus on customers, strategic priorities, and purpose, moving away from vertical/functional silos towards end-to-end horizontal collaboration and ownership of outcomes. Three Agile management practices are key to operationalize: autonomous cross-functional teams with dedicated members, iterative work with customers/stakeholders, and Agile leadership behaviors<sup ><a href="https://pages.navigator.bcg.com/kp/4cde5bc2-e44f-4d9f-ac10-3fabee18faec?opendocviz=true&amp;slideno=16" target="_blank"  rel="noreferrer">[2]</a></sup>.<br/><br/>Agile can also be applied in sales, where it accelerates decision making and moves it closer to customer value. It accelerates iteration to increase conversion, provides deeper transparency to sales progress and actions for accurate forecasting, increases front-line efficiency for more effective resource allocation, and iterates on front lines for product market fit to find winning strategy<sup ><a href="https://pages.navigator.bcg.com/kp/f50622fd-be52-4cd1-92c0-6b8a80a19942?opendocviz=true&amp;slideno=3" target="_blank"  rel="noreferrer">[3]</a></sup>.`;
