import moment from 'moment';
import CONFIG from 'config';
import axios from 'axios';
import { buildQueryString, escapeRegExp, flattenQuery, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';
import { isQueryIntentEnabled, getNewSearchTerm } from 'utils/queryintent/queryintent';
import { setAppliedFilters, formatFilters } from 'utils/filters/filters';

export const name = 'knowledge';

export const API_KNOWLEDGE_SEARCH_PENDING = 'API_KNOWLEDGE_SEARCH_PENDING';
export const API_KNOWLEDGE_SEARCH_FAILURE = 'API_KNOWLEDGE_SEARCH_FAILURE';
export const API_KNOWLEDGE_SEARCH_SUCCESS = 'API_KNOWLEDGE_SEARCH_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const API_KNOWLEDGE_LOAD_MORE_PENDING = 'API_KNOWLEDGE_LOAD_MORE_PENDING';
export const API_KNOWLEDGE_LOAD_MORE_FAILURE = 'API_KNOWLEDGE_LOAD_MORE_FAILURE';
export const API_KNOWLEDGE_LOAD_MORE_SUCCESS = 'API_KNOWLEDGE_LOAD_MORE_SUCCESS';
export const API_SLIDE_LEVEL_SEARCH_PENDING = 'API_SLIDE_LEVEL_SEARCH_PENDING';
export const API_SLIDE_LEVEL_SEARCH_FAILURE = 'API_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_SLIDE_LEVEL_SEARCH_SUCCESS';
export const API_INIT_SLIDE_LEVEL_SEARCH_PENDING = 'API_INIT_SLIDE_LEVEL_SEARCH_PENDING';
export const API_INIT_SLIDE_LEVEL_SEARCH_FAILURE = 'API_INIT_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS';
export const SET_KNOWLEDGE_APPLIED_FILTERS_PENDING = 'SET_KNOWLEDGE_APPLIED_FILTERS_PENDING';
export const SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS = 'SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS';
export const SET_KNOWLEDGE_APPLIED_FILTERS_FAILED = 'SET_KNOWLEDGE_APPLIED_FILTERS_FAILED';
export const SET_KNOWLEDGE_ACTIVE_FILTER_INDEX = 'SET_KNOWLEDGE_ACTIVE_FILTER_INDEX';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const CHANGE_SORTER = 'CHANGE_SORTER';
export const SET_QUERY_INTENT_APPLIED = 'SET_QUERY_INTENT_APPLIED';
export const API_KNOWLEDGE_SEARCH_LASTSEARCH = 'API_KNOWLEDGE_SEARCH_LASTSEARCH';
export const API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL = 'API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL';
export const CHANGE_SEARCH_TERM_QI = 'CHANGE_SEARCH_TERM_QI';
export const SET_KNOWLEDGE_AUTO_CORRECT_STATE = 'SET_KNOWLEDGE_AUTO_CORRECT_STATE';
export const SET_KNOWLEDGE_UNDO_QUERY_INTENTS = 'SET_KNOWLEDGE_UNDO_QUERY_INTENTS';
export const TOGGLE_AND_FILTER = 'TOGGLE_AND_FILTER';
export const APPLY_AND_FILTERS = 'APPLY_AND_FILTERS';
export const CLEAR_PENDING_AND_FILTERS = 'CLEAR_PENDING_AND_FILTERS';
export const KNOWLEDGE_SET_SHOW_CHATBOT = 'KNOWLEDGE_SET_SHOW_CHATBOT';

const sources = [];
const initialState = {
  loading: true,
  loadMorePending: false,
  error: false,
  errorMessage: '',
  empty: false,
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  results: [],
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  filters: [],
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  activeFilter: '',
  query: queryWithSearchParamsOnly(),
  queryIntents: [],
  qiApplied: false,
  qiOriginal: '',
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
  andFilters: {},
  pendingAndFilters: {},
  showChatbot: false,
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getLoadMoreStatus: (state) => state[name].loadMorePending,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query,
  getQueryIntents: (state) => state[name].queryIntents,
  getQueryIntentsApplied: (state) => state[name].qiApplied,
  getSorter: (state) => state[name].query[CONFIG.QUERY_PARAMS.SORTING_ORDER],
  getQiOriginal: (state) => state[name].qiOriginal,
};

const checkPARecommended = (filters) => {
  if (filters.find(
    (filter) => filter.parameterName === CONFIG.KNOWLEDGE_FILTERS.VIEW_CONTROLS.PA_RECOMMENDED.NAME && filter.refinerValues
  )?.refinerValues.find(
    (value) => value.display === 'PA/Topic'
  )) {
    return true;
  }
  return false;
};

const knowledgeSearch = (query, isAiView) => async (dispatch) => {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  const queryPatched = { ...query };
  if (queryPatched.endDatePublished) {
    queryPatched.endDatePublished = moment(queryPatched.endDatePublished).startOf('day').add(1, 'd').format('YYYY-M-D');
  }
  if (queryPatched.endDateRevised) {
    queryPatched.endDateRevised = moment(queryPatched.endDateRevised).startOf('day').add(1, 'd').format('YYYY-M-D');
  }
  const searchQuery = flattenQuery(queryPatched);
  if (!isQueryIntentEnabled()) {
    searchQuery.disableQI = true;
  }

  dispatch({ type: API_KNOWLEDGE_SEARCH_PENDING, payload: { query: searchQuery } });
  try {
    const data = await axios.post(CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
      searchQuery,
      { cancelToken: newSource.token }
    );

    const filtersFromAPIAndConfig = formatFilters(data?.refiners, CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR);

    const paRecommendedFilter = data?.refiners ? checkPARecommended(data.refiners) : false;
    const payload = {
      ...data,
      query: searchQuery,
      results: data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })) || [],
      filters: filtersFromAPIAndConfig,
      paRecommendedFilter,
      autoCorrect: {
        isSearchTermCorrected: false,
        correctedSearchTerm: ''
      }
    };
    if (data.didYouMeans && Array.isArray(data.didYouMeans)) {
      payload.autoCorrect.isSearchTermCorrected = data.didYouMeans.length > 0;
      payload.autoCorrect.correctedSearchTerm = data.didYouMeans.reduce(
        (accumulator, currentValue) => {
          return accumulator.replace(new RegExp(`(${escapeRegExp(currentValue.form)})`, 'g'), currentValue.correction);
        },
        query.query
      );
    }

    if (!isAiView && data.queryIntents?.length > 0) {
      dispatch({
        type: CHANGE_SEARCH_TERM_QI,
        payload: {
          query: getNewSearchTerm(data.queryIntents, searchQuery.query),
          original: searchQuery.query
        }
      });
    }

    dispatch({
      type: API_KNOWLEDGE_SEARCH_SUCCESS,
      payload
    });
  } catch (error) {
    if (error.cancelled) return;
    dispatch({ type: API_KNOWLEDGE_SEARCH_FAILURE, payload: error.toString() });
    throw error;
  }
};

export const actions = {
  knowledgeSearch,
  loadMoreFromSearch: (query, currentPage) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const queryPatched = { ...query };
    if (queryPatched.endDatePublished) {
      queryPatched.endDatePublished = moment(queryPatched.endDatePublished).startOf('day').add(1, 'd').format('YYYY-M-D');
    }
    if (queryPatched.endDateRevised) {
      queryPatched.endDateRevised = moment(queryPatched.endDateRevised).startOf('day').add(1, 'd').format('YYYY-M-D');
    }
    const loadMoreQuery = flattenQuery(queryPatched);
    if (!isQueryIntentEnabled()) {
      loadMoreQuery.disableQI = true;
    }
    loadMoreQuery.resultsFromPage = parseInt(currentPage, 10) + CONFIG.DEFAULT_CURRENT_PAGE;

    dispatch({
      type: API_KNOWLEDGE_LOAD_MORE_PENDING,
      payload: {
        query: loadMoreQuery
      }
    });

    try {
      const data = await axios.post(
        CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
        loadMoreQuery,
        { cancelToken: newSource.token }
      );

      const payload = {
        ...data,
        query: loadMoreQuery,
        doc: data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })),
      };

      dispatch({ type: API_KNOWLEDGE_LOAD_MORE_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: API_KNOWLEDGE_LOAD_MORE_FAILURE, payload: error.message });
      throw error;
    }
  },
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_SEARCH_CANCELLED };
  },
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR, SET_KNOWLEDGE_APPLIED_FILTERS_PENDING, SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS, SET_KNOWLEDGE_APPLIED_FILTERS_FAILED),
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_SORTER, payload }),
  setQueryIntentsApplied: () => async (dispatch) => dispatch({ type: SET_QUERY_INTENT_APPLIED }),
  setAutoCorrectMode: (payload) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_AUTO_CORRECT_STATE, payload }),
  undoQueryIntents: (payload) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_UNDO_QUERY_INTENTS, payload }),
  toggleAndFilter: (payload) => async (dispatch) => dispatch({ type: TOGGLE_AND_FILTER, payload }),
  applyAndFilters: () => async (dispatch) => dispatch({ type: APPLY_AND_FILTERS }),
  clearPendingAndFilters: () => async (dispatch) => dispatch({ type: CLEAR_PENDING_AND_FILTERS }),
  setShowChatbot: (showChatbot) => (dispatch) => dispatch({ type: KNOWLEDGE_SET_SHOW_CHATBOT, payload: showChatbot })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_SLIDE_LEVEL_SEARCH_PENDING:
    case API_INIT_SLIDE_LEVEL_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_KNOWLEDGE_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL:
    case API_INIT_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_KNOWLEDGE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case CHANGE_SEARCH_TERM_QI:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload.query,
        },
        qiOriginal: action.payload.original
      };
    case API_KNOWLEDGE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        empty: action.payload.results.length <= 0,
        results: action.payload.results,
        totalCount: action.payload.results.length <= 0 ? 0 : action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount || 0,
        filters: action.payload.filters || [],
        paRecommendedFilter: action.payload.paRecommendedFilter,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        queryIntents: action.payload.queryIntents || [],
        qiApplied: false,
        autoCorrect: action.payload.autoCorrect
      };
    case API_KNOWLEDGE_SEARCH_LASTSEARCH:
      return {
        ...state,
        lastSearch: { query: { ...action.payload.query } }
      };
    case API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS:
    case API_SLIDE_LEVEL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case API_KNOWLEDGE_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        error: false,
        errorMessage: '',
        empty: false
      };
    case API_KNOWLEDGE_LOAD_MORE_FAILURE:
      return {
        ...state,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload

      };
    case API_KNOWLEDGE_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        totalCount: action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount,
        results: state.results.concat(action.payload.doc),
        queryIntents: action.payload.queryIntents,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_KNOWLEDGE_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case CHANGE_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    case SET_QUERY_INTENT_APPLIED:
      return {
        ...state,
        qiApplied: true
      };
    case SET_KNOWLEDGE_AUTO_CORRECT_STATE:
      return {
        ...state,
        autoCorrectMode: action.payload
      };
    case SET_KNOWLEDGE_UNDO_QUERY_INTENTS:
      // See CHANGE_SEARCH_TERM_QI above
      return {
        ...state,
        queryIntents: [],
        qiApplied: false,
        qiOriginal: '',
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: state.qiOriginal,
        },
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload
      };
    case TOGGLE_AND_FILTER:
      const andFilterKey = action.payload;
      if (andFilterKey === undefined) {
        return state;
      }

      const pendingExists = Object.keys(state.pendingAndFilters).includes(andFilterKey);
      const andState = state.andFilters[andFilterKey];
      const toggled = pendingExists ? !state.pendingAndFilters[andFilterKey] : !andState;

      return {
        ...state,
        pendingAndFilters: {
          ...state.pendingAndFilters,
          [andFilterKey]: toggled
        }
      };
    case APPLY_AND_FILTERS:
      return {
        ...state,
        andFilters: { ...state.pendingAndFilters },
        pendingAndFilters: {}
      };
    case CLEAR_PENDING_AND_FILTERS:
      return {
        ...state,
        pendingAndFilters: {}
      };
    case KNOWLEDGE_SET_SHOW_CHATBOT:
      return {
        ...state,
        showChatbot: action.payload
      };
    default:
      return state;
  }
}