import CONFIG from 'config';

export const name = 'casefields';
export const API_CASEFIELDS_PENDING = 'API_CASEFIELDS_PENDING';
export const API_CASEFIELDS_SUCCESS = 'API_CASEFIELDS_SUCCESS';
export const API_CASEFIELDS_FAILURE = 'API_CASEFIELDS_FAILURE';

const initialState = {
  casefieldsLoading: false,
  casefieldsError: false,
  casefieldsErrorMessage: '',
  casefields: {}
};

export const selectors = {
  getCasefields: (state) => state[name].casefields
};

const fetchCasefields = () => async (dispatch) => {
  dispatch({ type: API_CASEFIELDS_PENDING });

  try {
    const casefields = await fetch(CONFIG.API_URL.CASEFIELDS)
      .then((response) => response.json());

    dispatch({ type: API_CASEFIELDS_SUCCESS, payload: casefields });
  } catch (error) {
    dispatch({ type: API_CASEFIELDS_FAILURE, payload: error.message });
  }
};

export const actions = { fetchCasefields };

export function reducer(state = initialState, action) {
  let newState = state;

  switch (action.type) {
    case API_CASEFIELDS_PENDING:
      newState = {
        ...state,
        casefields: {},
        casefieldsPending: true,
        casefieldsError: false,
        casefieldsErrorMessage: ''
      };
      break;

    case API_CASEFIELDS_SUCCESS:
      newState = {
        ...state,
        casefields: action.payload,
        casefieldsPending: false,
        casefieldsError: false,
        casefieldsErrorMessage: ''
      };
      break;

    case API_CASEFIELDS_FAILURE:
      newState = {
        ...state,
        casefields: {},
        casefieldsPending: false,
        casefieldsError: true,
        casefieldsErrorMessage: action.payload
      };
      break;

    default:
      break;
  }

  return newState;
}