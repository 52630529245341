import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { ResponsiveContext } from 'context/ResponsiveContext/ResponsiveContext';
import { forceCheck } from 'react-lazyload';
import './Carousel.scss';

class Carousel extends PureComponent {
  constructor(props) {
    super(props);
    this.scrollContent = createRef();
    this.leftButton = createRef();
    this.rightButton = createRef();
  }

  componentDidMount = () => {
    this.updateArrows();
  };

  componentDidUpdate = () => {
    this.updateArrows();
  };

  scrollLeft = () => {
    forceCheck();
    const { onScroll } = this.props;
    const totalwidth = this.scrollContent.current.getBoundingClientRect().width / 2;
    /**   we are scrolling the content by clientwidth/2 on left side when clicked.
     * The moment scrollleft becomes < clientwidth we need to explicitly set scrollleft to zero so that scrolling is smooth.
     * this is because of the arrow button width that does not let the content to scroll back to zero. */
    if (this.scrollContent.current.scrollLeft <= totalwidth) {
      this.scrollContent.current.scrollLeft = 0;
    } else {
      this.scrollContent.current.scrollLeft -= totalwidth;
    }
    onScroll('left');
    window.setTimeout(this.updateArrows, 300);
  };

  scrollRight = () => {
    const { onScroll } = this.props;
    const { getContactDetails } = this.props;
    getContactDetails();
    forceCheck();
    const totalVisibleWidth = this.scrollContent.current.getBoundingClientRect().width / 2;
    /**   we are scrolling the content by clientwidth/2 on left side when clicked.
    * The moment scrollleft becomes > Total width we need to explicitly set scrollright to totalwidth so that scrolling is smooth.
    * this is because of the arrow button width that does not let the content to scroll back to totalwidth. */
    if (this.scrollContent.current.scrollLeft >= this.scrollContent.current.scrollWidth - totalVisibleWidth) {
      this.scrollContent.current.scrollLeft = this.scrollContent.current.scrollWidth;
    } else {
      this.scrollContent.current.scrollLeft += totalVisibleWidth;
    }
    onScroll('right');
    window.setTimeout(this.updateArrows, 300);
  };

  updateArrows = () => {
    const { fullArrowDisplay } = this.props;
    if (
      this.leftButton.current
      && this.rightButton.current
      && this.scrollContent.current
    ) {
      const scroller = this.scrollContent.current;
      const leftButt = this.leftButton.current.ref.current;
      const rightButt = this.rightButton.current.ref.current;
      if (scroller.scrollLeft === 0) {
        if (fullArrowDisplay) {
          leftButt.classList.add('hideArrow');
          scroller.classList.add('hideBorderLeft');
          rightButt.classList.add('hideArrow');
          scroller.classList.add('hideBorderRight');
          if (scroller.scrollWidth > scroller.getBoundingClientRect().width) {
            rightButt.classList.remove('hideArrow');
            scroller.classList.remove('hideBorderRight');
          }
        } else {
          leftButt.classList.add('disableArrows');
          rightButt.classList.remove('disableArrows');
        }
      } else if (
        scroller.scrollWidth - scroller.scrollLeft
        <= scroller.getBoundingClientRect().width
      ) {
        if (fullArrowDisplay) {
          rightButt.classList.add('hideArrow');
          scroller.classList.add('hideBorderRight');
          leftButt.classList.remove('hideArrow');
          scroller.classList.remove('hideBorderLeft');
        } else {
          rightButt.classList.add('disableArrows');
          leftButt.classList.remove('disableArrows');
        }
      } else {
        leftButt.classList.remove('hideArrow');
        rightButt.classList.remove('hideArrow');
        leftButt.classList.remove('disableArrows');
        rightButt.classList.remove('disableArrows');
        scroller.classList.remove('hideBorderRight');
        scroller.classList.remove('hideBorderLeft');
      }
    }
  };

  render = () => {
    const {
      children,
      showArrows,
      fullArrowDisplay,
      suggestions,
      className,
      isNavi,
    } = this.props;
    const { isMobile } = this.context;
    let disable = false;
    let clickable = true;
    if (!fullArrowDisplay) {
      const minSuggestions = isMobile ? 1 : 3;
      disable = suggestions.length < minSuggestions;
      clickable = suggestions.length >= minSuggestions;
    }
    return (
      <div className={`${className || ''} carousel ${isNavi && 'grid'}`}>
        {showArrows &&
          <div className={`arrow_container ${!fullArrowDisplay ? 'topRightArrows' : ''}`}>
            <Button
              className={`leftArrow hideArrow ${disable ? 'disableArrows' : ''}`}
              onClick={clickable && this.scrollLeft}
              ref={this.leftButton}
              data-testid="leftArrow"
            >
              <Icon name="chevron left" />
            </Button>
            <Button
              className={`rightArrow hideArrow ${disable ? 'disableArrows' : ''}`}
              onClick={clickable && this.scrollRight}
              ref={this.rightButton}
              data-testid="rightArrow"

            >
              <Icon name="chevron right" />
            </Button>
          </div>}
        <div className="scroller hideBorderLeft hideBorderRight" ref={this.scrollContent}>
          {children}
        </div>
      </div >
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  showArrows: PropTypes.bool,
  fullArrowDisplay: PropTypes.bool,
  suggestions: PropTypes.array,
  className: PropTypes.string,
  getContactDetails: PropTypes.func,
  onScroll: PropTypes.func,
  isNavi: PropTypes.bool
};

Carousel.defaultProps = {
  showArrows: true,
  isNavi: false,
  fullArrowDisplay: true,
  suggestions: [],
  className: '',
  getContactDetails: () => { },
  onScroll: () => { }
};
Carousel.contextType = ResponsiveContext;

export default Carousel;