import styled from 'styled-components';
import searchIcon from 'assets/images/search-icon-dark-gray.svg';
import searchIconHover from 'assets/images/search-icon-green.svg';
import Carousel from 'components/Carousel/Carousel';

export const StyledCard = styled.div`
  &{
    transition: all 0.25s;
    position: relative;
    margin: ${({ theme }) => `0 0 ${theme.SIZE(16)}`};
    background-color: ${(props) => props.theme.COLORS.$white};
    padding: ${({ theme }) => `${theme.SIZE(16)} ${theme.SIZE(2)}`};
    min-height: ${({ theme }) => theme.SIZE(165)};
    display: flex;
    flex-wrap: wrap;
    width:100%;
  }
`;

export const StyledCarousel = styled(Carousel)`
  &{
    &.carousel{
      height: auto;
      background-color: initial;
    }
    .scroller {
      height: auto;
      width: 100%;
    }
    .rightArrow{
      height: ${({ theme }) => theme.SIZE(50)};
    }
    .leftArrow{
      height: ${({ theme }) => theme.SIZE(50)};
    }
  }
`;

export const Header = styled.div`
  font-weight: bolder;
`;

export const SuggestionButton = styled.button`
  color: ${({ theme }) => theme.COLORS.$graydarkest};
  border: ${({ theme }) => `1px solid ${theme.COLORS.$graydarkest}`}; 
  font-size: ${({ theme }) => theme.SIZE(16)};
  border-radius: ${({ theme }) => theme.SIZE(5)};
  background: ${({ theme }) => theme.COLORS.$white} url(${searchIcon}) no-repeat;
  background-position: ${({ theme }) => `${theme.SIZE(10)} ${theme.SIZE(10)}`};
  padding: ${({ theme }) => theme.SIZE(10)};
  padding-left: ${({ theme }) => theme.SIZE(35)};
  margin-top: ${({ theme }) => theme.SIZE(5)};
  margin-bottom: ${({ theme }) => theme.SIZE(10)};
  margin-right: ${({ theme }) => theme.SIZE(10)};
  cursor: pointer;
  &:hover {
      color: ${({ theme }) => theme.COLORS.$bcggreenbluegreen};
      border: ${({ theme }) => `1px solid ${theme.COLORS.$bcggreenbluegreen}`}; 
      background-image: url(${searchIconHover});
  }
`;