import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// above polyfills are needed for IE to support the error page
// import 'whyDidYouRender';
import React from 'react';
import ReactDOM from 'react-dom';
import 'config-axios';
import CONFIG from 'config';

import '@kdshared/enrich-ui/dist/css/semantic.css';
import 'index.scss';

import App from 'components/App/App';
import windowUtil from 'utils/window/window';

if (window.location.host === CONFIG.ALTERNATE_SERVER_URL) {
  window.location.host = CONFIG.SERVER_URL;
}

ReactDOM.render(<App />, document.getElementById('root'));

windowUtil();