import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
// import { Row, Col, Icon } from '@kdshared/enrich-ui';
// import Button from 'components/Button/Button';

export const ProposalBuilderDetails = styled.div`
  margin-top: 57px;
  border-top: 2px solid ${({ theme }) => theme.COLORS.$grey};
  background-color: ${({ theme }) => theme.COLORS.$black200};
  display: flex;

  .leftbar{
    padding: 14px 20px 20px 20px;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
    width: 236px;
    position: relative;
  }

  .main{
    background: white;
    flex: 80%;
  }

  .wrapper{
    padding: 20px;
    display: flex;
    height: calc(100% - 60px);
  }

  .leftsec {
    height: 100%;
    flex-basis: calc(100% - 280px);
  }

  .rightsec {
    height: 100%;
    flex-basis: 265px;
    margin-left: 15px;
    padding: 10px;
  }

  .sticky{
    position: sticky;
    top: 80px;
  }

  .export {
    border-top: 1px solid ${({ theme }) => theme.COLORS.$grey};
    padding: 21px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;

  h1 {
    color: ${({ theme }) => theme.COLORS.$black200};
    margin-bottom: 34px;
  }

  .col1 {
    flex-shrink: 0;
  }

  .backButton {
    padding: 5px 10px;
    color: black;
    border-color: black;
    font-size: ${({ theme }) => theme.SIZE(16)};
    text-transform: none;
  }

  .col2 {
    flex-grow: 1;
    padding: 0 15px;
  }

  .content {
    font-size: ${({ theme }) => theme.SIZE(16)};
    color: ${({ theme }) => theme.COLORS.$black200};
    line-height: 28px;

    h2 {
      font-size: ${({ theme }) => theme.SIZE(22)};
      color: ${({ theme }) => theme.COLORS.$black200};
    }

    ul, ol {
      padding-left: 42px;
    }

    ul > li {
      list-style-type: disc;
    }

    ol > li {
      list-style-type: auto;
    }

    sup a {
      font-weight: bold;
      display: inline-block;
      background: #F1EEEA;
      padding: 10px 4px;
      border-radius: 6px;
      color: #197A56;
    }
  }
`;

export const MenuWrapper = styled(Menu)`
  &&&& {
    display: inline-flex;
    align-items: center;
    border: 2px solid black;
  }
  

  .ui.dropdown {
    padding: 10px;
    color: black;
    font-size: ${({ theme }) => theme.SIZE(16)};
  }
`;