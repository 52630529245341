import CONFIG from 'config';

export const name = 'homepageLinks';
export const API_HOMEPAGE_LINKS_PENDING = 'API_HOMEPAGE_LINKS_PENDING';
export const API_HOMEPAGE_LINKS_SUCCESS = 'API_HOMEPAGE_LINKS_SUCCESS';
export const API_HOMEPAGE_LINKS_FAILURE = 'API_HOMEPAGE_LINKS_FAILURE';

const initialState = {
  homepageLinksLoading: false,
  homepageLinksError: false,
  homepageLinksErrorMessage: '',
  homepageLinks: []
};

export const selectors = {
  getHomepageLinks: (state) => state[name].homepageLinks
};

const fetchHomepageLinks = () => async (dispatch) => {
  dispatch({ type: API_HOMEPAGE_LINKS_PENDING });

  try {
    const links = await fetch(CONFIG.API_URL.HOMEPAGE_LINKS)
      .then((response) => response.json())
      .then((data) => data
        .sort((a, b) => (a.order > b.order ? 1 : -1)));

    dispatch({ type: API_HOMEPAGE_LINKS_SUCCESS, payload: links });
  } catch (error) {
    dispatch({ type: API_HOMEPAGE_LINKS_FAILURE, payload: error.message });
  }
};

export const actions = { fetchHomepageLinks };

export function reducer(state = initialState, action) {
  let newState = state;

  switch (action.type) {
    case API_HOMEPAGE_LINKS_PENDING:
      newState = {
        ...state,
        homepageLinks: [],
        homepageLinksPending: true,
        homepageLinksError: false,
        homepageErrorMessage: ''
      };
      break;

    case API_HOMEPAGE_LINKS_SUCCESS:
      newState = {
        ...state,
        homepageLinks: action.payload,
        homepageLinksPending: false,
        homepageLinksError: false,
        homepageErrorMessage: ''
      };
      break;

    case API_HOMEPAGE_LINKS_FAILURE:
      newState = {
        ...state,
        homepageLinks: [],
        homepageLinksPending: false,
        homepageLinksError: true,
        homepageErrorMessage: action.payload
      };
      break;

    default:
      break;
  }

  return newState;
}