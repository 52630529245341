import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from 'redux/history';

import createRootReducer from './reducers';

const middleware = [ // Order dependent
  thunkMiddleware, // Enables actions to return functions
  routerMiddleware(history) // Enables dispatching actions
];
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleware.push(createLogger({ collapsed: true })); // Must be at bottom
}

let reduxCompose;
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
  reduxCompose = compose(applyMiddleware(...middleware));
} else {
  reduxCompose = composeWithDevTools(applyMiddleware(...middleware)); // Use only in the lower environments
}

const store = createStore(
  createRootReducer(history),
  reduxCompose
);

export default store;