/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import CONFIG from 'config';
import authService from 'redux/auth';
import { checkDurationAndReload, setAuthAttemptTime, isAuthAttemptSet, getAccessTokenFromCookie } from 'utils/auth/auth';

const invalidToken = (src) => {
  console.error('AXIOS ERROR 4 - invalid token', src);
  if (!isAuthAttemptSet()) setAuthAttemptTime();
  checkDurationAndReload(15);

  return Promise.reject(new axios.Cancel(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.INVALID_TOKEN));
};

axios.defaults.baseURL = CONFIG.SERVER_URL;

axios.interceptors.request.use(async (config) => {
  const accessToken = await authService.getAccessToken();

  if (!accessToken) {
    const accessTokenCookie = getAccessTokenFromCookie();
    return invalidToken(`request.use, accessToken.length: ${accessTokenCookie.length}`);
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
      'x-api-key': config.headers['x-api-key'] || CONFIG.X_API_KEY
    }
  };
}, (error) =>
  Promise.reject(error)
);

axios.interceptors.response.use((response) => {
  switch (response.status) {
    case 204: return response;
    default:
      return response.data;
  }
}, (error) => {
  if (error.response) {
    let isOnline = 'unknown';
    try {
      isOnline = window.navigator.onLine;
    } catch (oex) {
      console.error('AXIOS ERROR A: cant get window.navigator', oex);
    }
    console.error('AXIOS ERROR 1: online:', isOnline, '; resp status:', error.response?.status,'; resp data:', JSON.stringify(error.response?.data), '; message:', error.message, '; url:', error.config?.url);
    switch (error.response.status) {
      case 400:
      case 500:
        return Promise.reject({ message: CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.SERVER_ERROR, error });
      case 401:
        // if we get 401's from external APIs, we don't want to reload the app
        if (error.config.url.indexOf('/location') > -1 || error.config.url.indexOf('/people') > -1 || error.config.url.indexOf('/expertsearch') > -1) {
          return null;
        }
        return invalidToken('response.use error');
      case 403:
        return Promise.reject({ message: CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.FORBIDDEN, error });
      case 404:
        return Promise.reject({ message: CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND, error });
      default:
        return Promise.reject(new Error(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.UNKNOWN_CODE, error.response));
    }
  } else if (error.request) {
    // eslint-disable-next-line no-console
    console.error('AXIOS ERROR 2 status:', error.response?.status, 'message:', error.message, 'url:', error.config?.url);
    return Promise.reject(new Error(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NETWORK_ERROR, error.request));
  } else {
    // eslint-disable-next-line no-console
    console.error('AXIOS ERROR 3 status:', error.response?.status, 'message:', error.message, 'url:', error.config?.url);
    return Promise.reject({ cancelled: true });
  }
});
