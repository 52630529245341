export const pageScroll = (toId, elementClass = '') => {
  const itemsScrollPos = [];
  
  const navigationId = toId !== '' ? toId : window.location?.hash?.replace('#', '');
  
  //populate the positions of scrollable items
  const scrollItems = document.getElementsByClassName(elementClass);
  
  for (let item of scrollItems) {
    itemsScrollPos.push(item?.getBoundingClientRect()?.top);
  }
  itemsScrollPos.reverse();
  
  let element = null;
  if(navigationId !== ''){
    element = document.getElementById(navigationId);
  }
  
  if(!element){
    return itemsScrollPos;
  }
  
  const y = element.getBoundingClientRect().top + window.scrollY - 80;
  
  window.scroll({
    top: y,
    behavior: 'smooth'
  });
  
  return itemsScrollPos;
};

export const findClosestMatch = (toFind = 0, items = [], offset = 0) => {
  let nextItem = 0;
  
  items.find((i, index) => {
    if(toFind + items[items.length - 1] + offset > i) {
      nextItem = items.length - index - 1;
      return true;
    }
  
    return false;
  });
  
  return nextItem;
};